import React, { useEffect, useState } from "react";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  categoryDetailController,
  filterCategoryStatusController,
} from "../../services/filterCategoryServices/filterCategoryController";
import Pagination from "react-js-pagination";
import ModalBox from "../../common/ModalBox";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";

function ViewFilterCategoryManagement() {
  const [category, setCategory] = useState({});
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [statusModal, setStatusModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const { id } = useParams();
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const navigate = useNavigate();

  const categoryDetail = async () => {
    try {
      const response = await categoryDetailController(id);
      console.log(response);
      if (response?.code === 200) {
        console.log(response?.data);
        setCategory(response?.data);
        totalList(response?.data?.count);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //fetching Filter Category Status API
  const filterCategoryStatusHandler = async () => {
    try {
      setButtonLoader(true);
      const response = await filterCategoryStatusController(id);
      if (response?.code === 200) {
        toast.success(response?.message);
        setButtonLoader(false);
        setStatusModal(false);
        categoryDetail();
      } else {
        toast.error(response?.message);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    categoryDetail();
  }, []);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Filter Category
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-11">
                  <ul className="category_cstm_ul" type="none">
                    <li>
                      <h5>Category Name :</h5>
                      <p>{category.categoryName}</p>
                    </li>
                    <li>
                      <h5>Total Products :</h5>
                      <p>{category.count}</p>
                    </li>
                  </ul>
                </div>
                <div className="col-1 text-end">
                  <FormControlLabel
                    control={
                      <ToggleStatus
                        sx={{ m: 1 }}
                        checked={category.categoryStatus === 1}
                        onClick={() => {
                          setStatusModal(true);
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page-header mt-4">
            <div className="page_title_wrap">
              <h3 className="page-title"> </h3>
              <div className="button_wrap">
                <Link
                  to="#"
                  className="btn btn-btn btn-gradient-primary button"
                >
                  <i className="mdi mdi-plus menu-icon menu-icon"></i> Products
                  List
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Product</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category?.products?.length > 0 ? (
                        category.products.map(
                          ({ productName, status }, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{productName}</td>
                                  <td>
                                    <div className="action_button_wrap">
                                      <div className="switch_button">
                                        <FormControlLabel
                                          control={
                                            <ToggleStatus
                                              sx={{ m: 1 }}
                                              checked={status === 1}
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>Products Not Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + category?.products?.length - 1} of{" "}
                        {totalList} entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* Modal for Status Change */}
            <ModalBox
              status={statusModal}
              onClose={() => setStatusModal(false)}
              title={"Change Status"}
              description="Are you sure you want to change status?"
              action={filterCategoryStatusHandler}
              uploadButtonElement={buttonLoader}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewFilterCategoryManagement;
