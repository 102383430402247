import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { changePasswordController } from "../../services/authServices/authController";
import useButtonLoader from "../../hooks/useButtonLoader";
import { toast } from "react-toastify";

function ResetPassword() {
  const [inputField, setInputField] = useState({
    // newPassword: "",
    // confirmPassword: "",
    // key: "",
  });
  const [errors, setErrors] = useState({});
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const { id } = useParams();

  const navigate = useNavigate();

  //Form validation
  const formValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!inputField.newPassword) {
      validationStatus = false;
      error["newPassword"] = "Please enter new password";
    }
    if (!inputField.confirmPassword) {
      validationStatus = false;
      error["confirmPassword"] = "Please confirm your password";
    }
    setErrors(error);
    return validationStatus;
  };

  //Input Field Handler
  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value, key: id });
  };

  //fetching change password API
  const fetchingChangePassword = async (e) => {
    e.preventDefault(e);
    if(inputField.confirmPassword !== inputField?.newPassword){
      toast.error("New password and confirm password must be same")
    }else {
      const status = formValidation();
      if (status) {
        try {
          setButtonLoader(true);
          const response = await changePasswordController(inputField);
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            navigate("/");
            setButtonLoader(false);
          } else {
            setButtonLoader(false);
            toast.error(
              response?.response?.data?.message || response?.data?.message
            );
          }
        } catch (e) {
          toast.error(e.message);
          setButtonLoader(false);
        }
      }
    }

  };
  return (
    <React.Fragment>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth">
            <div class="row flex-grow">
              <div class="col-lg-4 mx-auto">
                <div class="auth-form-light text-left p-5">
                  <div class="brand-logo">
                    <div className="image_wrap">
                      <img src="./assets/images/stoklo.jpeg" />
                    </div>
                  </div>
                  <h4>Reset Password</h4>
                  <form class="pt-3">
                    {/* <div class="form-group">
                      <input
                        type="email"
                        class="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Enter Your Email Address"
                      />
                    </div> */}
                    <div class="form-group">
                      <label
                        htmlFor="email"
                        className="exampleInputUsername1 d-flex f-2"
                      >
                        <h4>New Password</h4>
                      </label>
                      <input
                        type="password"
                        name="newPassword"
                        value={inputField.newPassword}
                        class="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="New Password"
                        onChange={inputFieldHandler}
                      />
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors.newPassword}
                    </span>
                    <div class="form-group">
                      <label
                        htmlFor="email"
                        className="exampleInputUsername1 d-flex f-2"
                      >
                        <h4>Confirm Password</h4>
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={inputField.confirmPassword}
                        class="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="New Password"
                        onChange={inputFieldHandler}
                      />
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors.confirmPassword}
                    </span>
                    {/* <div class="form-group">
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div> */}
                    <div class="mt-3">
                      {/* <a */}
                      <button
                        class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        ref={buttonLoader}
                        onClick={(e) => {
                          fetchingChangePassword(e);
                        }}
                      >
                        Send
                        {/* </a> */}
                      </button>
                    </div>
                    {/* <div class="my-2 d-flex justify-content-between align-items-center"> */}
                    {/* <div class="form-check">
                        <label class="form-check-label text-muted">
                          <input type="checkbox" class="form-check-input" />{" "}
                          Keep me signed in{" "}
                        </label>
                      </div> */}
                    {/* <Link to="/forgot-password" class="auth-link text-black"> */}
                    {/* <div className="my-2">
                      <h6>
                        Didn't received the link ,{" "}
                        <Link to=""> resend again </Link>
                      </h6>
                    </div> */}
                    {/* </Link> */}
                    {/* </div> */}
                    <div class="mb-2">
                      {/* <button
                        type="button"
                        class="btn btn-block btn-facebook auth-form-btn"
                      >
                        <i class="mdi mdi-facebook me-2"></i>Connect using
                        facebook{" "}
                      </button>
                    </div>
                    <div class="text-center mt-4 font-weight-light">
                      {" "}
                      Don't have an account?{" "}
                      <a href="register.html" class="text-primary">
                        Create
                      </a> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword;
