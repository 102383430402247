import { instance, setJwtToken } from "../../config/axiosInstance";

// Cms Detail C0ntroller
export const cmsListController = async () => {
  const data = await instance.get(`admin/cms-list`, setJwtToken());
  return data;
};

// Cms Detail COntroller
export const CmsDetailsController = async (id) => {
  const data = await instance.get(
    `admin/detail-cms?id=${id}`,
    setJwtToken()
  );
  return data;
};


// Cms Update  Controller
export const CmsUpdateController = async (reqData) => {
  const data = await instance.post(
    `admin/cms-update`,
    reqData,
    setJwtToken()
  );
  return data;
};

