import React, { useState, useEffect, useRef } from "react";
import {
  pointsListingController,
  changePointStatusController,
  updatePointController,
} from "../../services/pointsManagementServices/pointsManagementController";
import { toast } from "react-toastify";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import { dateFormatYear } from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import { permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";

function PointsManagement() {
  const [pointData, setPointData] = useState([]);
  const [referAndEarn, setReferAndEarn] = useState({});
  const [onBoardingBonus, setOnboardingBonus] = useState({});
  const [cashback, setCashback] = useState({});
  const [referErrors, setReferErrors] = useState({});
  const [onBoardErrors, setOnBoardErrors] = useState({});
  const [cashbackErrors, setCashbackErrors] = useState({});
  const [walletDiscount, setWalletDiscount] = useState();
  const [walletDiscountErrors, setWalletDiscountErrors] = useState({});
  const [statusId, setStatusId] = useState(null);

  const [enabled, setEnabled] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const { permission } = usePermission();

  // Listing Wallet Points
  const fetchingPointsListing = async () => {
    try {
      const response = await pointsListingController();
      if (response.data?.code === 200) {
        const responseData = response?.data?.data;
        setPointData(responseData);
        setReferAndEarn(responseData[0]);
        setOnboardingBonus(responseData[1]);
        setWalletDiscount(responseData[5]);

        setCashback(responseData[2]);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Wallet Points Status Handler
  const changeStatusHandler = async () => {
    try {
      const id = statusId;
      setSubmitButton(true);
      const response = await changePointStatusController(id);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchingPointsListing();
        setSubmitButton(false);
        setStatusModal(false);
        statusId(null);
      } else {
        toast.error(response.response.data.message || response?.data?.message);
        setStatusId(null);
        setSubmitButton(false);
        setStatusModal(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
      setStatusId(null);
      setStatusModal(false);
    }
  };

  // Refer & Earn Validation
  const referValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!referAndEarn.amount || referAndEarn.amount === "0.00") {
      validationStatus = false;
      error["amount"] = "Please fill the amount ";
    }
    if (!referAndEarn.noOfDays) {
      validationStatus = false;
      error["noOfDays"] = "Please fill the number of days";
    }
    setReferErrors(error);
    return validationStatus;
  };

  // On Borading Bonus Validation
  const onBoardValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!onBoardingBonus.amount || onBoardingBonus.amount === "0.00") {
      validationStatus = false;
      error["amount"] = "Please fill the amount ";
    }
    if (!onBoardingBonus.noOfDays) {
      validationStatus = false;
      error["noOfDays"] = "Please fill the number of days";
    }
    setOnBoardErrors(error);
    return validationStatus;
  };

  // Cashback Validation
  const cashbackValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!cashback.type) {
      validationStatus = false;
      error["type"] = "Please select cashback type * ";
    }
    if (cashback.type === "1" && !cashback.amount) {
      validationStatus = false;
      error["amount"] = "Please fill the amount *";
    }
    if (cashback.type === "2" && !cashback.percentage) {
      validationStatus = false;
      error["percentage"] = "Please fill the percentage *";
    }
    if (cashback.type === "2" && Number(cashback.percentage) > 100) {
      validationStatus = false;
      error["percentage"] = "Percentage should not exceed 100% *";
    }
    if (!cashback.minCartValue || cashback.minCartValue === "0.00") {
      validationStatus = false;
      error["minCartValue"] = "Please fill the minimum cart value *";
    }
    if (!cashback.noOfDays) {
      validationStatus = false;
      error["noOfDays"] = "Please enter no. of days * ";
    } else if (Number(cashback.noOfDays) === 0) {
      validationStatus = false;
      error["noOfDays"] = "No of days should be greater than 0 * ";
    }
    setCashbackErrors(error);
    return validationStatus;
  };

  // Wallet Discount Validation
  const walletDiscountValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!walletDiscount.percentage) {
      validationStatus = false;
      error["percentage"] = "Please fill the percentage *";
    }
    if (Number(walletDiscount?.percentage) > 100) {
      validationStatus = false;
      error["percentage"] = "Percentage should not exceed over 100% * ";
    }
    if (!walletDiscount.amount || walletDiscount.amount === "0.00") {
      validationStatus = false;
      error["amount"] = "Please fill the amount *";
    }
    setWalletDiscountErrors(error);
    return validationStatus;
  };

  // Updated Wallet Points Handler
  const updateReferAndEarnHandler = async (e, key = "", id) => {
    e.preventDefault();
    try {
      let data = {};
      if (key === "referAndEarn") {
        data = {
          amount: Number(referAndEarn.amount),
          noOfDays: Number(referAndEarn.noOfDays),
          id: id,
        };
      }
      if (key === "onBoardingBonus") {
        data = {
          amount: Number(onBoardingBonus.amount),
          noOfDays: onBoardingBonus.noOfDays,
          id: id,
        };
      }

      if (key === "cashback") {
        data = {
          type: Number(cashback?.type),
          minCartValue: Number(cashback?.minCartValue),
          noOfDays: Number(cashback?.noOfDays),
          id: id,
        };
        if (Number(cashback.type) === 1) {
          Object.assign(data, {
            amount: Number(cashback.amount),
            percentage: null,
          });
        } else {
          Object.assign(data, {
            percentage: Number(cashback.percentage),
            amount: null,
          });
        }
      }

      if (key === "wallet") {
        data = {
          percentage: Number(walletDiscount?.percentage),
          amount: Number(walletDiscount?.amount),
          id: id,
        };
      }

      // Refer and Earn Fetch Api
      if (key === "referAndEarn") {
        const status = referValidation();
        if (status) {
          const response = await updatePointController(data);
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            fetchingPointsListing();
          } else {
            toast.error(
              response.response.data.message || response?.data?.message
            );
            fetchingPointsListing();
          }
        }
      }
      // OnBoarding Bonus API Fetch
      if (key === "onBoardingBonus") {
        const status = onBoardValidation();
        if (status) {
          const response = await updatePointController(data);
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            fetchingPointsListing();
          } else {
            toast.error(
              response.response.data.message || response?.data?.message
            );
            fetchingPointsListing();
          }
        }
      }
      // Cashback API Fetch
      if (key === "cashback") {
        const status = cashbackValidation();
        if (status === true) {
          const response = await updatePointController(data);
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            fetchingPointsListing();
          } else {
            toast.error(
              response.response.data.message || response?.data?.message
            );
            fetchingPointsListing();
          }
        }
      }
      if (key === "wallet") {
        const status = walletDiscountValidation();
        if (status === true) {
          const response = await updatePointController(data);
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            fetchingPointsListing();
          } else {
            toast.error(
              response.response.data.message || response?.data?.message
            );
            fetchingPointsListing();
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleInputClick = () => {
    setEnabled("enabled");
  };
  useEffect(() => {
    fetchingPointsListing();
  }, []);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">Wallet Points </h3>
              <div className="button_wrap"></div>
            </div>
          </div>

          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h3 className="">Earning Criteria </h3>

                  <React.Fragment>
                    <div className="row mt-2 d-flex justify-content-between">
                      <div className="col-11 mt-4">
                        <h4 className="float-start">{referAndEarn.title}</h4>
                      </div>
                      {permissionChecker(permission, 5, 5) && (
                        <div className="col-1 mt-4">
                          <div className="action_button_wrap">
                            <div className="switch_button">
                              <FormControlLabel
                                control={
                                  <ToggleStatus
                                    sx={{ m: 1 }}
                                    checked={referAndEarn.status === 1}
                                    onClick={() => {
                                      setStatusModal(true);
                                      setStatusId(referAndEarn?.id);
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={5}
                              name="amount"
                              value={referAndEarn.amount}
                              placeholder="Enter Amount"
                              onChange={(e) => {
                                setReferAndEarn({
                                  ...referAndEarn,
                                  amount: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {referErrors.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Number Of Days
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={3}
                              name="noOfDays"
                              value={referAndEarn.noOfDays}
                              placeholder="Enter Number Of Days"
                              onChange={(e) => {
                                setReferAndEarn({
                                  ...referAndEarn,
                                  noOfDays: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />

                            <span style={{ color: "red" }} className="error">
                              {referErrors.noOfDays}
                            </span>
                          </div>
                        </div>
                      </div>
                      {permissionChecker(permission, 5, 2) && (
                        <div className="col-2 text-end">
                          <button
                            className="btn btn-gradient-primary btn-icon-text"
                            disabled={enabled !== "enabled"}
                            onClick={(e) =>
                              updateReferAndEarnHandler(
                                e,
                                "referAndEarn",
                                referAndEarn.id
                              )
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="row d-flex justify-content-between">
                      <div className="col-11 mt-4">
                        <h4 className="float-start">{onBoardingBonus.title}</h4>
                      </div>
                      {permissionChecker(permission, 5, 5) && (
                        <div className="col-1 mt-4">
                          <div className="action_button_wrap">
                            <div className="switch_button">
                              <FormControlLabel
                                control={
                                  <ToggleStatus
                                    sx={{ m: 1 }}
                                    checked={onBoardingBonus.status === 1}
                                    onClick={() => {
                                      setStatusModal(true);
                                      setStatusId(onBoardingBonus?.id);
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={5}
                              name="amount"
                              value={onBoardingBonus.amount}
                              placeholder="Enter Amount"
                              onChange={(e) => {
                                setOnboardingBonus({
                                  ...onBoardingBonus,
                                  amount: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {onBoardErrors.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Number Of Days
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={3}
                              name="noOfDays"
                              value={onBoardingBonus.noOfDays}
                              placeholder="Enter Number Of Days"
                              onChange={(e) => {
                                setOnboardingBonus({
                                  ...onBoardingBonus,
                                  noOfDays: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {onBoardErrors.noOfDays}
                            </span>
                          </div>
                        </div>
                      </div>
                      {permissionChecker(permission, 5, 2) && (
                        <div className="col-2 text-end">
                          <button
                            className="btn btn-gradient-primary btn-icon-text"
                            disabled={enabled !== "enabled"}
                            // ref={submitButton}
                            onClick={(e) =>
                              updateReferAndEarnHandler(
                                e,
                                "onBoardingBonus",
                                onBoardingBonus.id
                              )
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="row mt-2 d-flex justify-content-between">
                      <div className="col-11 mt-4">
                        <h4 className="float-start">{walletDiscount?.title}</h4>
                      </div>
                      {permissionChecker(permission, 5, 5) && (
                        <div className="col-1 mt-4">
                          <div className="action_button_wrap">
                            <div className="switch_button">
                              <FormControlLabel
                                control={
                                  <ToggleStatus
                                    sx={{ m: 1 }}
                                    checked={walletDiscount?.status === 1}
                                    onClick={() => {
                                      setStatusModal(true);
                                      setStatusId(walletDiscount?.id);
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Discount Percentage
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={3}
                              name="percentage"
                              value={walletDiscount?.percentage}
                              placeholder="Enter Percentage"
                              onChange={(e) => {
                                setWalletDiscount({
                                  ...walletDiscount,
                                  percentage: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {walletDiscountErrors.percentage}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="form-group row">
                          <label className="col-sm-5 col-form-label">
                            Max Amount
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              onFocus={handleInputClick}
                              type="text"
                              className="form-control"
                              maxLength={5}
                              name="amount"
                              value={walletDiscount?.amount}
                              placeholder="Enter  Amount"
                              onChange={(e) => {
                                setWalletDiscount({
                                  ...walletDiscount,
                                  amount: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ),
                                });
                              }}
                            />

                            <span style={{ color: "red" }} className="error">
                              {walletDiscountErrors.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                      {permissionChecker(permission, 5, 2) && (
                        <div className="col-2 text-end">
                          <button
                            className="btn btn-gradient-primary btn-icon-text"
                            disabled={enabled !== "enabled"}
                            onClick={(e) =>
                              updateReferAndEarnHandler(
                                e,
                                "wallet",
                                walletDiscount?.id
                              )
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="row d-flex justify-content-between">
                      <div className="col-11 mt-4">
                        <h4 className="float-start">{cashback.title}</h4>
                      </div>
                      {permissionChecker(permission, 5, 5) && (
                        <div className="col-1 mt-4">
                          <div className="action_button_wrap">
                            <div className="switch_button">
                              <FormControlLabel
                                control={
                                  <ToggleStatus
                                    sx={{ m: 1 }}
                                    checked={cashback.status === 1}
                                    onClick={() => {
                                      setStatusModal(true);
                                      setStatusId(cashback?.id);
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-10 cstm_flex_wrap">
                        <div className="col-6">
                          <div className="form-group row">
                            <label
                              htmlFor="type"
                              className="col-sm-6 col-form-label"
                            >
                              Cashback Type
                            </label>
                            <div className="col-sm-6 text-start">
                              <select
                                onFocus={handleInputClick}
                                className="form-control cstmselect_drop"
                                name="type"
                                value={cashback.type}
                                onChange={(e) => {
                                  const selectedType = e.target.value;
                                  setCashback({
                                    ...cashback,
                                    type: selectedType,
                                    amount:
                                      selectedType === "1"
                                        ? ""
                                        : cashback.amount || "",
                                    percentage:
                                      selectedType === "2"
                                        ? ""
                                        : cashback.percentage || "",
                                    minCartValue: "",
                                  });
                                }}
                              >
                                <option value={""}>Choose Cashback Type</option>
                                <option value={"1"}>Flat</option>
                                <option value={"2"}>Percentage</option>
                              </select>

                              <span style={{ color: "red" }} className="error">
                                {cashbackErrors.type}
                              </span>
                            </div>
                          </div>
                        </div>
                        {cashback.type ? (
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-sm-6 col-form-label">
                                {cashback.type == "1" ? "Amount" : "Percentage"}
                              </label>
                              <div className="col-sm-6 text-start">
                                <input
                                  onFocus={handleInputClick}
                                  type="text"
                                  className="form-control"
                                  maxLength={45}
                                  name={
                                    cashback.type == "1"
                                      ? "amount"
                                      : "percentage"
                                  }
                                  value={
                                    cashback.type == "1"
                                      ? cashback.amount || ""
                                      : cashback.percentage || ""
                                  }
                                  placeholder={
                                    cashback.type == "1"
                                      ? "Enter Amount"
                                      : "Enter Percentage"
                                  }
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setCashback({
                                      ...cashback,
                                      [cashback.type === "1"
                                        ? "amount"
                                        : "percentage"]: inputValue,
                                      [cashback.type === "1"
                                        ? "percentage"
                                        : "amount"]: inputValue, // Clear opposite type's value
                                    });
                                  }}
                                />
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {cashback.type == "1"
                                    ? cashbackErrors.amount
                                    : cashbackErrors.percentage}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-4"></div>
                        )}
                        {(cashback.type == "1" || cashback.type == "2") && (
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-sm-6 col-form-label">
                                Set Minimum Cart Value
                              </label>
                              <div className="col-sm-6 text-start">
                                <input
                                  onFocus={handleInputClick}
                                  type="text"
                                  className="form-control"
                                  maxLength={45}
                                  name="minCartValue"
                                  value={cashback.minCartValue || ""}
                                  placeholder="Enter Minimum Cart Value"
                                  onChange={(e) => {
                                    setCashback({
                                      ...cashback,
                                      minCartValue: e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {cashbackErrors.minCartValue}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-6 col-form-label">
                              Number Of Days
                            </label>
                            <div className="col-sm-6 text-start">
                              <input
                                onFocus={handleInputClick}
                                type="text"
                                className="form-control"
                                maxLength={3}
                                name="noOfDays"
                                value={cashback.noOfDays}
                                placeholder="Enter Number Of Days"
                                onChange={(e) => {
                                  setCashback({
                                    ...cashback,
                                    noOfDays: e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    ),
                                  });
                                }}
                              />

                              <span style={{ color: "red" }} className="error">
                                {cashbackErrors.noOfDays}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {permissionChecker(permission, 5, 2) && (
                        <div className="col-2 text-end">
                          <button
                            className="btn btn-gradient-primary btn-icon-text"
                            disabled={enabled !== "enabled"}
                            onClick={(e) =>
                              updateReferAndEarnHandler(
                                e,
                                "cashback",
                                cashback.id
                              )
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBox
          status={statusModal}
          onClose={() => {
            setStatusModal(false);
            setStatusId(null);
          }}
          title="Change Status"
          description={"Are you sure you want to change the status?"}
          action={changeStatusHandler}
          uploadButtonElement={submitButton}
        />
      </div>
    </React.Fragment>
  );
}

export default PointsManagement;
