import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalBox = ({
  cstmClass,
  className,
  status,
  onClose,
  title,
  description,
  action,
  customizedFooter,
  children,
  uploadButtonElement,
  modalSize,
  size,
  centered

}) => {
  return (
    <>
      <Modal
        show={status}
        onHide={onClose}
        className={`${modalSize ? modalSize : ""}`}
        size={size}
        // centered={modalSize}
        centered={centered}
      >
        <Modal.Header closeButton onClick={onClose}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cstmClass ? className : ""}>
          {description}
          {children && children}
        </Modal.Body>
        <Modal.Footer>
          {!customizedFooter ? (
            <>
              <button className=" btn btn-gradient-light" onClick={onClose}>
                No
              </button>
              <button
                ref={uploadButtonElement}
                className="btn btn-gradient-dark"
                onClick={action}
              >
                Yes
              </button>
            </>
          ) : (
            customizedFooter
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalBox;
