import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Product List Api
export const userListingController = async () => {
  const data = await instance.get(`admin/users-list`, setJwtToken());
  return data;
};

// fetching Send Notification API
export const sendNotificationController = async (reqData) => {
  const data = await instance.post(
    `admin/send-notification`,
    reqData,
    setJwtToken()
  );
  return data;
};

// fetching Send Notification API
export const notificationListController = async (activePage, limit) => {
    const params={};
    params.page=activePage?activePage:""
    params.limit=limit?limit:""

  const data = await instance.get(`admin/notification-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};
