import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addSubAdminController,
  subAdminAccessListController,
  subAdminModuleListController,
  viewSubAdminController,
} from "../../services/subAdminServices/subAdminServices";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";

const ViewMember = () => {
  const navigate = useNavigate();
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [moduleName, setModuleName] = useState([]);
  const [permissionArray, setPermissionArray] = useState([]);
  const { id } = useParams();

  const fetchModuleList = async () => {
    try {
      const response = await subAdminModuleListController();
      if (response?.data?.code === 200) {
        setModuleName((prevModuleName) => [
          ...prevModuleName,
          ...(response?.data?.data || []).map((item) => ({
            ...item,
            access: item.access.split(",").map(Number), // Convert to numbers
          })),
        ]);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchSubAdminDetails = async () => {
    try {
      const response = await viewSubAdminController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        setPermissionArray(
          (response?.data?.data?.subadminDetail || []).reduce(
            (acc, { accessId, moduleId }) => {
              const existingModule = acc.find(
                (item) => item.moduleKey === moduleId
              );

              if (existingModule) {
                existingModule.accessKey.push(Number(accessId));
              } else {
                acc.push({
                  moduleKey: moduleId,
                  accessKey: [Number(accessId)],
                });
              }

              return acc;
            },
            []
          )
        );
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Add SubAdmin Api Handler
  const addSubAdmin = async (e) => {
    e.preventDefault();
    try {
      const response = await addSubAdminController({
        ...inputFieldValue,
        permissions: permissionArray,
        role: "subAdmin",
      });
      // console.log(response, "----->Response Add subadmin");
    } catch (e) {
      console.log(e.message);
    }
  };

  // console.log("permisson Array", permissionArray);

  useEffect(() => {
    fetchModuleList();
    fetchSubAdminDetails();
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <h2 className="page-title">
            <span
              className="page-title-icon bg-gradient-primary text-white me-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="mdi mdi-chevron-double-left"></i>
            </span>
            View Member
          </h2>
          <nav aria-label="breadcrumb"></nav>
        </div>
        <div className="wrap_Add_retailer">
          <div class="col-md- grid-margin stretch-card">
            <div class="card ">
              <div class="card-body">
                <form className="form-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Name *
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={25}
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            value={inputFieldValue.name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Email Address *
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={25}
                            placeholder="Enter Aadhar Number"
                            className="form-control"
                            name="email"
                            value={inputFieldValue.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Mobile Number *
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={10}
                            name="mobile"
                            value={
                              inputFieldValue.mobile
                                ? inputFieldValue.mobile
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {moduleName.map((item, index) => (
                        <React.Fragment key={++index}>
                          <div className="col-6 mb-4 text-start">
                            <h5>{item?.name}</h5>
                            {item.access.map((accessId) => {
                              const labelValue =
                                accessId === 1
                                  ? "add"
                                  : accessId === 2
                                  ? "edit"
                                  : accessId === 3
                                  ? "view"
                                  : accessId === 4
                                  ? "delete"
                                  : accessId === 5
                                  ? "status": accessId===6? "request"
                                  : null;

                              return (
                                <FormControlLabel
                                  key={accessId}
                                  label={labelValue}
                                  checked={permissionArray.some(
                                    (module) =>
                                      module &&
                                      module.moduleKey === item.id &&
                                      module.accessKey &&
                                      module.accessKey.includes(
                                        Number(accessId)
                                      )
                                  )}
                                  control={<Checkbox />}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMember;
