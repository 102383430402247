import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  insertVendorController,
  vendorListController,
} from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { isValidEmail, isValidPhoneNumber } from "../../utility/formValidation";
import {
  addBundleController,
  editBundleController,
  viewBundleDetailController,
} from "../../services/bundleServices/bundleController";
import { productListController } from "../../services/recommendedServices/recommendedController";
import { MultiSelect } from "react-multi-select-component";
import { Checkbox, FormControlLabel } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";

const EditBundle = () => {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [addProductList, setAddProductList] = useState([]);
  const [multipleproductImage, setMultipleProductImage] = useState([]);
  const [images, setImages] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const uploadProductImage = useRef();
  const [search,setSearch]=useState("");
  const [cloneProductList,setCloneProductList]=useState([])

  const { id } = useParams();

  const viewBundleDetails = async () => {
    try {
      const response = await viewBundleDetailController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        setImages(response?.data?.data?.bundleImages);
        setAddProductList((prevList) => [
          ...prevList,
          ...response?.data?.data?.bundleProducts.map(
            ({ productDetails, qty }) => ({
              productId: productDetails?.id, // Corrected property name
              qty: qty,
              productName:productDetails?.productName
            })
          ),
        ]);
        setCloneProductList((prevList) => [
          ...prevList,
          ...response?.data?.data?.bundleProducts.map(
            ({ productDetails, qty }) => ({
              productId: productDetails?.id, // Corrected property name
              qty: qty,
              productName:productDetails?.productName
            })
          ),
        ]);
        // console.log(response?.data?.data);
        // toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Validation Status
  const validation = () => {
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.bundleName) {
      error["bundleName"] = "Bundle Name is Manadatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.bundlePrice) {
      error["bundlePrice"] = "Bundle Price is Manadatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.discountPrice) {
      error["discountPrice"] = "Discount Price is Mandatory *";
      validationStatus = false;
    }
    if (
      Number(inputFieldValue.bundlePrice) <
      Number(inputFieldValue.discountPrice)
    ) {
      error["discountPrice"] =
        "Discount Price should not greater than bundle price *";
      validationStatus = false;
    }
    // if (!inputFieldValue.noOfStock) {
    //   error["noOfStock"] = "No. Of Stock is Mandatory *";
    //   validationStatus = false;
    // }
    if (!inputFieldValue.vendorId) {
      error["vendorId"] = "Vendor is Manadatory *";
      validationStatus = false;
    }
    if (addProductList.length <= 0) {
      error["productIds"] = "Select Products *";
      validationStatus = false;
    }
    if (images.length === 0 && multipleproductImage.length === 0) {
      error["productImage"] = "Image is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  //   Vendor List
  const fetchVendorManagementList = async () => {
    try {
      const response = await vendorListController();
      // console.log(response);
      if (response?.code === 200) {
        setVendorsList(response?.data?.rows);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Product List
  const fetchingProductList = async () => {
    try {
      const response = await productListController();

      // console.log(response?.data?.rows);

      if (response?.data?.code === 200) {
        const filteredProductList = response?.data?.data.filter(
          ({ noOfStock }) => noOfStock !== 0
        );

        setProductList(filteredProductList);
        setCloneProductList(filteredProductList);
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching product list:", error.message);
      toast.error("An error occurred while fetching the product list");
    }
  };

  // Input Field Handler
  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    if (type === "number") {
      let updatedValue = value.replace(/[^0-9]/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text") {
      let updatedValue = value.replace(/^\s+|\d+/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  //   multiple Image Handler
  const multipeImageHandler = (e) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;
    if (files.length > 0) {
      setImages([]);
      if (imageFormat.includes(files[0]?.type)) {
        setMultipleProductImage([...files]);
      } else {
        toast.error("Invalid image format");
      }
    }
  };

  const removeProductImage = (id) => {
    const newProductImage = [...multipleproductImage];
    newProductImage.splice(id, 1);
    setMultipleProductImage(newProductImage);
  };

  // Handle Add Vendor
  const editBundleHandler = async () => {
    const status = validation();
    // console.log(status,"Status----------------->")
    if (status) {
      try {
        const formData = new FormData();
        formData.append("vendorId", Number(inputFieldValue.vendorId));
        formData.append("bundleName", inputFieldValue.bundleName);
        formData.append("bundlePrice", Number(inputFieldValue.bundlePrice));
        formData.append("discountPrice", Number(inputFieldValue.discountPrice));
        formData.append("noOfStock", Number(inputFieldValue.noOfStock));
        // selectProduct.map(({ value }) => formData.append("productIds", value));
        formData.append("bundleProducts", JSON.stringify(addProductList));
        multipleproductImage.forEach((item, index) => {
          formData.append("productImage", item);
        });
        formData.append("bundleId", id);
        setSubmitButton(true);
        const response = await editBundleController(formData);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setSubmitButton(false);
          navigate(-1);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  const options = productList
    .filter(({ noOfStock }) => noOfStock !== 0)
    .map(({ productName, id }) => ({
      label: productName,
      value: id,
    }));

  // console.log(selectProduct, "SelectedProducts");

  const deleteImageHandler = () => {
    setImages([]);
  };

  const handleAddProductQuantity = (id) => {
    let item = {
      productId: id,
      qty: 1,
    };
    setAddProductList((prev) => [...prev, item]);
  };

  // Increment Product Quantity Handler
  const incrementProductQuantityHandler = (id) => {
    const index = addProductList.findIndex((item) => item?.productId === id);

    if (index !== -1) {
      const updatedList = [...addProductList];
      updatedList[index].qty++;

      setAddProductList(updatedList);
    }
  };

  // Decrement Product Quantity Handler
  const decrementProductQuantityHandler = (id) => {
    const index = addProductList.findIndex((item) => item?.productId === id);

    if (index !== -1) {
      const updatedList = [...addProductList];

      if (updatedList[index].qty === 1) {
        updatedList.splice(index, 1);
      } else {
        updatedList[index].qty--;
      }

      setAddProductList(updatedList);
    }
  };

  useEffect(() => {
    fetchVendorManagementList();
    fetchingProductList();
    viewBundleDetails();
  }, []);


  useEffect(() => {
    setCloneProductList(productList.filter(({productName}) =>
    productName.toLowerCase().includes(search.toLowerCase())
  ))
  }, [search]);

  console.log(cloneProductList, "Product List");
  console.log(search, "search");

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Edit Bundle
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card cstm_card_height">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Bundle Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Bundle Name"
                              name="bundleName"
                              value={inputFieldValue.bundleName}
                              onChange={(e) => inputFieldHandler(e)}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.bundleName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Bundle Price <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              placeholder="Enter Bundle Price"
                              className="form-control"
                              name="bundlePrice"
                              value={inputFieldValue.bundlePrice}
                              onChange={(e) => inputFieldHandler(e, "number")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.bundlePrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Discounted Price <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={10}
                              name="discountPrice"
                              placeholder="Enter Discount Price"
                              value={inputFieldValue.discountPrice}
                              onChange={(e) => inputFieldHandler(e, "number")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.discountPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            No. Of Stock
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={10}
                              name="noOfStock"
                              placeholder="Enter No. Stock"
                              value={inputFieldValue.noOfStock}
                              onChange={(e) => inputFieldHandler(e, "number")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfStock}
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Select Vendor <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <select
                              type="text"
                              className="form-control"
                              name="vendorId"
                              placeholder="select Vendor"
                              value={inputFieldValue.vendorId}
                              onChange={inputFieldHandler}
                            >
                              <option value={0 || ""}>Select Vendor</option>
                              {vendorsList &&
                                vendorsList.map(({ id, name }, index) => {
                                  return (
                                    <option key={index} value={id}>
                                      {name}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.vendorId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row cstm_drop_styling">
                          <label className="col-sm-2 col-form-label">
                            Select Products <span className="text-danger">*</span>
                          </label>
                          <Dropdown
                            className="col-md-6 text-start"
                            autoClose="outside"
                          >
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                              Select Products
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                               className="cstm_styling_drop_set"
                            >
                                <input
                                placeholder="search product"
                                className="form-control"
                                value={search}
                                name="search"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              {cloneProductList && cloneProductList.length > 0
                                ? cloneProductList.map(({ productName, id },index) => {
                                    return (
                                      <div className="cstm_flex_style" key={++index}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={addProductList.some(
                                                (item) =>
                                                  item && item.productId === id
                                              )}
                                            />
                                          }
                                        />
                                        <Dropdown.Item href="#">
                                          {productName}
                                        </Dropdown.Item>
                                        <td>
                                          {addProductList.find(
                                            (item) =>
                                              item?.productId === id &&
                                              item?.qty
                                          ) ? (
                                            <div className="action_button_wrap p-2">
                                              <div className="switch_button">
                                                <i
                                                  className="mdi mdi-minus-circle"
                                                  onClick={() => {
                                                    decrementProductQuantityHandler(
                                                      id
                                                    );
                                                  }}
                                                ></i>
                                                <p className="cstm_p_count">
                                                  {addProductList.find(
                                                    (item) =>
                                                      item?.productId === id
                                                  )?.qty || 0}
                                                </p>

                                                <i
                                                  className="mdi mdi-plus-circle"
                                                  onClick={() =>
                                                    incrementProductQuantityHandler(
                                                      id
                                                    )
                                                  }
                                                ></i>
                                              </div>
                                            </div>
                                          ) : (
                                            <Link
                                              to="#"
                                              className="btn btn-outline-dark cstm_btn_wrap_theme me-2"
                                              onClick={() =>
                                                handleAddProductQuantity(id)
                                              }
                                            >
                                              Add
                                            </Link>
                                          )}
                                        </td>
                                      </div>
                                    );
                                  })
                                : null}
                            </Dropdown.Menu>
                          <span style={{ color: "red" , display:"block" }} className="error">
                            {errors.productIds}
                          </span>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Product Image <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              ref={uploadProductImage}
                              type="file"
                              name="bundleImage"
                              accept="image/*"
                              className="form-control file-upload-info"
                              hidden
                              placeholder="Upload Image"
                              onChange={multipeImageHandler}
                            />
                         
                            <span className="input-group-append cstm_append_group">
                              <button
                                className="file-upload-browse btn btn-gradient-primary"
                                type="button"
                                onClick={() =>
                                  uploadProductImage.current?.click()
                                }
                              >
                                Upload
                              </button>
                            </span>
                            <span
                              style={{ color: "red", display:"block" }}
                              className="error cstm_ere"
                            >
                              {errors.productImage}
                            </span>
                          </div>
                        </div>
                        <div className="mb-4 mt-4">
                          <div className="row">
                            {multipleproductImage?.length > 0
                              ? multipleproductImage?.map((image, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className="col-3 cstm_upload_image mt-4 ml-4">
                                        <span>
                                          <i
                                            className="mdi mdi-close-circle-outline cstm_close_icon"
                                            onClick={() =>
                                              removeProductImage(index)
                                            }
                                          ></i>
                                        </span>
                                        <img
                                          src={URL.createObjectURL(image)}
                                          className="mb-3  mw-100 w-100 rounded"
                                          alt="banner"
                                          height="150px"
                                          width="200px"
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                              : null}
                          </div>

                          <div className="row">
                            {images &&
                              images.map(({ bundleImage, id }, index) => {
                                return (
                                  <React.Fragment key={++index}>
                                    <div className="col-3 col-3 cstm_upload_image">
                                      <span onClick={deleteImageHandler}>
                                        <i className="mdi mdi-close-circle-outline cstm_close_icon"></i>
                                      </span>
                                      <img
                                        src={bundleImage}
                                        className="mb-2 mw-100 w-100 rounded"
                                        alt={""}
                                        height="150px"
                                        width="200px"
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-gradient-primary mb-2 mr-2 mx-2"
                      onClick={editBundleHandler}
                      ref={submitButton}
                    >
                      Save
                    </button>
                    <button
                      type="submit"
                      className="btn btn-gradient-danger mb-2"
                      onClick={()=>{
                        navigate("/bundle-management")
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditBundle;
