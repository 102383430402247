import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";

// Fetching Scheme List Api
export const schemeListController = async (page, limit, search) => {
  const params = {};
  params.page = page || "";
  params.limit = limit || "";
  params.search = search || "";
  const data = await instance.get(`admin/scheme-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Add Scheme
export const addSchemeController = async (reqData) => {
  const data = await instance.post(
    `admin/create-scheme`,
    reqData,
    setJwtToken()
  );
  return data;
};

// View Scheme Details
export const viewSchemeDetailController = async (schemeId) => {
  const data = await instance.get(
    `admin/scheme-details?schemeId=${schemeId}`,
    setJwtToken()
  );
  return data;
};

// Edit Scheme Controller
export const editSchemeController = async (reqData) => {
  const data = await instance.post(
    `admin/update-scheme`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// Delete Scheme Controller
export const deleteSchemeController = async (schemeId) => {
  const data = await instance.delete(
    `admin/delete-scheme/${schemeId}`,
    setJwtToken()
  );
  return data;
};

// Change Status Scheme Controller
export const changeStatusSchemeController = async (schemeId) => {
  // console.log(schemeId, "SchemeId");
  const data = await instance.post(
    `admin/update-scheme-status?`,
    { id: schemeId },
    setJwtToken()
  );
  return data;
};
