import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";

// Fetching Wallet List  Api
export const walletListController = async (page, limit,search) => {
  const params = {};
  params.page = page ? page : "";
  params.limit = limit ? limit : "";
  params.search=search || "";
  const data = await instance.get(`admin/list-wallet`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Fetching Wallet List  Api
export const ViewTransactionController = async (id) => {
  const data = await instance.get(
    `admin/user-wallet-detail?id=${id}`,
    setJwtToken()
  );
  return data;
};

//   Fetching Add Amount Handler API
export const subAmountController = async (reqData) => {
  const data = await instance.post(
    `admin/debit-wallet`,
    reqData,
    setJwtToken()
  );
  return data;
};

//   Fetching Subtract Amount Handler API
export const addAmountController = async (reqData) => {
  const data = await instance.post(
    `admin/credit-wallet`,
    reqData,
    setJwtToken()
  );
  return data;
};
