import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({
  value,
  onChange,
  label,
  error,
  required,
  maxLength,
  ...rest
}) => {
;
  return (
    <div>
      {label && <label className="my-1">{label}</label>}
      {required && <span className="text-danger"> *</span>}
      <Editor
        editorState={value}
        wrapperClassName="demo-wrapper"
        editorClassName={`demo-editor text-editor-section ${
          error && "is-invalid"
        }`}
        onEditorStateChange={onChange}
        placeholder={"Description"}
        {...rest}
        toolbar={{
          image: {
            urlEnabled: true,
            uploadEnabled: false,
            alignmentEnabled: true,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: true, mandatory: false },
            defaultSize: {
              height: "250",
              width: "250",
            },
          },
        }}
      />
      {error && (
        <div className="text-danger" style={{ fontSize: "0.875em" }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default TextEditor;
