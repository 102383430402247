import React, { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { addNewOffersController } from "../../services/offersServices/offersManagementController";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
} from "../../utility/utils";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { Checkbox, FormControlLabel } from "@mui/material";

function AddNewOffers() {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [showDateField,setShowDateField]=useState(false);

  //Input Field Validation
  const validation = () => {
    let error = {};
    let validationStatus = true;
    if (!inputFieldValue.couponCode) {
      error["couponCode"] = "Coupon Code is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === "1" && !inputFieldValue.couponAmount) {
      error["couponAmount"] = "Amount is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponDescription === "<p><br></p>") {
      error["couponDescription"] = "Coupon Description is Mandatory";
      validationStatus = false;
    }
    if (!inputFieldValue.couponTitle) {
      error["couponTitle"] = "Coupon Title is Mandatory";
      validationStatus = false;
    }
    if (!inputFieldValue.minAmount) {
      error["minAmount"] = "Min-Amount is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === "" || !inputFieldValue.couponType) {
      error["couponType"] = "Select Coupon Type";
      validationStatus = false;
    }
    if (
      inputFieldValue.couponType === "2" &&
      !inputFieldValue.couponInPercent
    ) {
      error["couponInPercent"] = "Coupon Percentage is Mandatory";
      validationStatus = false;
    }
    if (Number(inputFieldValue.couponInPercent) > 100) {
      error["couponInPercent"] =
        "Coupon Percentage should not greater than 100";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === "2" && !inputFieldValue.maxAmount) {
      error["maxAmount"] = "Max-Discount is Mandatory";
      validationStatus = false;
    }
    if (!showDateField && !inputFieldValue.startDate) {
      error["startDate"] = "Start Date is Mandatory";
      validationStatus = false;
    }
    if (!showDateField && !inputFieldValue.endDate) {
      error["endDate"] = "End Date is Mandatory";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  const addNewOffers = async (e) => {
    e.preventDefault();
    const status = validation();
    // console.log(status, "--->Status");
    if (status) {
      setSubmitButton(true);
      try {
        const data = {
          couponCode: inputFieldValue.couponCode,
          couponType: Number(inputFieldValue.couponType),
          couponTitle: inputFieldValue.couponTitle,
          minAmount: Number(inputFieldValue.minAmount),
          couponDescription: inputFieldValue.couponDescription,
          type: showDateField?2:1,
        };
        if (!showDateField) {
          Object.assign(data, {
            startDate: dateFormatYear(inputFieldValue.startDate),
            endDate: dateFormatYear(inputFieldValue.endDate),
          });
        }

        if (inputFieldValue.couponType === "2") {
          Object.assign(data, {
            couponInPercent: Number(inputFieldValue.couponInPercent),
            maxAmount: Number(inputFieldValue.maxAmount),
          });
        } else {
          Object.assign(data, { couponAmount: inputFieldValue.couponAmount });
        }

        const response = await addNewOffersController(data);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setSubmitButton(false);
          navigate(-1);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  // Handling Input Fields
  const inputFieldHandler = (e, type = "") => {
    const { name, value, checked } = e.target;

    const sanitizeNumberInput = (inputValue) => {
      // Remove non-numeric and non-dot characters
      let updatedValue = inputValue.replace(/[^0-9.\s]/g, "");
      // Restrict to 2 decimal places
      updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1");
      return updatedValue;
    };

    const sanitizeCouponCodeInput = (inputValue) => {
      return inputValue.replace(/\s/g, "");
    };

    const sanitizeCTInput = (inputValue) => {
      return inputValue.replace(/^\s+/, "");
    };

    switch (type) {
      case "number":
        if (typeof value === "string") {
          const updatedValue = sanitizeNumberInput(value);
          setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
        }
        break;

      case "couponCode":
        if (typeof value === "string") {
          const updatedValue = sanitizeCouponCodeInput(value);
          setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
        }
        break;

      case "ct":
        if (typeof value === "string") {
          const updatedValue = sanitizeCTInput(value);
          setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
        }
        break;
        case "spinType":
          setInputFieldValue({
            ...inputFieldValue,
            [name]: checked ? "2" : "1"
          });
          setShowDateField(checked); 
          dateFieldHandler();
          break;
        

      case "offerType":
        if (value === "1") {
          setInputFieldValue({
            ...inputFieldValue,
            [name]: value,
            maxAmount: null,
            couponInPercent: null,
          });
        } else if (value === "2") {
          setInputFieldValue({
            ...inputFieldValue,
            [name]: value,
            couponAmount: null,
          });
        }
        break;

      default:
        setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  //   Config for Jodit Editor
  const config = useMemo(
    () => ({
      buttons: [
        "source",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "paragraph",
        "color",
        "table",
        "link",
        "undo",
        "redo",
        "cut",
        "hr",
        "eraser",
        "copyformat",
        "selectall",
        "formatting",
        "align",
        "removeformat",
        "fullsize",
        "about",
      ],
    }),
    []
  );

  const dateFieldHandler = () => {
    setInputFieldValue({
      ...inputFieldValue,
      startDate: "",
      endDate: "",
    });
  };

  // console.log(inputFieldValue);
  const editor = useRef(null);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Add New Offers
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Code <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={40}
                              className="form-control"
                              name="couponCode"
                              placeholder="Enter Coupon Code"
                              value={inputFieldValue.couponCode}
                              onChange={(e) => {
                                inputFieldHandler(e, "couponCode");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.couponCode}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Type <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              type="text"
                              className="form-control"
                              name="couponType"
                              placeholder="Enter Coupon Type"
                              value={inputFieldValue.couponType}
                              onChange={(e) => {
                                inputFieldHandler(e, "offerType");
                              }}
                            >
                              <option value={""}>Choose Coupon Type</option>
                              <option value={"2"}>Percentage</option>
                              <option value={"1"}>Flat</option>
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.couponType}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {inputFieldValue.couponType &&
                      inputFieldValue.couponType === "2" ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon% <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="couponInPercent"
                                maxLength={10}
                                placeholder="Enter Coupon Percent"
                                value={
                                  inputFieldValue.couponInPercent
                                    ? inputFieldValue.couponInPercent
                                    : ""
                                }
                                onChange={(e) => {
                                  inputFieldHandler(e, "number");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponInPercent}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Amount <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                name="couponAmount"
                                placeholder="Enter Coupon Amount"
                                value={
                                  inputFieldValue.couponAmount
                                    ? inputFieldValue.couponAmount
                                    : ""
                                }
                                onChange={(e) => {
                                  inputFieldHandler(e, "number");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Min Amount <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="minAmount"
                              placeholder="Enter min-amount"
                              value={inputFieldValue.minAmount}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.minAmount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="row">
                      {inputFieldValue.couponType === "2" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Max Discount <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                name="maxAmount"
                                type="text"
                                maxLength={7}
                                className="form-control"
                                placeholder="Enter max-discount"
                                value={inputFieldValue.maxAmount}
                                onChange={(e) => {
                                  inputFieldHandler(e, "number");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.maxAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {inputFieldValue.couponType === "2" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Title <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                name="couponTitle"
                                type="text"
                                maxLength={25}
                                className="form-control"
                                placeholder="Enter Coupon Title"
                                value={
                                  inputFieldValue.couponTitle
                                    ? inputFieldValue.couponTitle
                                    : ""
                                }
                                onChange={(e) => {
                                  inputFieldHandler(e, "ct");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponTitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/*  */}

                    {/* {inputFieldValue.type !== "2" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Start Date
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="col-sm-9 text-start">
                                <div className="react-datepicker-wrapper_retailer">
                                  <DatePicker
                                    placeholderText="Coupon Start Date"
                                    className="form-control"
                                    selected={inputFieldValue.startDate}
                                    value={inputFieldValue.startDate}
                                    showYearDropdown
                                    isClearable
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat="dd/MM/yyyy"
                                    minDate={Date.now()}
                                    onChange={(date) => {
                                      setInputFieldValue({
                                        ...inputFieldValue,
                                        startDate: date,
                                      });
                                    }}
                                    onChangeRaw={(e) => e.preventDefault()}
                                  />
                                  <p style={{ color: "red" }} className="error">
                                    {errors.startDate}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon End Date
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="react-datepicker-wrapper_retailer">
                                <DatePicker
                                  placeholderText="Coupon End Date"
                                  className="form-control"
                                  selected={inputFieldValue.endDate}
                                  value={inputFieldValue.endDate}
                                  showYearDropdown
                                  isClearable
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat="dd/MM/yyyy"
                                  minDate={inputFieldValue.startDate}
                                  onChange={(date) => {
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      endDate: date,
                                    });
                                  }}
                                  onChangeRaw={(e) => e.preventDefault()}
                                />
                                <p style={{ color: "red" }} className="error">
                                  {errors.endDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      {inputFieldValue.couponType !== "2" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Title
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                name="couponTitle"
                                type="text"
                                maxLength={25}
                                className="form-control"
                                placeholder="Enter Coupon Title"
                                value={
                                  inputFieldValue.couponTitle
                                    ? inputFieldValue.couponTitle
                                    : ""
                                }
                                onChange={(e) => {
                                  inputFieldHandler(e, "ct");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponTitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="col-sm-9 text-start">
                            <FormControlLabel
                              label={"Spin Type"}
                              control={
                                <Checkbox
                                  name={"type"}
                                  value={inputFieldValue.type}
                                  onChange={(e) => {
                                    inputFieldHandler(e, "spinType");
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      ["startDate"]: "",
                                      ["endDate"]: "",
                                    });
                                  }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {!showDateField && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Start Date<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="react-datepicker-wrapper_retailer">
                                <DatePicker
                                  placeholderText="Coupon Start Date"
                                  className="form-control"
                                  selected={inputFieldValue.startDate}
                                  showYearDropdown
                                  isClearable
                                  // disabled={inputFieldValue.type==="1"}
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat="dd/MM/yyyy"
                                  minDate={Date.now()}
                                  onChange={(date) => {
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      startDate: date,
                                    });
                                  }}
                                  onChangeRaw={(e) => e.preventDefault()}
                                />
                                <p style={{ color: "red" }} className="error">
                                  {errors.startDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon End Date<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="react-datepicker-wrapper_retailer">
                                <DatePicker
                                  placeholderText="Coupon End Date"
                                  className="form-control"
                                  selected={inputFieldValue.endDate}
                                  showYearDropdown
                                  isClearable
                                  // disabled={inputFieldValue.type==="1"}
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat="dd/MM/yyyy"
                                  minDate={inputFieldValue.startDate}
                                  onChange={(date) => {
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      endDate: date,
                                    });
                                  }}
                                  onChangeRaw={(e) => e.preventDefault()}
                                />
                                <p style={{ color: "red" }} className="error">
                                  {errors.endDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     )} 

                    <div className="row">
                      {inputFieldValue.couponType !== "2" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Title <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                name="couponTitle"
                                type="text"
                                maxLength={25}
                                className="form-control"
                                placeholder="Enter Coupon Title"
                                value={inputFieldValue.couponTitle || ""}
                                onChange={(e) => {
                                  inputFieldHandler(e, "ct");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponTitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Adding Type  */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="text-start">
                            <FormControlLabel
                            className="cstm_label_form"
                              label={"Spin Type"}
                              control={
                                <Checkbox
                                  name={"type"}
                                  value={inputFieldValue.type}
                                  onChange={(e) => {
                                    inputFieldHandler(e, "spinType");          
                                  }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Terms and Conditions <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-10 text-start cstm_width2">
                            <JoditEditor
                              ref={editor}
                              value={inputFieldValue?.couponDescription}
                              tabIndex={1}
                              onBlur={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  couponDescription: newContent,
                                });
                              }}
                              onChange={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  couponDescription: newContent,
                                });
                              }}
                              config={config}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.couponDescription}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      ref={submitButton}
                      className="btn btn-gradient-primary mb-2"
                      onClick={(e) => addNewOffers(e)}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddNewOffers;
