import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  updateCodController,
  updateMinimumOrderThresholdController,
} from "../../services/recommendedServices/recommendedController";
import { pointsListingController } from "../../services/pointsManagementServices/pointsManagementController";

const CodManagement = () => {
  const navigate = useNavigate();
  const [inputFieldValue, setInputFieldValue] = useState({
    cod: "",
    threshold: "",
  });


  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await pointsListingController();
        if (response?.data?.data) {
          const codData = response.data.data.find((item) => item.title === "COD charges");
          const thresholdData = response.data.data.find((item) => item.title === "Minimum order threshold");

          setInputFieldValue({
            cod: codData?.amount || "",
            threshold: thresholdData?.amount || "",
          });
        } else {
          toast.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("An error occurred while fetching data.");
      }
    };
    
    fetchApiData();
  }, []);

  const handleCodUpdate = async () => {
    try {
      const response = await updateCodController(inputFieldValue.cod);
      if (response?.data?.success) {
        toast.success("COD updated successfully!");
      } else {
        toast.error("Failed to update COD.");
      }
    } catch (error) {
      toast.error("An error occurred while updating COD.");
      console.error(error);
    } finally {
    }
  };

  const handleThresholdUpdate = async () => {
    try {
      const response = await updateMinimumOrderThresholdController(inputFieldValue.threshold);
      if (response?.data?.success) {
        toast.success("Minimum order threshold updated successfully!");
      } else {
        toast.error("Failed to update minimum order threshold.");
      }
    } catch (error) {
      toast.error("An error occurred while updating minimum order threshold.");
      console.error(error);
    } finally {
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFieldValue((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              COD Management
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card cstm_card_height">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                          COD charges <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              placeholder="Enter COD Value"
                              name="cod"
                              value={inputFieldValue.cod}
                   onChange={handleInputChange}
                            />
                           
                          </div>
                          <div className="col-sm-3 text-start">
                            <button
                              type="button"
                              className="btn btn-gradient-primary mb-2 mr-2"
                              onClick={handleCodUpdate}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                          Minimun order threshold <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              placeholder="Enter Threshold Value"
                              name="threshold"
                              value={inputFieldValue.threshold}
                              onChange={handleInputChange}
                            />
                           
                          </div>
                          <div className="col-sm-3 text-start">
                            <button
                              type="button"
                              className="btn btn-gradient-primary mb-2 mr-2"
                              onClick={handleThresholdUpdate}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CodManagement;
