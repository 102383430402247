export const permissionChecker = (permission = [], moduleId, roleId) => {
  const findRoles = permission.find((data) => data.moduleId === moduleId);

  if (!findRoles && localStorage.getItem("role") !== "admin") {
    return false;
  }

  const hasAccess =
    (findRoles && findRoles.accessIds && findRoles.accessIds.includes(roleId)) ||
    localStorage.getItem("role") === "admin";

  return hasAccess;
};



// Action Checker
export const actionsChecker = (permission = [], moduleId) => {
  const updatedPermission = permission.map((item) => ({
    ...item,
    moduleDetails: {
      ...item.moduleDetails,
      access: item?.moduleDetails?.access.split(",").map(Number),
    },
  }));

  // console.log(permission,"permission---->");
  // console.log(updatedPermission,"<----UpdatedPermissions----->");

  const hasAccess = updatedPermission.some((data) =>
    data?.moduleId === moduleId &&
    data?.moduleDetails?.access && data?.accessIds &&
    data?.moduleDetails?.access.some(accessId => data.accessIds.includes(accessId) && accessId !==1)
  );
  // console.log(hasAccess, "hasAccess action checker");

  if (!hasAccess && localStorage.getItem("role") !== "admin") {
    return false;
  }

  return hasAccess || localStorage.getItem("role") === "admin";
};








