import { instance, multipartJwtToken, setJwtToken } from "../../config/axiosInstance";

// Fetching Training Module List Api
export const trainingListController = async () => {
  const data = await instance.get(`admin/training-list`,setJwtToken());
  return data;
};

// Add Training Module
export const addTrainingController = async (formData) => {
    const data = await instance.post(`admin/add-training-url`,formData,multipartJwtToken());
    return data;
  };

  // Edit Training Module
export const editTrainingController = async (formData) => {
    const data = await instance.post(`admin/update-training-url`,formData,multipartJwtToken());
    return data;
  };

  // Delete Training Module
  export const deleteTrainingController = async (id) => {
    const data = await instance.delete(`admin/delete-training/${id}`,setJwtToken());
    return data;
  };
