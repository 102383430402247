import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Product List Api
export const pointsListingController = async () => {
  const  data = await instance.get(`admin/setting`, setJwtToken());
  return data;
};

// Fetching Product List Api
export const changePointStatusController = async (id) => {
    const  data = await instance.post(`admin/update-setting-status`,{id:id}, setJwtToken());
    return data;
  };

  // Fetching Product List Api
export const updatePointController = async (reqData) => {
    const  data = await instance.post(`admin/update-setting-data`,reqData, setJwtToken());
    return data;
  };