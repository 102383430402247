import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ModalBox from "../../common/ModalBox";
import {
  addBannerController,
  bannerListController,
  deleteBannerController,
  updateBannerController,
  updateBannerStatusController,
} from "../../services/bannerServices/bannerController";
import { toast } from "react-toastify";
import { dateFormat, showDateFormatYear } from "../../utility/utils";
import { FormControlLabel } from "@mui/material";
import ToggleStatus from "../../common/ToggleStatus";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useButtonLoader from "../../hooks/useButtonLoader";
import Pagination from "react-js-pagination";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";

function BannerManagement() {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [bannerId, setBannerId] = useState();
  const [updateBannerdata, setUpdateBannerData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [bannerListData, setBannerListData] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const [imageFile, setImageFile] = useState({});
  const [updateImage, setUpdateImage] = useState({});
  const [loader, show, hide] = useFullPageLoader();
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Submit",
    "Please wait"
  );
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const inputRef = useRef(null);
  const { permission } = usePermission();

  // Fetching Banner List Api
  const fetchingBannerManagementList = async () => {
    try {
      show();
      const response = await bannerListController(
        limit ? limit : "",
        activePage ? activePage : ""
      );
      if (response?.code === 200) {
        setBannerListData(response?.data?.rows);
        setTotalList(response?.data?.count);
        // console.log(response?.data);
        hide();
      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Input Field Validation For Adding Banner
  const formValidation = () => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    let validationStatus = true;
    const error = {};

    if (!inputFieldValue.bannerName) {
      error["bannerName"] = "Please enter banner name";
      validationStatus = false;
    }

    if (!imageFile?.name) {
      error["bannerImage"] = "Please upload an image";
      validationStatus = false;
    } else if (!validImageTypes.includes(imageFile.type)) {
      error["bannerImage"] = "Please upload a valid format (JPEG,PNG,JPG).";
      validationStatus = false;
    }

    setErrors(error);
    return validationStatus;
  };

  // Fetching Add Banner Api
  const handleAddBannerModal = async (e) => {
    e.preventDefault();
    const status = formValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const formData = new FormData();
        formData.append("bannerName", inputFieldValue.bannerName);
        formData.append("bannerUrl", inputFieldValue.bannerUrl);
        formData.append("bannerImage", imageFile);

        const response = await addBannerController(formData);
        // console.log(response, "response Add banner");
        if (response.code === 200) {
          setShowModal(false);
          setButtonLoader(false);
          toast.success(response.message);
          setInputFieldValue({});
          setImageFile({});
          fetchingBannerManagementList();
        } else {
          toast.error(response.message);
          setButtonLoader(false);
          setShowModal(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  // Fetching Delete Banner API
  const deleteBannerHandler = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      const response = await deleteBannerController(bannerId);
      if (response?.data?.code === 200) {
        setDeleteModal(false);
        toast.success(response.data?.message);
        fetchingBannerManagementList();
        setButtonLoader(false);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setDeleteModal(false);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
    }
  };

  //Input Field Validation For Updating Banner
  const updateFormValidation = () => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    let validationStatus = true;
    const error = {};

    if (!updateBannerdata.bannerName) {
      error["bannerName"] = "Please enter banner name";
      validationStatus = false;
    }
    if (updateImage?.name) {
      if (!validImageTypes.includes(updateImage.type)) {
        error["bannerImage"] = "Please upload a valid format (JPEG,PNG,JPG).";
        validationStatus = false;
      }
    }

    setErrors(error);
    return validationStatus;
  };

  //Fetching Update Banner Api
  const handleUpdateBannerModal = async (e) => {
    e.preventDefault();
    const status = updateFormValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const formData = new FormData();
        formData.append("bannerName", updateBannerdata.bannerName);
        formData.append("bannerUrl", updateBannerdata.bannerUrl);
        formData.append("id", bannerId);
        formData.append("bannerImage", updateImage);

        const response = await updateBannerController(formData);
        // console.log(response, "response Add banner");
        if (response.code === 200) {
          setEditModal(false);
          toast.success(response.message);
          setButtonLoader(false);
          fetchingBannerManagementList();
        } else {
          toast.error(response.message);
          setButtonLoader(false);
          setEditModal(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  //Fetching Update Status Api
  const updateBannerStatus = async () => {
    try {
      setButtonLoader(true);
      const response = await updateBannerStatusController(bannerId);
      if (response.code === 200) {
        setStatusModal(false);
        toast.success(response.message);
        fetchingBannerManagementList();
        setButtonLoader(false);
      } else {
        setStatusModal(false);
        toast.error(response.message);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e);
      setButtonLoader(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingBannerManagementList();
  }, [limit, activePage]);

  useEffect(() => {
    if (editModal === false || showModal === false) {
      setErrors({});
    }
  }, [editModal, showModal]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h3 class="page-title"> Banner Management </h3>
            {permissionChecker(permission, 19, 1) && (
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <button
                    onClick={() => setShowModal(true)}
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                  >
                    Add Banner
                  </button>
                </ol>
              </nav>
            )}
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
          </div>
          {loader}
          <div class="row wrap_selectCstm">
            <div class="col-lg-12 grid-margin stretch-card cstm_padding_set">
              <div class="card">
                <div class="card-body">
                  <div className="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Banner Name</th>
                          <th>Banner Image</th>
                          <th>Created At</th>
                          {actionsChecker(permission, 19) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {bannerListData && bannerListData.length > 0 ? (
                          bannerListData.map(
                            (
                              {
                                id,
                                bannerName,
                                bannerImage,
                                bannerUrl,
                                bannerStatus,
                                createdAt,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{bannerName}</td>
                                    <td>
                                      <img
                                        src={
                                          bannerImage
                                            ? bannerImage
                                            : "../../assets/images/user.png"
                                        }
                                        alt={bannerName}
                                      />
                                    </td>
                                    <td>{showDateFormatYear(createdAt)}</td>
                                    {actionsChecker(permission, 19) && (
                                      <td>
                                        <div className="action_button_wrap">
                                          <div className="switch_button">
                                            {permissionChecker(
                                              permission,
                                              19,
                                              5
                                            ) && (
                                              <FormControlLabel
                                                control={
                                                  <ToggleStatus
                                                    onClick={() => {
                                                      setBannerId(id);

                                                      setStatusModal(true);
                                                    }}
                                                    sx={{ m: 1 }}
                                                    checked={bannerStatus == 1}
                                                  />
                                                }
                                              />
                                            )}
                                            {permissionChecker(
                                              permission,
                                              19,
                                              2
                                            ) && (
                                              <Link
                                                to="#"
                                                className="action_button"
                                              >
                                                <i
                                                  class="mdi mdi-table-edit"
                                                  onClick={() => {
                                                    setEditModal(true);
                                                    setBannerId(id);
                                                    setUpdateBannerData({
                                                      ...updateBannerdata,
                                                      bannerName: bannerName,
                                                      bannerUrl: bannerUrl,
                                                    });
                                                    setImageFile(bannerImage);
                                                  }}
                                                ></i>
                                              </Link>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              19,
                                              4
                                            ) && (
                                              <Link className="action_button">
                                                <DeleteRoundedIcon
                                                  sx={{ color: red[500] }}
                                                  onClick={() => {
                                                    setBannerId(id);
                                                    setDeleteModal(true);
                                                  }}
                                                />
                                              </Link>
                                            )}
                                          </div>
                                        </div>

                                        {/* <ModeEditIcon
                                      color="success"
                                      onClick={() => {
                                        setEditModal(true);
                                        setBannerId(id);
                                        setUpdateBannerData({
                                          ...updateBannerdata,
                                          bannerName: bannerName,
                                          bannerUrl: bannerUrl,
                                        });
                                        setImageFile(bannerImage);
                                      }}
                                    /> */}
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={5}> No banner found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {totalList && totalList > 0 && (
                        <h5>
                          Showing {startNumber} to{" "}
                          {startNumber + bannerListData?.length - 1} of{" "}
                          {totalList || ""} entries
                        </h5>
                      )}
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Box for adding banner*/}
          <ModalBox
            status={showModal}
            onClose={() => {
              {
                setShowModal(false);
                setInputFieldValue("");
                setImageFile("");
              }
            }}
            title={"Add Banner Management"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    <div class="form-group">
                      <label htmlFor="Banner ">
                        Enter Banner Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputName1"
                        maxLength={25}
                        placeholder="Enter Banner Name"
                        onChange={(e) =>
                          setInputFieldValue({
                            ...inputFieldValue,
                            bannerName: e.target.value,
                          })
                        }
                      />
                      <span style={{ color: "red" }} className="error">
                        {errors.bannerName}
                      </span>
                    </div>
                    <div class="form-group d-flex justify-content-between align-items-center">
                      <label>
                        File upload (Size 1426 x 420)
                        <span className="text-danger">*</span>
                      </label>

                      <div class="">
                        <input
                          ref={inputRef}
                          type="file"
                          class="form-control file-upload-info"
                          hidden
                          placeholder="Upload Image"
                          accept=".png"
                          onChange={(e) => {
                            setImageFile(e.target.files[0]);
                            // console.log(e.target.files, "------>Files");
                          }}
                        />
                        <span class="input-group-append">
                          <button
                            class="file-upload-browse btn btn-gradient-primary"
                            type="button"
                            onClick={() => inputRef.current?.click()}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors.bannerImage}
                    </span>
                    <div className="mb-4">
                      {imageFile && imageFile?.name && (
                        <div className="cstm_modal_img_wrap">
                          <img
                            src={URL.createObjectURL(imageFile)}
                            class="mb-2 mw-100 w-100 rounded"
                            alt="banner"
                          />
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          setShowModal(false);
                          setInputFieldValue("");
                          setImageFile("");
                          e.preventDefault();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        ref={buttonLoader}
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          handleAddBannerModal(e);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/* Modal Box for updating banner*/}
          <ModalBox
            status={editModal}
            onClose={() => {
              setEditModal(false);
            }}
            title={"Update Banner Management"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    <div class="form-group">
                      <label htmlFor="Banner ">
                        Enter Banner Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputName1"
                        value={updateBannerdata.bannerName}
                        maxLength={40}
                        name="bannerName"
                        placeholder="Enter Banner Name"
                        onChange={(e) =>
                          setUpdateBannerData({
                            ...updateBannerdata,
                            bannerName: e.target.value,
                          })
                        }
                      />
                      <span style={{ color: "red" }} className="error">
                        {errors.bannerName}
                      </span>
                    </div>
                    <div class="form-group d-flex justify-content-between align-items-center">
                      <label>
                        File upload (Size 1426 x 420){" "}
                        <span className="text-danger">*</span>
                      </label>

                      <div class="">
                        <input
                          ref={inputRef}
                          type="file"
                          maxLength={25}
                          class="form-control file-upload-info"
                          hidden
                          placeholder="Upload Image"
                          accept="image/*"
                          onChange={(e) => setUpdateImage(e.target.files[0])}
                        />
                        <span class="input-group-append">
                          <button
                            class="file-upload-browse btn btn-gradient-primary"
                            type="button"
                            onClick={() => inputRef.current?.click()}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors.bannerImage}
                    </span>
                    <div className="mb-4">
                      {updateImage?.name ? (
                        <div className="cstm_modal_img_wrap">
                          <img
                            src={URL.createObjectURL(updateImage)}
                            alt="not found"
                            class="mb-2 mw-100 w-100 rounded"
                          />
                        </div>
                      ) : (
                        <div className="cstm_modal_img_wrap">
                          <img
                            src={imageFile}
                            alt="not found"
                            class="mb-2 mw-100 w-100 rounded"
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          handleUpdateBannerModal(e);
                        }}
                        ref={buttonLoader}
                      >
                        Update
                      </button>
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          setEditModal(false);
                          e.preventDefault();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/*Updating status modal*/}
          <ModalBox
            status={statusModal}
            onClose={() => setStatusModal(false)}
            title="Change Status"
            description="Are you sure you want to change the status of banner ?"
            action={updateBannerStatus}
            uploadButtonElement={buttonLoader}
          />

          {/* Delete Banner*/}
          <ModalBox
            status={deleteModal}
            onClose={() => setDeleteModal(false)}
            title="Delete Banner"
            description={"Are you sure you want to delete this banner?"}
            action={deleteBannerHandler}
            uploadButtonElement={buttonLoader}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default BannerManagement;
