import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addSubAdminController,
  subAdminAccessListController,
  subAdminModuleListController,
} from "../../services/subAdminServices/subAdminServices";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";
import { isValidEmail, isValidPhoneNumber } from "../../utility/formValidation";

const AddMember = () => {
  const navigate = useNavigate();
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const [moduleName, setModuleName] = useState([]);
  const [accessKey, setAccessKey] = useState([]);
  const [permissionArray, setPermissionArray] = useState([]);

  const fetchModuleList = async () => {
    try {
      const response = await subAdminModuleListController();
      if (response?.data?.code === 200) {
        setModuleName((prevModuleName) => [
          ...prevModuleName,
          ...(response?.data?.data || []).map((item) => ({
            ...item,
            access: item.access.split(","),
          })),
        ]);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    if (type === "number") {
      let updatedValue = value.replace(/[^0-9]/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text") {
      let updatedValue = value.replace(/^\s+|\d+/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  // Checkbox Handler
  const checkBoxHandler = (e, accessId="name", moduleId="moduleId", index) => {
    const { name, value } = e.target;
    const selectedObjectIndex = permissionArray.findIndex(
      (module) => module.moduleKey === moduleId
    );
    const newPermissionArray = [...permissionArray];

    if (selectedObjectIndex === -1) {
      newPermissionArray.push({ moduleKey: moduleId, accessKey: [accessId] });
    } else {
      const accessIndex =
        newPermissionArray[selectedObjectIndex].accessKey.indexOf(accessId);

      if (accessIndex !== -1) {
        newPermissionArray[selectedObjectIndex].accessKey.splice(
          accessIndex,
          1
        );
      } else {
        newPermissionArray[selectedObjectIndex].accessKey.push(accessId);
      }

      // If the accessKey array becomes empty, remove the corresponding object.
      if (newPermissionArray[selectedObjectIndex].accessKey.length === 0) {
        newPermissionArray.splice(selectedObjectIndex, 1);
      }
    }

    // Update the state with the newPermissionArray.
    setPermissionArray(newPermissionArray);
  };

  //   Validate Input Field
  const validation = () => {
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.name) {
      validationStatus = false;
      error["name"] = "Name is Mandatory *";
    }
    if (!inputFieldValue.email) {
      validationStatus = false;
      error["email"] = "Email is Mandatory *";
    } else if (!isValidEmail(inputFieldValue.email)) {
      validationStatus = false;
      error["email"] = "Enter Valid Email *";
    }
    if (!inputFieldValue.mobileNo) {
      validationStatus = false;
      error["mobileNo"] = "Mobile Number is Mandatory *";
    } else if (!isValidPhoneNumber(inputFieldValue.mobileNo)) {
      validationStatus = false;
      error["mobileNo"] = "Enter Valid Mobile Number *";
    }
    if (!inputFieldValue.password) {
      validationStatus = false;
      error["password"] = "Password is Mandatory *";
    }
    setErrors(error);
    return validationStatus;
  };

  // Add SubAdmin Api Handler
  const addSubAdmin = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      try {
        const response = await addSubAdminController({
          ...inputFieldValue,
          permissions: permissionArray,
          role: "SubAdmin",
          mobileNo: Number(inputFieldValue.mobileNo),
        });
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          navigate(-1);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  console.log("permisson Array", permissionArray);

  useEffect(() => {
    fetchModuleList();
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <h2 className="page-title">
            <span
              className="page-title-icon bg-gradient-primary text-white me-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="mdi mdi-chevron-double-left"></i>
            </span>
            Add Member
          </h2>
          <nav aria-label="breadcrumb"></nav>
        </div>
        <div className="wrap_Add_retailer">
          <div class="col-md- grid-margin stretch-card">
            <div class="card ">
              <div class="card-body">
                <form className="form-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={60}
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            value={inputFieldValue.name}
                            onChange={(e) => inputFieldHandler(e, "text")}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={60}
                            placeholder="Enter Email Address"
                            className="form-control"
                            name="email"
                            value={inputFieldValue.email}
                            onChange={(e) => {
                              inputFieldHandler(e, "email");
                            }}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={10}
                            name="mobileNo"
                            placeholder="Enter Mobile Number"
                            value={inputFieldValue.mobileNo}
                            onChange={(e) => {
                              inputFieldHandler(e, "number");
                            }}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.mobileNo}
                          </span>
                        </div>
                      </div>
                      {/* moduleId */}
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Password <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            className="form-control"
                            name="password"
                            placeholder="Enter Password"
                            value={inputFieldValue.password}
                            onChange={inputFieldHandler}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.password}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {moduleName.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <div className="col-6 mb-4 text-start">
                            <h5>{item?.name}</h5>
                            {item.access.map((accessId) => {
                              const labelValue =
                                accessId === "1"
                                  ? "add"
                                  : accessId === "2"
                                  ? "edit"
                                  : accessId === "3"
                                  ? "view"
                                  : accessId === "4"
                                  ? "delete"
                                  : accessId === "5"
                                  ? "status":accessId==="6"?"request"
                                  : null;

                              return (
                                <FormControlLabel
                                  key={accessId} 
                                  label={labelValue}
                                  name={labelValue}
                                  value={accessId}
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        checkBoxHandler(
                                          e,
                                          accessId,
                                          item.id,
                                          index
                                        );
                                      }}
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                          {/* </div> */}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      class="btn btn-gradient-primary mx-3 mt-3"
                      onClick={addSubAdmin}
                    >
                      Submit
                    </button>
                    <button class="btn btn-secondary mt-3">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
