import React, { createContext, useContext, useState } from 'react';

// Create a new context
const PermissionContext = createContext();

export function usePermission() {
  return useContext(PermissionContext);
}

export function PermissionProvider({ children }) {
    const storedArray = localStorage.getItem('Permission');
    const storedArrayMain = JSON.parse(storedArray);

  const [permission, setPermission] = useState(storedArrayMain || []);

  return (
    <PermissionContext.Provider value={{permission,setPermission}}>
      {children}
    </PermissionContext.Provider>
  );
}
