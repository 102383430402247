import React from 'react'

function Loader() {
  return (
    <div className="btn--loading loader_wrapper">
        <div class="dot-spin"></div>
    </div>
  )
}

export default Loader