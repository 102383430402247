import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";

// Fetching Scheme List Api
export const bundleListController = async (page, limit, search) => {
  const params = {};
  params.page = page || "";
  params.limit = limit || "";
  params.search = search || "";
  const data = await instance.get(`admin/bundle-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Add Scheme
export const addBundleController = async (reqData) => {
  const data = await instance.post(
    `admin/create-bundle`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// View Bundle Details
export const viewBundleDetailController = async (bundleId) => {
  const data = await instance.get(
    `admin/bundle-detail?bundleId=${bundleId}`,
    setJwtToken()
  );
  return data;
};

// Edit Bundle Controller
export const editBundleController = async (reqData) => {
  const data = await instance.post(
    `admin/update-bundle`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// Delete Bundle Controller
export const deleteBundleController = async (id) => {
  const data = await instance.delete(
    `admin/delete-bundle/${id}`,
    setJwtToken()
  );
  return data;
};

// Change Bundle Status Controller
export const changeBundleStatusController = async (id) => {
  const data = await instance.post(
    `admin/change-bundle-status`,
    { id: id },
    setJwtToken()
  );
  return data;
};
