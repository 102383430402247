import React from "react";
import { Link } from "react-router-dom";

const GameManagement = () => {
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h1 className="page-title">Game Management</h1>
            </div>
          </div>
          <div className="card mb-4">
            <Link className="class_overwrite_anchor" to={`/game-management/add-rule`}>
              <div className="card-body">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-10">
                    <p className="margin-bottom-unset">
                      Set Prizes And Probability
                    </p>
                  </div>
                  <div className="col-2">
                    <i className="mdi mdi-arrow-right-bold mdi_arrow_bold"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-between  align-items-center">
                <div className="col-10">
                  <p className="margin-bottom-unset">Set Game Rules</p>
                </div>
                <div className="col-2">
                  <Link to="#">
                    <i className="mdi mdi-arrow-right-bold mdi_arrow_bold"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card mt-4"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GameManagement;
