import React, { useRef, useState } from "react";
import ModalBox from "../../common/ModalBox";
import { addTrainingController } from "../../services/trainingServices/trainingController";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";

const AddTraningModule = ({
  errors,
  addModal,
  setAddModal,
  setErrors,
  trainingModuleList,
  inputValue,
  setInputValue,
}) => {
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [thumbnail,setThumbnail]=useState("");
  const validation = () => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    let error = {};
    let validationStatus = true;
    if (!inputValue.moduleName) {
      error["moduleName"] = "Enter Module Name";
      validationStatus = false;
    }
    if (!inputValue.description) {
      error["description"] = "Enter Description";
      validationStatus = false;
    }
    if (!inputValue.link) {
      error["link"] = "Enter Link";
      validationStatus = false;
    }
    if (!thumbnail?.name) {
      error["thumbnailImage"] = "Please upload a Thumbnail";
      validationStatus = false;
    } else if (!validImageTypes.includes(thumbnail.type)) {
      error["thumbnailImage"] = "Please upload a valid format (JPEG,PNG,JPG).";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };
  const addTrainingModuleHandler = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      try {
        setSubmitButton(true);
        const formData=new FormData();
        formData.append("moduleName",inputValue?.moduleName || "");
        formData.append("description",inputValue?.description || "");
        formData.append("link",inputValue?.link || "");
        formData.append("thumbnail",thumbnail);
        const response = await addTrainingController(formData);
        setInputValue({});
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          trainingModuleList();
          setAddModal(false);
          setSubmitButton(false);
          setErrors({});
        } else {
          toast.error(response?.response?.data?.message || response?.message);
          setAddModal(false);
          setSubmitButton(false);
          setErrors({});
        }
      } catch (e) {
        console.log(e.message);
        setAddModal(false);
        setErrors({});
        setSubmitButton(false);
      }
    }
  };

  const inputRef=useRef();

  return (
    <ModalBox
      status={addModal}
      onClose={() => {
        setAddModal(false);
        setErrors({});
        setInputValue({});
        setThumbnail("")
      }}
      title="Add Training Module"
      customizedFooter={<></>}
    >
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <form className="forms-sample">
              <div className="form-group">
                <label htmlFor="moduleName">Module Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={30}
                  id="exampleInputName1"
                  value={inputValue.moduleName}
                  name="moduleName"
                  placeholder="Enter Name"
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      moduleName: e.target.value.replace(/^\s+/, ""),
                    })
                  }
                />
                {!inputValue.moduleName && errors.moduleName && (
                  <span style={{ color: "red" }} className="error">
                    {errors.moduleName}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="mt-2">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  maxLength={200}
                  value={inputValue.description}
                  name="description"
                  placeholder="Write Here..."
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      description: e.target.value.replace(/^\s+/, ""),
                    })
                  }
                />
                {!inputValue.description && errors.description && (
                  <span style={{ color: "red" }} className="error">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="link" className="mt-2">
                  Link <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  value={inputValue.link}
                  name="link"
                  placeholder="link"
                  onChange={(e) =>
                    setInputValue({ ...inputValue, link: e.target.value.replace(/^\s+/, "") })
                  }
                />
                {!inputValue.link && errors.link && (
                  <span style={{ color: "red" }} className="error">
                    {errors.link}
                  </span>
                )}
              </div>
              <div class="form-group d-flex justify-content-between align-items-center">
                      <label>Thumbnail <span className="text-danger">*</span></label>

                      <div class="">
                        <input
                          ref={inputRef}
                          type="file"
                          maxLength={25}
                          class="form-control file-upload-info"
                          hidden
                          placeholder="Upload Image"
                          accept="image/*"
                          onChange={(e) => setThumbnail(e.target.files[0])}
                        />
                        <span class="input-group-append">
                          <button
                            class="file-upload-browse btn btn-gradient-primary"
                            type="button"
                            onClick={() => inputRef.current?.click()}
                          >
                            Upload
                          </button>
                        </span>
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors.thumbnailImage}
                    </span>
                    <div className="mb-4">
                      {thumbnail?.name && (
                        <div className="cstm_modal_img_wrap">
                          <img
                            src={URL.createObjectURL(thumbnail)}
                            // alt="not found"
                            class="mb-2 mw-100 w-100 rounded"
                          />
                        </div>
                      )}
                    </div>
              <div className="form-group">
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button
                    type="submit"
                    ref={submitButton}
                    className="btn btn-gradient-primary me-2"
                    onClick={(e) => {
                      addTrainingModuleHandler(e);
                    }}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={(e) => {
                      setAddModal(false);
                      e.preventDefault();
                      setErrors({});
                      setInputValue({});
                      setThumbnail("")
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
};

export default AddTraningModule;
