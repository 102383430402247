import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";

// Fetching Training Module List Api
export const giftListController = async (page, limit, search) => {
  const params = {
    page: page || "",
    limit: limit || 10,
    search: search || "",
  };
  const data = await instance.get(`admin/gift-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Add Gift Products
export const addGiftController = async (reqData) => {
  const data = await instance.post(
    `admin/create-gift`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// View Gift Details
export const viewGiftDetailController = async (giftId) => {
  const data = await instance.get(
    `admin/gift-details?giftId=${giftId}`,
    setJwtToken()
  );
  return data;
};

// Edit Gift Products
export const editGiftController = async (reqData) => {
  const data = await instance.post(
    `admin/update-gift`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// Delete Gift Controller
export const deleteGiftController = async (giftId) => {
  const data = await instance.delete(
    `admin/delete-gift/${giftId}`,
    setJwtToken()
  );
  return data;
};
