import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { showDateFormatYear } from "../../utility/utils";
import {
  deleteTrainingController,
  trainingListController,
} from "../../services/trainingServices/trainingController";
import AddTraningModule from "./AddTraningModule";
import EditTraningModule from "./EditTrainingModule";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";

const TrainingModule = () => {
  const [trainingList, setTrainingList] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [trainingId, setTrainingId] = useState(null);
  const [trainingDetail, setTrainingDetail] = useState({});
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [inputValue, setInputValue] = useState({});
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const { permission } = usePermission();
  const [showDescription, setShowDescription] = useState("");
  const [showLink, setShowLink] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);

  const trainingModuleList = async () => {
    try {
      show();
      // console.log(activePage);
      const response = await trainingListController();
      // console.log(response?.data?.data?.data, "Response Training Module");
      if (response?.data?.code === 200) {
        hide();
        setTotalList(response?.data?.data?.count);
        setTrainingList(response?.data?.data?.data);
      } else {
        toast.error(response?.response?.data?.message || response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  //   Training Detail Handler
  const trainingDetailHandler = (id, moduleName, description, link,thumbnail) => {
    const data = {
      trainingId: id,
      moduleName,
      description,
      link,
      thumbnail
    };
    setEditModal(true);
    setTrainingDetail(data);
  };

  // Fetching Delete Retailer
  const deleteTrainingHandler = async (e) => {
    e.preventDefault();
    setSubmitButton(true);
    try {
      const response = await deleteTrainingController(trainingId);
      if (response?.data?.code === 200) {
        setDeleteModal(false);
        toast.success(response.data?.message);
        trainingModuleList();
        setSubmitButton(false);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setDeleteModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  useEffect(() => {
    trainingModuleList();
  }, []);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Training Module </h3>
              {permissionChecker(permission, 11, 1) && (
                <div className="button_wrap">
                  <Link
                    to="#"
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i>Add
                    Training Module
                    {/* {console.log(addModal, "addModal")} */}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-lg-3 cstm_padding_set"></div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Created At</th>
                          <th>Module Name</th>
                          <th>Description</th>
                          <th>Link</th>
                          <th>Thumbnail</th>
                          {actionsChecker(permission, 11) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {trainingList && trainingList.length > 0 ? (
                          trainingList.map(
                            (
                              { id, moduleName, description, link, createdAt,thumbnail },
                              index
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{showDateFormatYear(createdAt)}</td>
                                    <td>{moduleName}</td>
                                    <td className="Cstm_see_description">
                                      {description.length > 30 ? (
                                        <>
                                          {description.slice(0, 30)}{" "}
                                          <Link
                                            onClick={() => {
                                              setShowDescription(description);
                                              setDescriptionModal(true);
                                            }}
                                            to="#"
                                          >
                                            see more
                                          </Link>
                                        </>
                                      ) : (
                                        description
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <td className="Cstm_see_description text-center">
                                        {link.length > 30 ? (
                                          <>
                                            {link.slice(0, 30)}{" "}
                                            <Link
                                              onClick={() => {
                                                setShowLink(link);
                                                setLinkModal(true);
                                              }}
                                              to="#"
                                            >
                                              seeMore
                                            </Link>
                                          </>
                                        ) : (
                                          <div className="text-center">
                                          <Link to={`${link}`}> {link}</Link>
                                          </div>
                                        )}
                                      </td>
                                    </td>
                                    <td><img src={thumbnail?thumbnail:"/assets/images/user.png"} /></td>
                                    <td>
                                      <div className="action_button_wrap">
                                        <div className="switch_button">
                                          {permissionChecker(
                                            permission,
                                            11,
                                            2
                                          ) && (
                                            <Link
                                              to="#"
                                              className="action_button"
                                              onClick={() => {
                                                trainingDetailHandler(
                                                  id,
                                                  moduleName,
                                                  description,
                                                  link,
                                                  thumbnail
                                                );
                                              }}
                                            >
                                              <i className="mdi mdi-table-edit"></i>
                                            </Link>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            11,
                                            4
                                          ) && (
                                            <Link className="action_button">
                                              <DeleteRoundedIcon
                                                sx={{ color: red[500] }}
                                                onClick={() => {
                                                  setTrainingId(id);
                                                  setDeleteModal(true);
                                                }}
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={5}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Training Module */}
      <AddTraningModule
        errors={errors}
        addModal={addModal}
        setAddModal={setAddModal}
        setErrors={setErrors}
        trainingModuleList={trainingModuleList}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      {/* Edit Training Module */}
      <EditTraningModule
        errors={errors}
        editModal={editModal}
        setEditModal={setEditModal}
        setErrors={setErrors}
        trainingModuleList={trainingModuleList}
        trainingDetail={trainingDetail}
        setTrainingDetail={setTrainingDetail}
      />

      {/* Delete Training Module */}
      <ModalBox
        status={deleteModal}
        onClose={() => setDeleteModal(false)}
        title="Delete Offer"
        description={"Are you sure you want to delete?"}
        action={deleteTrainingHandler}
        uploadButtonElement={submitButton}
      />

      {/* Show  Description Modal */}
      <ModalBox
        status={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        title=""
        customizedFooter={<></>}
      >
        <div>
          <h5 className="text-center">{showDescription}</h5>
        </div>
      </ModalBox>

      {/* Show Link Modal */}
      <ModalBox
        status={linkModal}
        onClose={() => setLinkModal(false)}
        title=""
        customizedFooter={<></>}
      >
        <div className="text-center">
          <Link to={`${showLink}`} className="text-center">{showLink}</Link>
        </div>
      </ModalBox>
    </React.Fragment>
  );
};

export default TrainingModule;
