import React, { useState, useEffect } from "react";
import {
  QuickMenusListController,
  quickMenusDragDropController,
  quickMenusStatusController,
} from "../../services/homeConfigurationServices/homeConfigurationController";
import { toast } from "react-toastify";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ModalBox from "../../common/ModalBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function QuickMenus() {
  const [quickMenusList, setQuickMenusList] = useState([]);
  const [quickMenusId, setQuickMenusId] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const navigate = useNavigate();

  const fetchingQuickMenus = async () => {
    try {
      const response = await QuickMenusListController();
      if (response?.data?.code === 200) {
        setQuickMenusList(response.data.data);
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Change Quick Menus Status Handler
  const changeQuickMenusStatusHandler = async () => {
    try {
      const response = await quickMenusStatusController(quickMenusId);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchingQuickMenus();
        setStatusModal(false);
      } else {
        toast.error(response.response.data.message);
        setStatusModal(false);
      }
    } catch (e) {
      console.log(e.message);
      setStatusModal(false);
    }
  };

  // Drag and Drop Home Config List
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    try {
      const response = await quickMenusDragDropController(
        Number(result.source.index),
        Number(result.destination.index)
      );
      // console.log(response, "---------->response");
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchingQuickMenus();
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchingQuickMenus();
  }, []);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                Quick Menus
              </h2>
              <div className="button_wrap"></div>
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {/* <tbody> */}
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <tbody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {quickMenusList && quickMenusList.length > 0
                                ? quickMenusList.map(
                                    (
                                      {
                                        homeMenuId,
                                        id,
                                        image,
                                        name,
                                        order,
                                        status,
                                      },
                                      index
                                    ) => (
                                      <Draggable
                                        key={order}
                                        draggableId={order}
                                        index={order} // Assuming order represents the desired index
                                      >
                                        {(provided, snapshot) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <td>{index + 1}</td>
                                            <td>{name}</td>
                                            <td>
                                              <div className="action_button_wrap">
                                                <Link
                                                  to="#"
                                                  className="action_button"
                                                >
                                                  <i className="mdi mdi-arrow-expand-all cstm_expand"></i>
                                                </Link>
                                                <div className="switch_button">
                                                  <FormControlLabel
                                                  className="switch_button"
                                                    control={
                                                      <ToggleStatus
                                                        onClick={() => {
                                                          setStatusModal(true);
                                                          setQuickMenusId(id);
                                                        }}
                                                        sx={{ m: 1 }}
                                                        checked={status === 1}
                                                      />
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    )
                                  )
                                : null}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title="Change Status"
          description={"Are you sure you want to Change Status?"}
          action={changeQuickMenusStatusHandler}
        />
      </div>
    </React.Fragment>
  );
}

export default QuickMenus;
