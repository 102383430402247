import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ModalBox from "../../common/ModalBox";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { orderRequestListController } from "../../services/orderRequestServices/orderRequestController";

function OrderRequestManagement() {
  const [orderRequestList, setOrderRequestList] = useState([]);
  const [search, setSearch] = useState("");
  const [totalList, setTotalList] = useState();
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [showImage, setShowImage] = useState("");

  const fetchOrderManagementList = async () => {
    try {
      show();
      const response = await orderRequestListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      if (response?.data?.code === 200) {
        hide();
        setOrderRequestList(response?.data?.data?.help);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchOrderManagementList();
  }, [debounceValue, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">Order Support Requests</h3>
              <div className="button_wrap"></div>
            </div>
          </div>

          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Name , Email , Phone , Number"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Business Name</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader}
                        {orderRequestList && orderRequestList.length > 0 ? (
                          orderRequestList.map(
                            (
                              {
                                id,
                                name,
                                email,
                                phone,
                                bussinessName,
                                description,
                                image,
                                orderId,
                                userId,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>{name || "---"}</td>
                                    <td>
                                      {/* <img
                                        src={
                                          image
                                            ? image
                                            : "../../assets/images/user.png"
                                        }
                                      /> */}
                                      <Link
                                        onClick={() => {
                                          setImageModal(true);
                                          setShowImage(
                                            image ||
                                              "../../assets/images/user.png"
                                          );
                                        }}
                                        to="#"
                                        className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                      >
                                        View Image
                                      </Link>
                                    </td>
                                    <td>{email || "---"}</td>
                                    <td>{phone || "---"}</td>
                                    <td>{bussinessName || "---"}</td>
                                    <td className="Cstm_see_description">
                                      {description.length > 30 ? (
                                        <>
                                          {description.slice(0, 30)}{" "}
                                          <Link
                                            onClick={() => {
                                              setShowDescription(description);
                                              setDescriptionModal(true);
                                            }}
                                            to="#"
                                          >
                                            see more
                                          </Link>
                                        </>
                                      ) : (
                                        description
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + orderRequestList?.length - 1} of{" "}
                        {totalList} entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Category Name Description Modal */}
        <ModalBox
          status={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          title=""
          customizedFooter={<></>}
        >
          <div>
            <h5 className="text-center">{showDescription}</h5>
          </div>
        </ModalBox>

                  {/* Show Image Modal */}
                  <ModalBox
            status={imageModal}
            onClose={() => {
              setImageModal(false);
            }}
            title={""}
            customizedFooter={<></>}
            size={"lg"}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    {showImage ? (
                      <div class="form-group text-center">
                        {showImage ? (
                          <img src={showImage} height="600px" width="600px" />
                        ) : (
                          "No Image Found"
                        )}
                      </div>
                    ) : (
                      <div className="form-group text-center">
                        <h4>No Image Found</h4>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

      </div>
    </React.Fragment>
  );
}

export default OrderRequestManagement;
