import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cat_1_ListController } from "../../services/homeConfigurationServices/homeConfigurationController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toast } from "react-toastify";
import ModalBox from "../../common/ModalBox";
import Pagination from "react-js-pagination";
import useDebounce from "../../hooks/useDebounce";

const Cat2 = () => {
  const { id,categoryId } = useParams();
  const [loader, show, hide] = useFullPageLoader();
  const [totalList, setTotalList] = useState(null);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [catList, setCatList] = useState([]);
  const [startNumber, setStartNumber] = useState();
  const debounceValue = useDebounce(search, 500);
  const navigate = useNavigate();


  console.log(id,"CAt 2 ---------->id")

  const fetchCategoryList = async () => {
    try {
      //   show();
      const response = await cat_1_ListController(
        categoryId ? categoryId : "",
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      console.log(response, "Response");
      if (response?.data?.code === 200) {
        hide();
        setCatList(response?.data?.data?.products);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };
  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchCategoryList();
  }, [debounceValue, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                {id}
              </h2>
              <div className="button_wrap"></div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              {/* <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by name or email address"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div> */}
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th> Product Name</th>
                          <th>Product Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {catList && catList.length > 0
                          ? catList.map(
                              ({ id, productName, productImage }, index) => {
                                return (
                                  <React.Fragment key={id}>
                                    <tr>
                                      <td>
                                        {(activePage - 1) * limit + ++index}
                                      </td>
                                      <td>{productName}</td>
                                      <td>
                                        <img src={`${productImage[0].productImage ? productImage[0].productImage : ''}`} />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + catList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Category Name Description Modal */}
        {/* <ModalBox
          status={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          title=""
          customizedFooter={<></>}
        >
          <div>
            <h5 className="text-center">{showDescription}</h5>
          </div>
        </ModalBox> */}
      </div>
    </React.Fragment>
  );
};

export default Cat2;
