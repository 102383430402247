import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminToken } from "../utility/utils";

// this code is created an instance with base urls!
export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// this function is used for set headers in api!
export const setJwtToken = () => {
  return {
    headers: {
      Authorization: "Bearer " + getAdminToken(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

// This funtion is used to set multipart token!
export const multipartJwtToken = () => {
  return {
    headers: {
      Authorization: "Bearer " + getAdminToken(),
      "Content-Type": "multipart/form-data",
    },
  };
};

// This function is for removing token from local storage!
export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();

  const removeToken = () => {
    localStorage.removeItem("token");
  };

  useEffect(() => {
    toast.clearWaitingQueue();
    const resInterceptor = (response) => {
      if (response?.data?.data?.access_token) {
        localStorage.setItem("token", response.data.data.access_token);
      }
      if (response?.data?.code === 401) {
        toast.error("Session Expired");
        removeToken();
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response?.data?.code === 401) {

        removeToken();
        navigate("/");
      }
      return error;
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
  }, []);
  return isSet && children;
};
