import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Product List Api
export const orderListController = async (
  activePage,
  limit,
  search,
  filterByDate,
  orderNo
) => {
  try {
    // console.log(orderNo,"orderNo")
    const params = {};
    params.search = search;
    params.page = activePage ? activePage : "";
    params.limit = limit ? limit : "";
    params.date = filterByDate ? filterByDate : "";
    params.orderNo = orderNo ? orderNo : "";
    const data = await instance.get(`admin/orders-list`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data?.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message, {
        id: "order",
      });

      return data?.data;
    }
  } catch (e) {
    console.log(e.message);
  }
};

// Fetching Order List Detail
export const viewOrderController = async (id) => {
  const data = await instance.get(`admin/order-detail?id=${id}`, setJwtToken());
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message, {
      id: "order",
    });
    return data?.data;
  }
};

// Fetching order change status
export const changeOrderStatusController = async (reqData) => {
  const data = await instance.post(
    `admin/update-order-status`,
    reqData,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message, {
      id: "order",
    });
    return data?.data;
  }
};

// Fetching cancel order status
export const cancelOrderController = async (reqData) => {
  const data = await instance.post(
    `admin/cancel-order`,
    reqData,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message, {
      id: "order",
    });
    return data?.data;
  }
};

// Fetching Refund Payment API
export const refundPaymentController = async (reqData) => {
  const data = await instance.post(
    `admin/refund-payment`,
    reqData,
    setJwtToken()
  );
  return data;
};
