import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ProductsCartListReviewController,
  ProductsCartUpdateController,
  shortBookListingController,
} from "../../services/digitalShortbookServices/shortbookController";
import { toast } from "react-toastify";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";

const ReviewCartList = () => {
  const [cartData, setCartData] = useState([]);
  const [addProductList, setAddProductList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [productId, setProductId] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [shortbookList, setShortbookList] = useState([]);

  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const navigate = useNavigate();
  const { reviewId, UserId } = useParams();

  const fetchingShortbookList = async () => {
    try {
      show();
      const response = await shortBookListingController();
      if (response?.data?.code === 200) {
        hide();
        const requiredData = response?.data?.data?.data.filter(
          (item) => item.id == reviewId
        );

        requiredData.forEach(({ cartJson }) => {
          if (cartJson) {
            const reviewList = JSON.parse(cartJson);
            const newReviewList = reviewList.map(
              ({ productName, qty, type }) => ({
                productName: productName || "---",
                qty: qty || "---",
                type: type || "---",
              })
            );
            setShortbookList(newReviewList);
          }
        });
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  useEffect(() => {
    fetchingShortbookList();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                Review Items
              </h3>
              <div className="button_wrap"></div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shortbookList && shortbookList.length > 0 ? (
                          shortbookList.map(({ productName, qty }, index) => {
                            return (
                              <tr key={++index}>
                                <td>{++index}</td>
                                <td>{productName}</td>
                                <td>{qty}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3}>No Cart Product Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Link
                    to="/digital-shortbook"
                    className="btn btn-primary cstm_btn_wrap_theme mt-4"
                  >
                    Confirm & Save
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReviewCartList;
