import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Filter Category List Api
export const filterCategoryListController = async (
  search,
  activePage,
  limit
) => {
  try {
    const params = {};
    params.search = search;
    params.page = activePage ? activePage : "";
    params.limit = limit ? limit : "";
    const  data  = await instance.get(`admin/product-cat-list`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching Filter category list.");
    return null;
  }
};

// Fetching Add Filter Category Name API
export const addFilterCategoryNameController = async (categoryName) => {
  try {
    const  data  = await instance.post(
      `admin/create-product-category`,
      { categoryName: categoryName },
      setJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occured in adding filter category name");
  }
};

// Fetching update Filter Category Name API
export const editFilterCategoryNameController = async (categoryName, id) => {
  try {
    const  data = await instance.post(
      `admin/update-category`,
      { categoryName: categoryName, id: id },
      setJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occured in updating filter category name");
  }
};

// Fetching Delete Filter Category Name API
export const deleteFilterCategoryNameController = async (id) => {
  try {
    const  data  = await instance.delete(
      `admin/delete-category/${id}`,
      setJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occured in Deleting filter category name");
  }
};

// Fetching API Of Category Detail
export const categoryDetailController = async (id) => {
  try {
    const params = {};
    params.id = id ? id : "";
    const  data  = await instance.get(`admin/category-details`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occured in Deleting filter category name");
  }
};

// Fetching Filter Category Status API
export const filterCategoryStatusController = async (id) => {
  try {
    const  data  = await instance.post(
      `admin/update-product-cat-status`,
      { id: id },
      setJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occured in Deleting filter category name");
  }
};

//Manage Home Config 
export const manageHomeConfigController = async (reqData) => {
    const  data  = await instance.post(
      `admin/home-category-manage`,
      reqData,
      setJwtToken()
    );
    return data;
    }
