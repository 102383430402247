import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Banner List Api
export const bannerListController = async (limit, activePage) => {
  let params = {};
  params.limit = limit;
  params.page = activePage;
  try {
    const data = await instance.get(`admin/banner-list`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching banner list.");
    return null;
  }
};

// Fetching Add Banner Api
export const addBannerController = async (reqData) => {
  try {
    const data = await instance.post(
      `admin/insert-banner`,
      reqData,
      multipartJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching banner list.");
    return null;
  }
};

// Fetching Update Banner Api
export const updateBannerController = async (reqData) => {
  try {
    const data = await instance.post(
      `admin/update-banner`,
      reqData,
      multipartJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching banner list.");
    return null;
  }
};

// Fetching Update Banner Status Api
export const updateBannerStatusController = async (id) => {
  try {
    const data = await instance.post(
      `admin/update-banner-status`,
      { id: id },
      setJwtToken()
    );
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching banner list.");
    return null;
  }
};

// Delete Banner
export const deleteBannerController = async (id) => {
  const data = await instance.delete(`admin/delete-banner`, {
    data: { id },
    ...setJwtToken(),
  });
  return data;
};
