import { instance } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Login Api
export const loginController = async (reqData) => {
  try {
    const data = await instance.post(`admin/login`, {
      email: reqData.email,
      password: reqData.password,
    });
    return data;
  } catch (e) {
    toast.error("An error occurred during login.");
    return null;
  }
};

// Forget Password API
export const forgotPasswordController = async (reqData) => {
  const data = await instance.post(`admin/forgot-password`, {
    email: reqData,
  });
  return data;
};

// Change Password API
export const changePasswordController = async (reqData) => {
  const data = await instance.post(`admin/change-password`, reqData);
  return data;
};
