import React, { useState } from "react";
import { Link } from "react-router-dom";
import { loginController } from "../../services/authServices/authController";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useButtonLoader from "../../hooks/useButtonLoader";
import { usePermission } from "../../context/create_Context";

const Login = () => {
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [inputType, setInputType] = useState("password");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "SIGN IN",
    "Please wait.."
  );
  const navigate = useNavigate();
  const { setPermission } = usePermission();

  //Form validation
  const formValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!inputField.email) {
      validationStatus = false;
      error["email"] = "Please enter email";
    }
    if (!inputField.password) {
      validationStatus = false;
      error["password"] = "Please enter Password";
    }
    setErrors(error);
    return validationStatus;
  };

  // Input Handler
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };
  const fetchLoginApi = async (e) => {
    e.preventDefault();
    const status = formValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const response = await loginController(inputField);
        // console.log(response, "----->Login response");
        if (response?.data?.code === 200) {
          localStorage.setItem("token",response?.data?.data?.access_token);
          if (response?.data?.data?.permission) {
            const permissionsArray = response?.data?.data?.permission;
            const groupedPermission = permissionsArray.reduce((acc, item) => {
              const existingItem = acc.find(
                (group) => group.moduleId === item.moduleId
              );

              if (existingItem) {
                existingItem.accessIds.push(item.accessId);
              } else {
                acc.push({
                  moduleId: item.moduleId,
                  accessIds: [item.accessId],
                  moduleDetails: item.moduleDetails || "",
                });
              }

              return acc;
            }, []);

            localStorage.setItem(
              "Permission",
              JSON.stringify(groupedPermission)
            );
            localStorage.setItem("role", response?.data?.data?.admin?.role);
            localStorage.setItem("name", response?.data?.data?.admin?.name);
            setPermission(groupedPermission);
          }

          navigate("/dashboard");
          toast.success(response?.data?.message);
          setButtonLoader(false);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setButtonLoader(false);
        }
      } catch (e) {
        toast.error(e.message);
        setButtonLoader(false);
      }
    }
  };

  // Toggle Input Type
  const inputTypeToggle = () => {
    setInputType(inputType === "text" ? "password" : "text");
  };
  return (
    <React.Fragment>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="LoginArea login_input">
              <form>
                <div className="image_wrap">
                  <img src="./assets/images/stoklo.jpeg" />
                </div>
                <div className=" ">
                  <div className="form_title">
                    <h2>Login</h2>
                    <p>
                      To keep connected with us please login with your personal
                      info.
                    </p>
                  </div>
                  <div className={`form_field ${errors.email && "isError"}`}>
                    <label>
                      Email <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={inputField.email || ""}
                      className={`input form-control ${
                        errors.email && "is-invalid"
                      }`}
                      id="exampleInputEmail1"
                      placeholder="Email Address"
                      onChange={inputHandler}
                    />

                    <span className="Icon login_icon ">
                      <i
                        className="mdi mdi-account-circle"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className="invalid-feedback">{errors.email}</span>
                  </div>
                  <div className={`form_field ${errors.password && "isError"}`}>
                    <label>
                      Password <span class="text-danger">*</span>
                    </label>
                    <input
                      type={inputType}
                      name="password"
                      value={inputField.password}
                      className={`input cstm_padding_input form-control ${
                        errors.password && "is-invalid"
                      }`}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={inputHandler}
                    />
                    <span className="toggle" onClick={inputTypeToggle}>
                      {inputType === "text" ? (
                        <i
                          className="mdi mdi-eye
 "
                        ></i>
                      ) : (
                        <i className="mdi mdi-eye-off"></i>
                      )}
                    </span>

                    <span className="Icon">
                      <i className="mdi mdi-lock" aria-hidden="true"></i>
                    </span>
                    <div className="invalid-feedback">{errors.password}</div>
                  </div>

                  <div className="login_btn_group">
                    <button
                      type="button"
                      className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn w-100"
                      onClick={(e) => fetchLoginApi(e)}
                      ref={buttonLoader}
                    >
                      Log In
                    </button>
                    <Link
                      to="/forgot-password"
                      className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn w-100 my-4"
                    >
                      FORGOT PASSWORD ?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
