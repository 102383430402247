import { CustomTab, CustomTabs } from "../../common/Tabs";
import React, { useEffect, useState } from "react";
import Terms from "./section/Terms";
import Privacy from "./section/Privacy";
import About from "./section/About";
import { toast } from "react-toastify";
// import { getCmsDetailApi, updateCmsApi } from "services/cms";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import {
  CmsDetailsController,
  CmsUpdateController,
} from "../../services/cmsServices/CMSController";
import useButtonLoader from "../../hooks/useButtonLoader";

const CMSManagement = () => {
  const [type, setType] = useState(1);
  const [cmsData, setCmsData] = useState({});
  const [errors, setErrors] = useState("");
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const fetchingCmsDetail = async () => {
    try {
      const response = await CmsDetailsController(type);
      if (response?.data?.code === 200) {
        setCmsData(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Validation For CMS Description...
  const validation = () => {
    let error = "";
    let validationStatus = true;

    if (type === 1 && cmsData.description === "<p><br></p>") {
      error = "Description is Mandatory *";
      validationStatus = false;
    }
    if (type === 2 && cmsData.description === "<p><br></p>") {
      error = "Description is Mandatory *";
      validationStatus = false;
    }
    if (type === 3 && cmsData.description === "<p><br></p>") {
      error = "Description is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  const updateCmsDetail = async () => {
    // console.log(errors,"Errors");
    // console.log(cmsData,"<--CMSDATA-->")
    if (validation()) {
      try {
        setSubmitButton(true);
        const data = {
          id: type || 0,
          description: cmsData.description || "",
          title: cmsData.title || "",
        };
        const response = await CmsUpdateController(data);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setSubmitButton(false);
          setCmsData(response?.data?.data);
          setErrors("");
          fetchingCmsDetail();
        } else {
          toast.error(response?.response?.data?.message || response?.data?.message);
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  useEffect(() => {
    fetchingCmsDetail();
    setErrors("");
  }, [type]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="TitleBox">
            {loader}

            <h4 className="Title">CMS Management</h4>
            <div className="form-group"></div>
          </div>
          <div className="col-sm-6 col-form-label mx-auto">
            <CustomTabs
              value={type}
              onChange={(e, section) => {
                setType(section);
              }}
              aria-label="Content"
              variant="fullWidth"
            >
              <CustomTab value={1} label={"Privacy Policy"} />
              <CustomTab value={2} label={"Terms And Conditions"} />

              <CustomTab value={3} label={"About Us"} />
            </CustomTabs>
          </div>
          <div className="row">
            {type === 2 && (
              <Terms
                cmsData={cmsData}
                setCmsData={setCmsData}
                updateCmsDetail={updateCmsDetail}
                errors={errors}
              />
            )}
            {type === 1 && (
              <Privacy
                cmsData={cmsData}
                setCmsData={setCmsData}
                updateCmsDetail={updateCmsDetail}
                errors={errors}
              />
            )}
            {type === 3 && (
              <About
                cmsData={cmsData}
                setCmsData={setCmsData}
                updateCmsDetail={updateCmsDetail}
                errors={errors}
              />
            )}
          </div>
          <button
            className="btn btn-gradient-primary mb-2"
            onClick={updateCmsDetail}
            ref={submitButton}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default CMSManagement;
