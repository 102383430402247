import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import { productListController } from "../../services/productServices/productListingController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { orderListController } from "../../services/orderServices/orderListController";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import { userListingController } from "../../services/notificationServices/notificationController";
import { addProductsCartController } from "../../services/digitalShortbookServices/shortbookController";
import useButtonLoader from "../../hooks/useButtonLoader";

const CreateCart = () => {
  const [addProductList, setAddProductList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const { UserId, id } = useParams();
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  // console.log(UserId, "UserId", id, "id--->");
  const fetchingProductList = async () => {
    try {
      show();
      // console.log(activePage);
      const response = await productListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : "",
        filterByDate ? dateFormatYear(filterByDate) : ""
      );
      // console.log(response?.data?.rows);
      if (response?.code === 200) {
        hide();
        setTotalList(response?.data?.count);
        setProductList(response?.data?.rows.filter((item) => item.status === 1 && item.noOfStock!==0));

      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Cart Product Handler
  const cartProductsHandler = async () => {
    try {
      const reqData = {
        data: addProductList,
        userId: UserId,
        digitalCartId: id,
      };
      setSubmitButton(true);
      const response = await addProductsCartController(reqData);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setSubmitButton(false);
        navigate(`/digital-shortbook/create-cart/review-items/${id}/${UserId}`);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  // Handle Cart Status
  const handleAddProductQuantity = (id,productName) => {
    let item = {
      productId: id,
      productName:productName,
      qty: 1,
      type:1
    };
    setAddProductList((prev) => [...prev, item]);
  };

  // Increment Product Quantity Handler
  const incrementProductQuantityHandler = (id) => {
    const index = addProductList.findIndex((item) => item?.productId === id);

    if (index !== -1) {
      const updatedList = [...addProductList];
      updatedList[index].qty++;

      setAddProductList(updatedList);
    }
  };

  // Decrement Product Quantity Handler
  const decrementProductQuantityHandler = (id) => {
    const index = addProductList.findIndex((item) => item?.productId === id);

    if (index !== -1) {
      const updatedList = [...addProductList];

      if (updatedList[index].qty === 1) {
        updatedList.splice(index, 1);
      } else {
        updatedList[index].qty--;
      }

      setAddProductList(updatedList);
    }
  };

  // console.log(addProductQuantity, "AddProductQuantity");
  // console.log(addProductList, "AddProductList");

  useEffect(() => {
    fetchingProductList();
  }, [search, filterByDate, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                Create Cart{" "}
              </h2>
              <div className="button_wrap">
                {addProductList && addProductList.length > 0 && (
                  <button
                    to="#"
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                    onClick={cartProductsHandler}
                    ref={submitButton}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i>
                    Add Items
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Product Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          {/* DropDown for approved Status */}

          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList && productList.length > 0 ? (
                          productList.map(({ productName, id }, index) => {
                            return (
                              <React.Fragment key={++index}>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{productName}</td>
                                  <td>
                                    {addProductList.find(
                                      (item) =>
                                        item?.productId === id && item?.qty
                                    ) ? (
                                      <div className="action_button_wrap p-2">
                                        <div className="switch_button">
                                          <i
                                            className="mdi mdi-plus-circle"
                                            onClick={() =>
                                              incrementProductQuantityHandler(
                                                id
                                              )
                                            }
                                          ></i>
                                          <p className="cstm_p_count">
                                            {addProductList.find(
                                              (item) => item?.productId === id
                                            )?.qty || 0}
                                          </p>

                                          <i
                                            className="mdi mdi-minus-circle"
                                            onClick={() => {
                                              decrementProductQuantityHandler(
                                                id
                                              );
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="btn btn-outline-dark cstm_btn_wrap_theme me-2"
                                        onClick={() =>
                                          handleAddProductQuantity(id,productName)
                                        }
                                      >
                                        Add
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3}>No Products Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + productList?.length - 1} of {productList.length}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCart;
