import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  homeConfigurationListController,
  homeDragDropController,
  homeStatusController,
} from "../../services/homeConfigurationServices/homeConfigurationController";
import { toast } from "react-toastify";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalBox from "../../common/ModalBox";
import QuickMenus from "./QuickMenus";

const HomeConfiguration = () => {
  const [homeConfigList, setHomeConfigList] = useState([]);
  const [homeId, setHomeId] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const navigate = useNavigate();

  // Fetching Home COnfiguration Api
  const fetchingHomeConfig = async () => {
    try {
      const response = await homeConfigurationListController();
      if (response?.data?.code === 200) {
        setHomeConfigList(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Drag and Drop Home Config List
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    try {
      const response = await homeDragDropController(
        Number(result.source.index),
        Number(result.destination.index)
      );
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchingHomeConfig();
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Change Home Status Handler
  const changeHomeStatusHandler = async () => {
    try {
      const response = await homeStatusController(homeId);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchingHomeConfig();
        setStatusModal(false);
      } else {
        toast.error(response?.response?.data?.message);
        setStatusModal(false);
      }
    } catch (e) {
      console.log(e.message);
      setStatusModal(false);
    }
  };

  const nameMapping = {
    5: "Quick Menus",
    4: "Banner",
    6: "Refer and earn",
    7: "Spin",
  };

  // const handleRoute = (id, categoryId, name) => {
  //   if (categoryId !== 0) {
  //     if ( name === "Banner") {
  //       navigate(`/banner-management`);
  //     } else {
  //       navigate(
  //         `/home-configuration/${
  //           id === 5
  //             ? `5/${name}/${categoryId}`
  //             : id === 4
  //             ? `4/${name}/${categoryId}`
  //             : id === 1
  //             ? `1/${name}/${categoryId}`
  //             : id === 3
  //             ? `3/${name}/${categoryId}`
  //             : id === 6
  //             ? `6/${name}/${categoryId}`
  //             : id === 7
  //             ? `7/${name}/${categoryId}`
  //             : id === 8
  //             ? `8/${name}/${categoryId}`
  //             : id === 9
  //             ? `9/${name}/${categoryId}`
  //             : ""
  //         }`
  //       );
  //     }
  //   }

  //   return null;
  // };

  const handleRoute = (id, categoryId, name) => {
    if (id === 4) {
      navigate("/banner-management");
      return null;
    }
    if (id === 8) {
      navigate("/scheme-management");
      return null;
    }
    if (id === 5) {
      navigate(`/home-configuration/${id}/${name}/${categoryId}`);
      return null;
    }
    if (id === 6) {
      navigate(`/points-management`);
      return null;
    }
    if (id === 7) {
      navigate(`/game-management`);
      return null;
    }
    
    let route = "";
    switch (id) {
      case 1:
      case 3:
      case 9:
        route = `/${id}/${name}/${categoryId}`;
        break;
      default:
        break;
    }
    
    if (route !== "") {
      navigate(`/home-configuration${route}`);
    }
    
    return null;
  };
  
  

  useEffect(() => {
    fetchingHomeConfig();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">Home Configuration</h2>
              <div className="button_wrap"></div>
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      {/* <tbody> */}
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <tbody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {homeConfigList && homeConfigList.length > 0
                                ? homeConfigList.map(
                                    (
                                      { name, order, id, status, categoryId },
                                      index
                                    ) => (
                                      <Draggable
                                        key={order}
                                        draggableId={order}
                                        index={order}
                                      >
                                        {(provided, snapshot) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <td>{order}</td>

                                            <td>
                                              {name !== null && name !== ""
                                                ? name
                                                : nameMapping[id] || ""}
                                            </td>

                                            <td>
                                              <div className="action_button_wrap">
                                                <button
                                                  className="cstm_toogle_wrap"
                                                  onClick={() => {
                                                    handleRoute(
                                                      id,
                                                      categoryId,
                                                      name
                                                        ? name
                                                        : nameMapping[id]
                                                    );
                                                  }}
                                                >
                                                  <i className="mdi mdi-settings"></i>
                                                </button>
                                                <Link
                                                  to="#"
                                                  className="action_button"
                                                >
                                                  <i className="mdi mdi-arrow-expand-all cstm_expand"></i>
                                                </Link>
                                                <div className="switch_button">
                                                  <FormControlLabel
                                                    control={
                                                      <ToggleStatus
                                                        sx={{ m: 1 }}
                                                        checked={status === 1}
                                                        onClick={() => {
                                                          setStatusModal(true);
                                                          setHomeId(id);
                                                        }}
                                                      />
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    )
                                  )
                                : null}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {/* </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title="Change Status"
          description={"Are you sure you want to change status?"}
          action={changeHomeStatusHandler}
        />
      </div>
    </React.Fragment>
  );
};

export default HomeConfiguration;
