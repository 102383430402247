import React, { useMemo, useRef } from "react";
// import TextEditor from "components/TextEditor/TextEditor";
import TextEditor from "../../../common/TextEditor";
import JoditEditor from "jodit-react";

const Terms = ({ cmsData, setCmsData, updateCmsDetail, errors }) => {
  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const editor = useRef(null);

  // console.log("term value---------------------------------->", cmsData);
  return (
    <div className="my-3">
      <h4 className="text-center">Terms & Conditions</h4>
      <div className="col-sm-12 col-form-label">
        <JoditEditor
          ref={editor}
          value={cmsData?.description}
          tabIndex={1}
          onBlur={(newContent) => {
            setCmsData({
              ...cmsData,
              description: newContent,
            });
          }}
          config={config}
        />
        <span style={{ color: "red" }} className="error">
          {errors}
        </span>
      </div>
    </div>
  );
};

export default Terms;
