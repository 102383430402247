import React, { useState, useEffect, useLayoutEffect } from "react";
import { renderToString } from "react-dom/server";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import {
  changeProductStatusController,
  productListController,
} from "../../services/productServices/productListingController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ToggleStatus from "../../common/ToggleStatus";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import TypingPlaceholder from "../../common/TypingPlaceholder";
import { changeStatusSubAdminController, subAdminListController } from "../../services/subAdminServices/subAdminServices";

const SubAdminManagement = () => {
  const [addModal, setAddModal] = useState(false);
  const [subAdminList, setSubAdminList] = useState([]);
  const [subAdminId, setSubAdminId] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);

  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();

  const fetchingSubAdminList = async () => {
    try {
      show();
      // console.log(activePage);
      const response = await subAdminListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      // console.log(response?.data?.data?.data, "Response---------->");
      if (response?.data?.code === 200) {
        hide();
        setTotalList(response?.data?.data?.count);
        setSubAdminList(response?.data?.data?.data);
      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Sub-Admin Status Handler
  const handleSubAdminStatus=async()=>{
    try {
      setSubmitButton(true);
      const response = await changeStatusSubAdminController(subAdminId);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setStatusModal(false);
        setSubmitButton(false);
        fetchingSubAdminList();
      } else {
        toast.error(response?.response?.data?.message);
        setStatusModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  // Clear filter
  const clearFilter = () => {
    setActivePage(1);
    setLimit(10);
    setSearch("");
    setFilterByDate("");
    fetchingSubAdminList();
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingSubAdminList();
  }, [activePage, limit, debounceValue, filterByDate]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">Sub-Admin Management</h2>
              <div className="button_wrap">
                <Link
                  to="/sub-admin-management/add-member"
                  className="btn btn-outline-info cstm_btn_wrap_theme"
                  onClick={() => setAddModal(true)}
                >
                  <i className="mdi mdi-plus menu-icon menu-icon"></i>New Member
                </Link>
              </div>
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-lg-3 cstm_padding_set"></div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Email Id</th>
                          <th>Mobile Number</th>
                          <th>Role</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subAdminList && subAdminList.length > 0 ? (
                          subAdminList.map(
                            (
                              { id, email, name, mobile, role, status },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>{name || "---"}</td>
                                    <td>{email || "---"}</td>
                                    <td>{mobile || "---"}</td>
                                    <td>{role || "---"}</td>
                                    <td>
                                      <div className="action_button_wrap">
                                        <div className="switch_button">
                                          <FormControlLabel
                                            control={
                                              <ToggleStatus
                                                onClick={() => {
                                                  setStatusModal(true);
                                                  setSubAdminId(id);
                                                }}
                                                sx={{ m: 1 }}
                                                checked={status == 1}
                                              />
                                            }
                                          />
                                        </div>
                                        <Link
                                          className="action_button"
                                          to={`/sub-admin-management/view/${id}`}
                                        >
                                          <VisibilityIcon
                                            className="cstm_eye"
                                            color="secondary"
                                          />
                                        </Link>
                                        <Link
                                          className="action_button"
                                          to={`/sub-admin-management/edit/${id}`}
                                        >
                                          <i className="mdi mdi-table-edit"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={6}></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
          status={statusModal}
          onClose={() => {
            setStatusModal(false);
          }}
          title="Change Status"
          description="Are you sure you want to change the status of this subAdmin?"
          action={handleSubAdminStatus}
          uploadButtonElement={setSubmitButton}
        />
      </div>
    </React.Fragment>
  );
};

export default SubAdminManagement;
