import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addSubAdminController,
  editSubAdminController,
  subAdminAccessListController,
  subAdminModuleListController,
  viewSubAdminController,
} from "../../services/subAdminServices/subAdminServices";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";
import useButtonLoader from "../../hooks/useButtonLoader";
import { isValidEmail, isValidPhoneNumber } from "../../utility/formValidation";

const EditMember = () => {
  const navigate = useNavigate();
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [moduleName, setModuleName] = useState([]);
  const [permissionArray, setPermissionArray] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [errors, setErrors] = useState({});
  const { id } = useParams();

  const fetchModuleList = async () => {
    try {
      const response = await subAdminModuleListController();
      if (response?.data?.code === 200) {
        setModuleName((prevModuleName) => [
          ...prevModuleName,
          ...(response?.data?.data || []).map((item) => ({
            ...item,
            access: item.access.split(",").map(Number), // Convert to numbers
          })),
        ]);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchSubAdminDetails = async () => {
    try {
      const response = await viewSubAdminController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        setPermissionArray(
          (response?.data?.data?.subadminDetail || []).reduce(
            (acc, { accessId, moduleId }) => {
              const existingModule = acc.find(
                (item) => item.moduleKey === moduleId
              );

              if (existingModule) {
                existingModule.accessKey.push(Number(accessId));
              } else {
                acc.push({
                  moduleKey: moduleId,
                  accessKey: [Number(accessId)],
                });
              }

              return acc;
            },
            []
          )
        );
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    if (type === "number") {
      let updatedValue = value.replace(/[^0-9]/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: Number(updatedValue) });
    } else if (type === "text") {
      let updatedValue = value.replace(/^\s+|\d+/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  const checkBoxHandler = (e, accessId, moduleId, index) => {
    const selectedObjectIndex = permissionArray.findIndex(
      (module) => module.moduleKey === moduleId
    );
    const newPermissionArray = [...permissionArray];

    if (selectedObjectIndex === -1) {
      newPermissionArray.push({
        moduleKey: moduleId,
        accessKey: [accessId],
      });
    } else {
      const accessKeyArray = newPermissionArray[selectedObjectIndex].accessKey;
      const accessIndex = accessKeyArray.indexOf(accessId);

      if (accessIndex !== -1) {
        accessKeyArray.splice(accessIndex, 1);
      } else {
        accessKeyArray.push(accessId);
      }

      // If the accessKey array becomes empty, remove the corresponding object.
      if (accessKeyArray.length === 0) {
        newPermissionArray.splice(selectedObjectIndex, 1);
      }
    }

    setPermissionArray(newPermissionArray);
  };

  //   Validate Input Field

  const validation = () => {
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.name) {
      validationStatus = false;
      error["name"] = "Name is Mandatory *";
    }
    if (!inputFieldValue.email) {
      validationStatus = false;
      error["email"] = "Email is Mandatory *";
    } else if (!isValidEmail(inputFieldValue.email)) {
      validationStatus = false;
      error["email"] = "Enter Valid Email *";
    }
    if (!inputFieldValue.mobile) {
      validationStatus = false;
      error["mobile"] = "Mobile Number is Mandatory *";
    } else if (!isValidPhoneNumber(inputFieldValue.mobile)) {
      validationStatus = false;
      error["mobile"] = "Enter Valid Mobile Number *";
    }
    setErrors(error);
    return validationStatus;
  };

  const editSubAdmin = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      try {
        setSubmitButton(true);
        const { mobile, ...reqData } = inputFieldValue; //Removing Mobile key from object inputFieldValue
        const response = await editSubAdminController({
          ...reqData,
          permissions: permissionArray,
          mobileNo: inputFieldValue.mobile,
          role: "SubAdmin",
          id: id,
        });
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          navigate(-1);
          setSubmitButton(false);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  //   console.log(moduleName, "ModuleName----->");
  //   console.log(permissionArray, "Permission Array ----------->");

  useEffect(() => {
    fetchModuleList();
    fetchSubAdminDetails();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <h2 className="page-title">
            <span
              className="page-title-icon bg-gradient-primary text-white me-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="mdi mdi-chevron-double-left"></i>
            </span>
            Edit Member
          </h2>
          <nav aria-label="breadcrumb"></nav>
        </div>
        <div className="wrap_Add_retailer">
          <div class="col-md- grid-margin stretch-card">
            <div class="card ">
              <div class="card-body">
                <form className="form-sample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={60}
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            value={inputFieldValue.name}
                            onChange={(e) => inputFieldHandler(e, "text")}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            maxLength={60}
                            placeholder="Enter Email"
                            className="form-control"
                            name="email"
                            value={inputFieldValue.email}
                            onChange={(e) => {
                              inputFieldHandler(e, "email");
                            }}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            type="text"
                            className="form-control"
                            maxLength={10}
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            value={
                              inputFieldValue.mobile
                                ? inputFieldValue.mobile
                                : ""
                            }
                            onChange={(e) => {
                              inputFieldHandler(e, "number");
                            }}
                          />
                          <span style={{ color: "red" }} className="error">
                            {errors.mobile}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Password
                        </label>
                        <div className="col-sm-9 text-start">
                          <input
                            className="form-control"
                            name="password"
                            placeholder="Enter Password"
                            value={inputFieldValue.password}
                            onChange={inputFieldHandler}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      {moduleName.map((item, index) => (
                        <React.Fragment key={++index}>
                          <div className="col-6 mb-4 text-start">
                            <h5>{item?.name}</h5>
                            {item.access.map((accessId) => {
                              const labelValue =
                                accessId === 1
                                  ? "add"
                                  : accessId === 2
                                  ? "edit"
                                  : accessId === 3
                                  ? "view"
                                  : accessId === 4
                                  ? "delete"
                                  : accessId === 5
                                  ? "status": accessId===6? "request"
                                  : null;

                              return (
                                <FormControlLabel
                                  key={accessId}
                                  label={labelValue}
                                  checked={permissionArray.some(
                                    (module) =>
                                      module &&
                                      module.moduleKey === item.id &&
                                      module.accessKey &&
                                      module.accessKey.includes(
                                        Number(accessId)
                                      )
                                  )}
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        checkBoxHandler(
                                          e,
                                          accessId,
                                          item.id,
                                          index
                                        );
                                      }}
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      class="btn btn-gradient-primary mx-3 mt-3"
                      onClick={editSubAdmin}
                      ref={submitButton}
                    >
                      Submit
                    </button>
                    <button class="btn btn-secondary mt-3">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMember;
