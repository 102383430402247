import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import { filterCategoryListController } from "../../services/filterCategoryServices/filterCategoryController";
import {
  manageCategoryProductsController,
  productCategoryListController,
} from "../../services/productCategoryServices/productCategoryController";
import { usePermission } from "../../context/create_Context";
import { permissionChecker } from "../../config/roleAccess";

const ProductCategoryManagement = () => {
  const [productList, setProductList] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const categoryIdRef = useRef();
  const { permission } = usePermission();

  // Fetching Filter Category List API
  const filterCategoryListing = async () => {
    try {
      show();
      const response = await filterCategoryListController(
        search,
        activePage,
        showModal === true ? 1000 : limit
      );
      if (response?.code === 200) {
        setCategoryList(response?.data?.rows);
        setTotalList(response?.data?.count);
        hide();
      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Fetching API product based on Selected Category
  const inputFieldHandler = async (e) => {
    setCategoryId(Number(e.target.value));
    if (Number(e.target.value) === 0) {
      setProductList([]);
      setCheckedProduct([]);
      categoryIdRef.current = "";
    }
    categoryIdRef.current = Number(e.target.value);

    if (categoryIdRef.current) {
      try {
        const response = await productCategoryListController(
          categoryIdRef.current
        );

        if (response?.data?.code === 200) {
          setProductList(response?.data?.data);
          setCheckedProduct(
            response?.data?.data.map(({ id, productName, isSelected }) => {
              return {
                id: id,
                productName: productName,
                isSelected: isSelected,
              };
            })
          );
        } else {
          toast.error(response?.response?.data?.message || response?.data?.message);
        }
      } catch (e) {
        console.log(e.message);
        hide();
      }
    }
  };

  // Manage Products Handler
  const manageCategoryProductsHandler = async () => {
    try {
      setSubmitButton(true);
      const data = checkedProduct
        .filter((item) => item.isSelected)
        .map(({ id }) => id);

      const reqData = {
        categoryId: categoryIdRef.current,
        productId: data,
      };
      const response = await manageCategoryProductsController(reqData);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        categoryIdRef.current = "";
        setCategoryId("");
        setShowModal(false);
        setCheckedProduct([]);
        setSubmitButton(false);
        filterCategoryListing();
        setProductList([]);
      } else {
        toast.error(response?.data?.message);
        categoryIdRef.current = "";
        setCategoryId("");
        setShowModal(false);
        setCheckedProduct([]);
        setSubmitButton(false);
        setProductList([])
      }
    } catch (e) {
      console.log(e.message);
      categoryIdRef.current = "";
      setCategoryId("");
      setSubmitButton(false);
    }
  };

  // All Product Select Handler
  const allProductSelectHandler = () => {
    if (productList.length > 0) {
      const areAllSelected = checkedProduct.every((item) => item.isSelected);

      const updatedProducts = checkedProduct.map((item) => ({
        ...item,
        isSelected: !areAllSelected,
      }));

      setCheckedProduct(updatedProducts);
    }
  };

  // Single Product Select Handler
  const singleProductSelectHandler = (productId, selected) => {
    const updatedProducts = checkedProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }
      return item;
    });
    setCheckedProduct(updatedProducts);
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    filterCategoryListing();
  }, [activePage, limit, search, showModal]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Product Category Management </h3>
              {permissionChecker(permission, 9, 1) && (
                <div className="button_wrap">
                  <Link
                    to="#"
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i>Manage
                    Products
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set"></div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Category Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList && categoryList.length > 0 ? (
                          categoryList.map(({ categoryName, id }, index) => {
                            return (
                              <React.Fragment key={++index}>
                                <tr>
                                  <td>{(activePage - 1) * limit + ++index}</td>
                                  <td>{categoryName}</td>
                                </tr>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={2}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + categoryList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Show Modal For Managing Products */}
          <ModalBox
            status={showModal}
            onClose={() => {
              setShowModal(false);
              setCategoryId("");
              setCheckedProduct([]);
              categoryIdRef.current = "";
              setProductList([]);
            }}
            title={"Manage Products"}
            customizedFooter={<></>}
            modalSize={"cstm_modal_size"}
            centered={true}
            size={"md"}
          >
            <div className="form-group row">
              <label className="col-3 mt-2 mx-3">
                <strong>Category <span className="text-danger">*</span></strong>
              </label>
              <div className="col-5">
                <select
                  className="form-control"
                  onChange={inputFieldHandler}
                  name="categoryId"
                  value={categoryId}
                >
                  <option value={0 || ""}>Select Category</option>
                  {categoryList.map(({ id, categoryName }) => {
                    return (
                      <option key={id} value={id}>
                        {categoryName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={productList && productList.length>0?"cstm_height_Set":""}>
            <table className="table table-hover">
              {productList && productList.length > 0 && (
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>
                      <FormControlLabel
                        label={"Select All"}
                        control={
                          <Checkbox
                            checked={
                              checkedProduct &&
                              checkedProduct.length > 0 &&
                              checkedProduct.every((item) => item.isSelected)
                            }
                            onChange={allProductSelectHandler}
                          />
                        }
                      />
                    </th>
                  </tr>
                </thead>
              )}

              <tbody>
                {productList && productList.length > 0 ? (
                  productList.map(({ productName, id, isSelected }) => {
                    return (
                      <React.Fragment key={id}>
                        <tr>
                          <td>{productName}</td>
                          <td>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={productName}
                                  checked={
                                    checkedProduct.find(
                                      (item) => item.id === id
                                    )?.isSelected
                                  }
                                  onChange={() =>
                                    singleProductSelectHandler(id, isSelected)
                                  }
                                />
                              }
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center">Please Select Category <span className="text-danger">*</span></td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            {productList && productList.length > 0 && (
              <div className="text-center">
                <button
                  className="btn btn-gradient-primary mt-5"
                  onClick={manageCategoryProductsHandler}
                  ref={submitButton}
                >
                  Save
                </button>
              </div>
            )}
          </ModalBox>
          {/*  */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductCategoryManagement;
