import { useRef, useState, useEffect } from "react";

const useButtonLoader = () => {
  const [isLoading, setLoading] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      // console.log(element.innerText, "element");

      if (isLoading) {
        element.disabled = true;
        element.innerHTML = `<span animation="border" role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">${element.innerText}</span></span>`;
      } else {
        element.disabled = false;
        element.innerHTML = element.innerText;
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return [elementRef, setLoading];
};

export default useButtonLoader;

