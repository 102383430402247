import React, { useEffect, useState } from "react";
import sidenav from "../routes/sidenav";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePermission } from "../context/create_Context";


function LeftSideBar() {
  const navigate = useNavigate();
  const {permission}=usePermission();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      // toast.error("Login Again");
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav cstm_nav">
          <li className="nav-item nav-profile"></li>
          {sidenav(permission).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <li className="nav-item">
                  <NavLink to={item.path} className="nav-link">
                    <span className="menu-title">{item.name}</span>
                    <i className={item.icon + " menu-icon"}></i>
                  </NavLink>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default LeftSideBar;
