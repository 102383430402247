import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";

// Fetching SubAdmin List Api
export const subAdminListController = async (page, limit, search) => {
  const params = {};
  params.page = page || "";
  params.limit = limit || "";
  params.search = search || "";
  const data = await instance.get(`admin/subadmin-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Fetching Module List Api
export const subAdminModuleListController = async () => {
  const data = await instance.get(`admin/module-list`, setJwtToken());
  return data;
};

// Fetching Access List Api
export const subAdminAccessListController = async () => {
  const data = await instance.get(`admin/access-list`, setJwtToken());
  return data;
};

// Add Sub-Admin
export const addSubAdminController = async (reqData) => {
  const data = await instance.post(
    `admin/create-subadmin`,
    reqData,
    setJwtToken()
  );
  return data;
};

// View Sub-Admin
export const viewSubAdminController = async (id) => {
  const data = await instance.get(
    `admin/subadmin-detail?id=${id}`,
    setJwtToken()
  );
  return data;
};

// Edit Sub-Admin
export const editSubAdminController = async (reqData) => {
  const data = await instance.post(
    `admin/update-subadmin`,
    reqData,
    setJwtToken()
  );
  return data;
};

// Subadmin Status Change API
export  const changeStatusSubAdminController = async (subadminId) => {
  const data = await instance.post(
    `admin/update-sub-admin-status`,
    { id: subadminId },
    setJwtToken()
  );
  return data;
};
