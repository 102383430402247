import React, { useEffect, useState } from "react";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  categoryDetailController,
  filterCategoryStatusController,
} from "../../services/filterCategoryServices/filterCategoryController";
import Pagination from "react-js-pagination";
import ModalBox from "../../common/ModalBox";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import {
  ViewTransactionController,
  addAmountController,
  subAmountController,
} from "../../services/walletServices/walletController";
import { dateFormatYear, showDateFormatYear } from "../../utility/utils";
import DatePicker from "react-datepicker";

function ViewTransaction() {
  const [transactionDetails, setTransactionDetails] = useState({});
  const [errors, setErrors] = useState("");
  const [walletDetails, setWalletDetails] = useState([]);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [statusModal, setStatusModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const { id } = useParams();
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [addAmountModal, setAddAmountModal] = useState();
  const [subAmountModal, setSubAmountModal] = useState();
  const [addAmount, setAddAmount] = useState({});
  const [subAmount, setSubAmount] = useState({});
  const [expiryDate, setExpiryDate] = useState();
  const [showReason,setShowReason]=useState("");
  const [reasonModal,setReasonModal]=useState(false)
  const navigate = useNavigate();

  const transactionDetail = async () => {
    try {
      const response = await ViewTransactionController(id);
      // console.log(response);
      if (response?.data?.code === 200) {
        setTransactionDetails(response?.data?.data);
        setWalletDetails(response?.data?.data?.pointTranDetails);
        // console.log(response?.data?.data, "-------------->");
        setTotalList(response?.data?.count);
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //fetching Filter Category Status API
  const filterCategoryStatusHandler = async () => {
    try {
      setButtonLoader(true);
      const response = await filterCategoryStatusController(id);
      if (response?.code === 200) {
        toast.success(response?.message);
        setButtonLoader(false);
        setStatusModal(false);
      } else {
        toast.error(response.response.data.message);
        toast.error(response?.message);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
    }
  };

  const addValidation = () => {
    let validationStatus = true;
    const error = {};
    if (!addAmount.amount) {
      error["amount"] = "Amount Field is Mandatory *";
      validationStatus = false;
    }
    if (!addAmount.reason) {
      error["reason"] = "Reason is Mandatory *";
      validationStatus = false;
    }
    if (!expiryDate) {
      error["expiryDate"] = "Expiry Date is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  const subValidation = () => {
    let validationStatus = true;
    const error={}
    if (!subAmount.amount) {
      error["amount"]=("Amount Field is Mandatory *");
      validationStatus = false;
    }    if (!subAmount.reason) {
      error["reason"]=("Reason is Mandatory *");
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  //   Add Money Handler
  const addMoneyHandler = async (e) => {
    e.preventDefault();
    const status = addValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const data = {
          ...addAmount,
          expiryDate: dateFormatYear(expiryDate),
          id: id,
        };
        const response = await addAmountController(data);
        if (response?.data?.code === 200) {
          toast.success(`Rs ${addAmount.amount}  ${response?.data?.message}`);
          setButtonLoader(false);
          setAddAmountModal(false);
          setAddAmount({});
          setErrors("");
          transactionDetail();
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setButtonLoader(false);
          setAddAmountModal(false);
          transactionDetail();
          setAddAmount({});
          setErrors("");
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
        setAddAmount("");
        setErrors("");
      }
    }
  };

  //   Add Money Handler
  const subMoneyHandler = async (e) => {
    e.preventDefault();
    const status = subValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const data = { amount: subAmount.amount, reason:subAmount.reason, id: id };
        if (Number(subAmount.amount) <= transactionDetails.walletAmount) {
          const response = await subAmountController(data);
          if (response?.data?.code === 200) {
            // toast.success(response?.data?.message);
            toast.success(`Rs ${subAmount.amount}  ${response?.data?.message}`);
            setButtonLoader(false);
            setSubAmountModal(false);
            setSubAmount("");
            setErrors("");
            transactionDetail();
          } else {
            toast.error(
              response?.response?.data?.message || response?.data?.message
            );
            setButtonLoader(false);
            setSubAmountModal(false);
            transactionDetail();
            setSubAmount("");
            setErrors("");
          }
        } else {
          toast.error("Your wallet balance is lower than debited amount");
          setButtonLoader(false);
          setSubAmountModal(false);
          transactionDetail();
          setSubAmount("");
          setErrors("");
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
        setSubAmount("");
        setErrors("")
      }
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  // Date Handler Function

  const dateHandler = (date) => {
    setExpiryDate(date);
  };

  useEffect(() => {
    transactionDetail();
  }, []);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Wallet Transaction List
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row d-flex cstm_wrap_wallet">
                <div className="col-4 d-flex">
                  <div>
                    <h5>User Name: </h5>
                  </div>
                  <div className="ml-4 ms-1">
                    <h5> {transactionDetails.retailerName}</h5>
                  </div>
                </div>
                <div className="col-3 d-flex">
                  <div>
                    <h5>Wallet Balance:</h5>
                  </div>
                  <div className="ms-1"> {transactionDetails.walletAmount}</div>
                </div>
                <div className="col-5 d-flex text_end">
                  <div className="me-2">
                    <button
                      className="btn btn-outline-primary btn-fw"
                      onClick={() => setAddAmountModal(true)}
                    >
                      Add Amount
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-outline-dark btn-fw"
                      onClick={() => setSubAmountModal(true)}
                    >
                      Subtract Amount
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-header mt-4"></div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Reason</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {walletDetails && walletDetails.length > 0 ? (
                        walletDetails.map(
                          (
                            {
                              id,
                              amount,
                              createdAt,
                              status,
                              updatedAt,
                              transactionSource,
                              transactionType,
                              userId,
                              reason,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{showDateFormatYear(createdAt)}</td>
                                  {/* <td>{reason || "---"}</td> */}
                                  <td className="Cstm_see_description">
                                        {reason && reason.length > 30 ? (
                                          <>
                                            {reason.slice(0, 30)}{" "}
                                            <Link
                                              onClick={() => {
                                                setShowReason(reason);
                                                setReasonModal(true);
                                              }}
                                              to="#"
                                            >
                                              see more
                                            </Link>
                                          </>
                                        ) : (
                                          reason || "---"
                                        )}
                                      </td>
                                  <td>
                                    {transactionType === 1
                                      ? "Credited"
                                      : "Debited"}
                                  </td>
                                  <td>{transactionSource}</td>
                                  <td>{amount}</td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={6}>No Transaction History</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Modal for Status Change */}
            <ModalBox
              status={statusModal}
              onClose={() => setStatusModal(false)}
              title={"Change Status"}
              description="Are you sure you want to change status of this retailer ?"
              action={filterCategoryStatusHandler}
              uploadButtonElement={buttonLoader}
            />

            {/* Modal for Subtracting amount  in wallet*/}
            <ModalBox
              status={subAmountModal}
              onClose={() => {
                setSubAmountModal(false);
                setErrors({});
                setSubAmount({});
              }}
              title="Debit Amount"
              customizedFooter={<></>}
            >
              <React.Fragment>
                <div class="card">
                  <div class="card-body">
                    <form class="forms-sample">
                      <div class="form-group">
                        <label htmlFor="Banner ">Enter Amount Here <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          class="form-control"
                          maxLength={5}
                          id="exampleInputName1"
                          value={subAmount.amount}
                          name="amount"
                          placeholder="Enter Amount"
                          onChange={(e) =>
                            setSubAmount({...subAmount,["amount"]:e.target.value.replace(/[^0-9.]/g, "")})
                          }
                        />
                        <span style={{ color: "red" }} className="error">
                          {errors.amount}
                        </span>
                        <div class="form-group">
                        <label htmlFor="reason" className="mt-2">
                          Reason <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          maxLength={100}
                          id="exampleInputName1"
                          value={subAmount.reason}
                          name="reason"
                          placeholder="Reason"
                          onChange={(e) =>
                            setSubAmount({
                              ...subAmount,
                              ["reason"]: e.target.value,
                            })
                          }
                        />
                          <span style={{ color: "red" }} className="error">
                          {errors.reason}
                        </span>
                        </div>

                        {/* <label htmlFor="expiryDate" className="mt-2">
                          Expiry Date
                        </label> */}
                        {/* <div className="react-datepicker-wrapper_retailer">
                          <DatePicker
                            placeholderText="Expiry Date"
                            className="form-control"
                            showYearDropdown
                            selected={expiryDate}
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            minDate={Date.now()}
                            dateFormat="dd/MM/yyyy"
                            onChangeRaw={(e) => e.preventDefault()}
                            onChange={(date) => dateHandler(date)}
                          />
                        </div> */}
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <button
                            type="submit"
                            class="btn btn-gradient-primary me-2"
                            onClick={(e) => {
                              subMoneyHandler(e);
                            }}
                            ref={buttonLoader}
                          >
                            Submit
                          </button>
                          <button
                            class="btn btn-light"
                            onClick={(e) => {
                              setSubAmountModal(false);
                              e.preventDefault();
                              setErrors("");
                              setSubAmount("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            </ModalBox>

            {/* Modal for Adding amount  in wallet  */}
            <ModalBox
              status={addAmountModal}
              onClose={() => {
                setAddAmountModal(false);
                setErrors({});
                setAddAmount({});
              }}
              title="Credit Amount"
              customizedFooter={<></>}
            >
              <React.Fragment>
                <div class="card">
                  <div class="card-body">
                    <form class="forms-sample">
                      <div class="form-group">
                        <label htmlFor="Banner ">Enter Amount Here <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputName1"
                          maxLength={5}
                          value={addAmount.amount}
                          name="amount"
                          placeholder="Enter Amount"
                          onChange={(e) =>
                            setAddAmount({
                              ...addAmount,
                              ["amount"]: e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              ),
                            })
                          }
                        />
                        <span style={{ color: "red" }} className="error">
                          {errors.amount}
                        </span>
                      </div>
                      <div class="form-group">
                        <label htmlFor="reason" className="mt-2">
                          Reason <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          class="form-control"
                          maxLength={100}
                          id="exampleInputName1"
                          value={addAmount.reason}
                          name="reason"
                          placeholder="Reason"
                          onChange={(e) =>
                            setAddAmount({
                              ...addAmount,
                              ["reason"]: e.target.value,
                            })
                          }
                        />
                        <span style={{ color: "red" }} className="error">
                          {errors.reason}
                        </span>
                      </div>
                      <div class="form-group">
                        <label htmlFor="expiryDate" className="mt-2">
                          Expiry Date <span className="text-danger">*</span>
                        </label>
                        <div className="react-datepicker-wrapper_retailer">
                          <DatePicker
                            placeholderText="Expiry Date"
                            className="form-control"
                            showYearDropdown
                            selected={expiryDate}
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            minDate={Date.now()}
                            dateFormat="dd/MM/yyyy"
                            onChangeRaw={(e) => e.preventDefault()}
                            onChange={(date) => dateHandler(date)}
                          />
                          <p style={{ color: "red" }} className="error">
                            {errors.expiryDate}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <button
                            type="submit"
                            class="btn btn-gradient-primary me-2"
                            onClick={(e) => {
                              addMoneyHandler(e);
                            }}
                            ref={buttonLoader}
                          >
                            Submit
                          </button>
                          <button
                            class="btn btn-light"
                            onClick={(e) => {
                              setAddAmountModal(false);
                              e.preventDefault();
                              setErrors({});
                              setAddAmount({});
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            </ModalBox>

            {/* Modal for showing reason */}
            <ModalBox
          status={reasonModal}
          onClose={() => setReasonModal(false)}
          title=""
          customizedFooter={<></>}
        >
          <div>
            <h5 className="text-center">{showReason}</h5>
          </div>
        </ModalBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewTransaction;
