import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const CustomTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#A3793C",
  },
});

export const CustomTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightSemiBold,
  fontSize: theme.typography.pxToRem(15),
  backgroundColor: "#F5ED9C",
  // marginRight: theme.spacing(1),
  color: "#242424",
  "&.Mui-selected": {
    color: "#242424",

    backgroundColor: "#F5ED9C",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#F5ED9C",
  },
}));