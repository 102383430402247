import React, { useRef } from "react";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import { addGiftController } from "../../services/giftServices.jsx/giftController";
import { toast } from "react-toastify";

const AddGiftProduct = ({
  errors,
  setErrors,
  addModal,
  setAddModal,
  setImageFile,
  imageFile,
  inputFieldValue,
  setInputFieldValue,
  fetchGiftManagementList,
}) => {
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const inputRef = useRef();

  // Input Field Validation
  const validation = () => {
    const validImageFormat = ["image/jpeg", "image/png", "image/jpg"];
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.giftName) {
      error["giftName"] = "Enter Gift Name";
      validationStatus = false;
    }
    // if (!inputFieldValue.giftUnit) {
    //   error["giftUnit"] = "Enter Inventory";
    //   validationStatus = false;
    // }
    if (!inputFieldValue.giftPrice) {
      error["giftPrice"] = "Enter Price";
      validationStatus = false;
    }
    if (!imageFile) {
      error["giftImage"] = "Please Upload Gift Image";
      validationStatus = false;
    } else if (
      !imageFile.length > 0 &&
      !validImageFormat.includes(imageFile?.type)
    ) {
      error["giftImage"] =
        "Please Upload valid image format i.e : jpg,jpeg,png ";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Input Field Handler Function
  const inputFieldHandler = (e, inputType = "") => {
    const { name, value } = e.target;
    const valueType = typeof value;
    if (inputType === "number" && valueType === "string") {
      let updatedValue = value.replace(/[^0-9]/g,"");
      console.log(updatedValue,"Updated Value");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (inputType === "text" && valueType === "string") {
      let updatedValue= value.replace(/^\s+/, "").replace(/[0-9]/g, "");
      setInputFieldValue({
        ...inputFieldValue,
        [name]: updatedValue,
      });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  
  // Image Handler
  const imageHandler = (e) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;
    if (files.length > 0) {
      if (imageFormat.includes(files[0]?.type)) {
        setImageFile(files[0]);
      } else {
        toast.error("Invalid Image Format");
        setImageFile("");
      }
    }
  };

// console.log(inputFieldValue,"Input Field Value");

  // Handling Add Gift
  const addGiftHandler = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      try {
        setButtonLoader(true);
        const formdata = new FormData();
        formdata.append("giftName", inputFieldValue?.giftName);
        // formdata.append("giftUnit", inputFieldValue?.giftUnit);
        formdata.append("giftPrice", inputFieldValue?.giftPrice);
        formdata.append("giftImage", imageFile);
        const response = await addGiftController(formdata);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setButtonLoader(false);
          setAddModal(false);
          setErrors("");
          setInputFieldValue("");
          setImageFile("");
          fetchGiftManagementList();
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setButtonLoader(false);
          setErrors("");
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  return (
    <ModalBox
      status={addModal}
      onClose={() => {
        {
          setAddModal(false);
          setInputFieldValue("");
          setImageFile("");
          setErrors("");
        }
      }}
      title={"Add Gift"}
      customizedFooter={<></>}
    >
      <React.Fragment>
        <div class="card">
          <div class="card-body">
            <form class="forms-sample">
              <div class="form-group">
                <label htmlFor="Banner ">Gift Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputName1"
                  maxLength={25}
                  placeholder="Enter Gift Name"
                  value={inputFieldValue?.giftName}
                  name="giftName"
                  onChange={(e) => {
                    inputFieldHandler(e, "text");
                  }}
                />
                <span style={{ color: "red" }} className="error">
                  {errors?.giftName}
                </span>
              </div>
              {/* <div class="form-group">
                <label for="exampleInputEmail3">Inventory <span className="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail3"
                  placeholder="Enter Units"
                  name="giftUnit"
                  value={inputFieldValue?.giftUnit}
                  onChange={(e) => {
                    inputFieldHandler(e, "number");
                  }}
                />
                <span style={{ color: "red" }} className="error">
                  {errors.giftUnit}
                </span>
              </div> */}
              <div class="form-group">
                <label for="exampleInputEmail3">Price <span className="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail3"
                  placeholder="Enter Price"
                  value={inputFieldValue?.giftPrice}
                  name="giftPrice"
                  onChange={(e) => {
                    inputFieldHandler(e, "number");
                  }}
                />
                <span style={{ color: "red" }} className="error">
                  {errors.giftPrice}
                </span>
              </div>
              <div class="form-group d-flex justify-content-between align-items-center">
                <label>File upload <span className="text-danger">*</span></label>

                <div class="">
                  <input
                    ref={inputRef}
                    type="file"
                    class="form-control file-upload-info"
                    hidden
                    placeholder="Upload Image"
                    accept="/image*"
                    onChange={(e) => imageHandler(e)}
                  />
                  <span class="input-group-append">
                    <button
                      class="file-upload-browse btn btn-gradient-primary"
                      type="button"
                      onClick={() => inputRef.current?.click()}
                    >
                      Upload
                    </button>
                  </span>
                </div>
              </div>
              <span style={{ color: "red" }} className="error">
                {errors.giftImage}
              </span>
              <div className="mb-4">
                {imageFile && imageFile?.name && (
                  <div className="cstm_modal_img_wrap">
                    <img
                      src={URL.createObjectURL(imageFile)}
                      class="mb-2 mw-100 w-100 rounded"
                      alt="gift Image"
                    />
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <button
                  class="btn btn-light"
                  onClick={(e) => {
                    setAddModal(false);
                    setInputFieldValue("");
                    setImageFile("");
                    setErrors("");
                    e.preventDefault();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  ref={buttonLoader}
                  class="btn btn-gradient-primary me-2"
                  onClick={(e) => {
                    addGiftHandler(e);
                  }}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
};

export default AddGiftProduct;
