import React, { useEffect, useState } from "react";
import { dashboardListController } from "../../services/dashboard/dashboardController";
import Chart from "react-google-charts";
import { toast } from "react-toastify";
import { showDateFormatYear } from "../../utility/utils";

function Dashboard() {
  const [topCartData, setTopCartData] = useState([]);
  const [topSellingProduct, setTopSellingProduct] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [topWalletUsers, setTopWalletUsers] = useState([]);
  const [userRegistrationsThisMonth, setUserRegistrationsThisMonth] = useState(
    []
  );

  const fetchingDashboard = async () => {
    try {
      const response = await dashboardListController();
      if (response?.data?.code === 200) {
        console.log("response Dashboaed",response?.data?.data?.topWalletUsers);
        const res = response?.data?.data;

        setTopSellingProduct(
          res?.topSellingProduct.map(
            ({ productDetails, totalQuantitySold }) => {
              const safeProductName =
                productDetails?.productName || "Unknown Product"; // If null or undefined, set to "Unknown Product"
              const safeTotalQuantitySold = totalQuantitySold
                ? Number(totalQuantitySold)
                : 0; // If null or undefined, set to 0

              return {
                productDetails: safeProductName,
                totalQuantitySold: safeTotalQuantitySold,
              };
            }
          )
        );

        setTopCartData(
          res?.allTopCart?.topCartData.map(({date,cartCount }) => {
            const updateDate = date ? showDateFormatYear(date) : ""; // If null or undefined, set to ""
            const TotalCartCount = cartCount ? cartCount : 0; // Convert to number or set to 0 if null

            return {
              date: updateDate,
              cartCount: TotalCartCount,
            };
          })
        );

        setTopUsers(response?.data?.data?.topUsers);

        setTopWalletUsers(
          response?.data?.data?.topWalletUsers.map(
            ({ retailerName, walletAmount }) => {
              const safeRetailerName = retailerName || ""; // If null or undefined, set to ""
              const safeWalletAmount = walletAmount || 0; // If null or undefined, set to 0

              return {
                retailerName: safeRetailerName,
                walletAmount: safeWalletAmount,
              };
            }
          )
        );

        setUserRegistrationsThisMonth(
          response?.data?.data?.userRegistrationsThisMonth.map(
            ({ registrationDate, registrationCount }) => {
              const safeRegistrationDate =
                showDateFormatYear(new Date(registrationDate)) || ""; // If null or undefined, set to ""
              const safeRegistrationCount = registrationCount || 0; // If null or undefined, set to 0

              return {
                registrationDate: safeRegistrationDate,
                registrationCount: safeRegistrationCount,
              };
            }
          )
        );
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const topSellingProductsData = [
    ["Product name", "Total Products", { role: "style" }],
    ...topSellingProduct.map(({ productDetails, totalQuantitySold }) => [
      productDetails,
      totalQuantitySold,
      "#b87333",
    ]),
  ];
  // console.log("topSellingProductsData",topSellingProductsData)

  const userRegistrationsThisMonthData = [
    ["Registration Date", "Registration Count", { role: "style" }],
    ...userRegistrationsThisMonth.map(
      ({ registrationDate, registrationCount }) => [
        registrationDate,
        Number(registrationCount),
        "#303bd6",
      ]
    ),
  ];

  const topWalletUsersData = [
    ["Retailer Name", "Wallet Amount", { role: "style" }],
    ...topWalletUsers.map(({ retailerName, walletAmount }) => [
      retailerName,
      Number(walletAmount),
      "#e8ff00",
    ]),
  ];

  const cartData = [
    ["Date","Abandoned Cart", { role: "style" }],
    ...topCartData.map(({ cartCount, date }) => [
      date,
      cartCount,
      "#ff00c6",
    ]),
  ];

  // console.log(topCartData, "Data---------------->");

  useEffect(() => {
    fetchingDashboard();
  }, []);
  return (
    <>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-home"></i>
              </span>{" "}
              Dashboard
            </h3>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span></span>Overview{" "}
                  <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
              </ul>
            </nav>
          </div>
          <div class="row">
            <div class="col-md-12 stretch-card grid-margin">
              <div class="card bg-gradient-info card-img-holder text-white">
                {userRegistrationsThisMonth &&
                  userRegistrationsThisMonth.length > 0 && (
                    <div class="card-body">
                      <h4 class="font-weight-normal mb-3">
                        Total Registration{" "}
                        <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                      </h4>
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={userRegistrationsThisMonthData}
                        options={{
                          legend: { 
                                    backgroundColor: "#b87333" // specify background color for legend box
                                  },
                                  colors: ["#303bd6", 'blue']
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div class="col-md-12 stretch-card grid-margin">
              <div class="card bg-gradient-danger card-img-holder text-white">
                {topSellingProduct && topSellingProduct.length > 0 && (
                  <div class="card-body">
                    <h4 class="font-weight-normal mb-3">
                      Top Selling Products{" "}
                      <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      options={{
                        legend: { 
                                  backgroundColor: "#b87333" // specify background color for legend box
                                },
                                colors: ['#b87333', 'blue']
                      }}
                    
                      data={topSellingProductsData}
                    />
                  </div>
                )}
              </div>
            </div>
            {topWalletUsers && topWalletUsers.length > 0 && (
              <div class="col-md-12 stretch-card grid-margin">
                <div class="card bg-gradient-success card-img-holder text-white">
                  <div class="card-body">
                    <h4 class="font-weight-normal mb-3">
                      Top Wallet Amount Users{" "}
                      <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      options={{
                        legend: { 
                                  backgroundColor: "#b87333" // specify background color for legend box
                                },
                                colors: ["#e8ff00", 'blue']
                      }}
                      data={topWalletUsersData}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {topCartData && topCartData.length > 0 && (
            <div class="row">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card bg-gradient-primary card-img-holder text-white">
                  <div class="card-body">
                    <h4 class="font-weight-normal mb-3">
                      Total Number Of Abandoned Carts{" "}
                      <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                    </h4>
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      options={{
                        legend: { 
                                  backgroundColor: "#b87333" // specify background color for legend box
                                },
                                colors: ["#ff00c6", 'blue']
                      }}
                      data={cartData}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {topUsers && topUsers.length > 0 && (
            <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Top Users</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th> S.No </th>
                            <th>Retailer Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            <th>Total Orders</th>
                            <th>Total Amount Spent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topUsers && topUsers.length ? (
                            topUsers.map(
                              (
                                {
                                  retailerDetails,
                                  totalAmountSpent,
                                  numberOfOrders,
                                },
                                index
                              ) => {
                                return (
                                  <React.Fragment key={++index}>
                                    <tr>
                                      <td>{++index}</td>
                                      <td>
                                        {retailerDetails && retailerDetails?.retailerName || "---"}
                                      </td>
                                      <td>{retailerDetails && retailerDetails?.email || "---"}</td>
                                      <td>
                                        {retailerDetails && retailerDetails?.mobileNumber || "---"}
                                      </td>
                                      <td>{numberOfOrders || "---"}</td>
                                      <td>{totalAmountSpent || "---"}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={6}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <footer class="footer">
          <div class="container-fluid d-flex justify-content-between">
            <span class="text-muted d-block text-center text-sm-start d-sm-inline-block">
              Copyright © Stoklo 2023
            </span>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Dashboard;
