import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorListController } from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
// import { filterCategoryListController } from "../../services/filterCategoryServices/filterCategoryController";
import { productDetailsController } from "../../services/productServices/productListingController";

function ViewProduct() {
  const [vendorList, setVendorList] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const { productId } = useParams();
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const uploadProductImage = useRef();

  //  Fetching Product Details API
  const fetchingProductDetail = async () => {
    try {
      const response = await productDetailsController(productId);
      if (response?.code === 200) {
        setInputFieldValue(response?.data);
        setImages(response?.data?.productImage);
        toast.success(response?.message);
      } else {
        toast.error(response?.message || "Internal Server Error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Fetching Vendor List
  const fetchingVendorList = async () => {
    const response = await vendorListController();
    console.log(response);
    if (response?.code === 200) {
      setVendorList(response?.data?.rows);
    } else {
      toast.error(response?.message);
    }
  };

  // For Format Value
  const formatValue = (value) => {
    const stringValue = value.toString();
    const dotIndex = stringValue.indexOf(".");
    let formattedValue = "";

    if (dotIndex !== -1) {
      const decimalPart = stringValue.substr(dotIndex + 1, 2); // Take only two decimal places
      const fullDigit = stringValue.substr(0, dotIndex + 1);
      formattedValue = fullDigit + decimalPart;
    } else {
      formattedValue = stringValue;
    }

    return formattedValue;
  };

  useEffect(() => {
    fetchingVendorList();
    fetchingProductDetail();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              View Product
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Product Name
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={45}
                              className="form-control"
                              disabled
                              name="productName"
                              placeholder="Enter Product Name"
                              value={
                                inputFieldValue.productName
                                  ? inputFieldValue.productName
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Vendors
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              className="form-control"
                              value={inputFieldValue.vendorId}
                              name="vendorId"
                              disabled
                            >
                              <option value="">Select Vendor</option>
                              {vendorList.length > 0 &&
                                vendorList.map(({ id, name }) => {
                                  return (
                                    <React.Fragment key={id}>
                                      <option value={id}>{name}</option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Composition Name
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={45}
                              disabled
                              className="form-control"
                              name="compositionName"
                              placeholder="Enter Composition Name"
                              value={
                                inputFieldValue?.compositionName
                                  ? inputFieldValue?.compositionName
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of Stock
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfStock"
                              type="text"
                              placeholder="Enter No. of stock"
                              maxLength={7}
                              disabled
                              className="form-control"
                              value={
                                inputFieldValue?.noOfStock
                                  ? inputFieldValue?.noOfStock
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Units
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              disabled
                              placeholder="Enter units in Kilogram,Litre"
                              className="form-control"
                              name="units"
                              value={
                                inputFieldValue?.units
                                  ? inputFieldValue?.units
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Smallest Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              disabled
                              className="form-control"
                              placeholder="Enter smallest unit in kg,ltr"
                              name="smallestUnit"
                              value={
                                inputFieldValue?.smallestUnit
                                  ? inputFieldValue?.smallestUnit
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">SKU</label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              disabled
                              placeholder="Enter SKU"
                              name="sku"
                              value={
                                inputFieldValue?.sku ? inputFieldValue?.sku : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Packaging
                          </label>
                          <div className="col-sm-9 text-start d-flex">
                            <input
                              type="text"
                              maxLength={11}
                              className="form-control"
                              name="dimestion"
                              disabled
                              placeholder="NNNxNNNxNNN"
                              value={
                                inputFieldValue.dimestion
                                  ? inputFieldValue.dimestion
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={7}
                              className="form-control"
                              name="margin"
                              disabled
                              placeholder="Enter Margin"
                              value={
                                inputFieldValue.margin
                                  ? inputFieldValue.margin
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength="5"
                              className="form-control"
                              name="marginPerUnit"
                              disabled
                              value={
                                inputFieldValue.marginPerUnit
                                  ? inputFieldValue.marginPerUnit
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">MRP</label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={7}
                              className="form-control"
                              disabled
                              name="mrp"
                              placeholder="Enter MRP"
                              value={inputFieldValue.mrp || ""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            MRP Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength="5"
                              disabled
                              className="form-control"
                              name="mrpPerUnit"
                              value={inputFieldValue.mrpPerUnit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={7}
                              className="form-control"
                              disabled
                              name="netPrice"
                              placeholder="Enter Net Price"
                              value={inputFieldValue.netPrice}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={5}
                              disabled
                              className="form-control"
                              name="netPricePerUnit"
                              value={inputFieldValue.netPricePerUnit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of units per pack
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfUnitPerPack"
                              type="text"
                              placeholder="Enter No. of units"
                              maxLength={7}
                              disabled
                              className="form-control"
                              value={
                                inputFieldValue?.noOfUnitPerPack
                                  ? inputFieldValue?.noOfUnitPerPack
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Product Description
                          </label>
                          <div className="col-sm-10 cstm_width2">
                            <textarea
                              type="text"
                              className="form-control"
                              rows="4"
                              disabled
                              placeholder="Write here product description..."
                              name="productComposition"
                              value={inputFieldValue.productComposition}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row cstm_position_set">
                      <div className="col-sm-12">
                        <div className="form-group cstm_wrap_position">
                          <label>Product Image</label>

                          <div className="">
                            <input
                              ref={uploadProductImage}
                              type="file"
                              name="productImage"
                              multiple
                              disabled
                              className="form-control file-upload-info"
                              hidden
                              placeholder="Upload Image"
                              accept=".jpg"
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-4">
                          <div className="row">
                            {images.map(({ productImage, id }, index) => {
                              return (
                                <React.Fragment>
                                  <div className="col-3 col-3 cstm_upload_image">
                                    <img
                                      src={productImage}
                                      className="mb-2 mw-100 w-100 rounded"
                                      alt={inputFieldValue.productName}
                                      height="150px"
                                      width="200px"
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewProduct;
