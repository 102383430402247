import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalBox from "./ModalBox";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";



function Header() {
  const [toggle, setToggle] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (toggle === "") {
      setToggle("sidebar-icon-only");
      document.body.className = "sidebar-icon-only";
    } else {
      setToggle("");
      document.body.className = "";
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("Permission");
    localStorage.removeItem("role")
    toast.success("Admin logout successfully");
    navigate("/");
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className=" navbar-brand-wrapper cstm_wrap_navbar_wrapper ">
          <Link className="navbar-brand brand-logo" to="#">
            <figure className="nav_logo_bar">
              {" "}
              <img src="assets/images/stoklo.jpeg" alt="logo" />
            </figure>
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="#">
            <img src="assets/images/stokloLogo.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            onClick={toggleSidebar}
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right cstmheader_nav">
            <li className="nav-item nav-profile">
              <Dropdown alignright="true">
                <Dropdown.Toggle className="nav-link">
                  <div className="nav-profile-img">
                    <img src="assets/images/user.png" alt="image" />
                    <span className="availability-status online"></span>
                  </div>
                  <div className="nav-profile-text">
                    <p className="mb-1 cstm_color"><Trans>{localStorage.getItem("name")}</Trans></p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="" onClick={(e) => {
                    {
                      e.preventDefault();
                      setLogoutModal(true);
                    }
                  }}>
                    <i className="mdi mdi-power mx-2 text-primary"></i>
                    <Trans>Logout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          {/* Modal for Status Change */}
          <ModalBox
            cstmClass="true"
            className="cstmModal_box"
            status={logoutModal}
            onClose={() => setLogoutModal(false)}
            title={""}
            description="Are you sure you want to Logout?"
            action={logoutHandler}
          />
        </div>
      </nav>
    </>
  );
}

export default Header;
