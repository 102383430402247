import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import { productListController } from "../../services/recommendedServices/recommendedController";
import { toast } from "react-toastify";
import { giftListController } from "../../services/giftServices.jsx/giftController";
import { addSchemeController } from "../../services/schemeServices/schemeController";
import useButtonLoader from "../../hooks/useButtonLoader";
import { Checkbox, FormControlLabel } from "@mui/material";

const AddScheme = () => {
  const [productList, setProductList] = useState([]);
  const [giftList, setGiftList] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const fetchingProductList = async () => {
    try {
      const response = await productListController();
      if (response?.data?.code === 200) {
        setProductList(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Gift Listing
  const fetchGiftManagementList = async () => {
    try {
      const response = await giftListController();
      if (response?.data?.code === 200) {
        setGiftList(response?.data?.data?.gifts);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Input Field Validation
  const validation = () => {
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.schemeName) {
      error["schemeName"] = "Scheme Name is Mandatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.type) {
      error["type"] = "Product Type is Mandatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.noOfProduct) {
      error["noOfProduct"] = "Number Of Product is Mandatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.selectProductid) {
      error["selectProductid"] = "Select Product on Scheme Applied *";
      validationStatus = false;
    }
    if (
      inputFieldValue.type === "1" &&
      (!inputFieldValue.giftId || inputFieldValue.giftId === "")
    ) {
      error["giftId"] = "Select Gift *";
      validationStatus = false;
    }
    if (
      inputFieldValue.type === "2" &&
      (!inputFieldValue.giftProductId || inputFieldValue.giftProductId === "")
    ) {
      error["giftProductId"] = "Select Product *";
      validationStatus = false;
    }
    if (inputFieldValue.termAndCondition === "<p><br></p>") {
      error["termAndCondition"] = "Terms And Condition is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  //   Add Scheme
  const AddSchemeHandler = async () => {
    const status = validation();
    if (status) {
      try {
        const reqData = {};
        reqData.schemeName = inputFieldValue.schemeName || "";
        reqData.termAndCondition = inputFieldValue.termAndCondition || "";
        reqData.type = inputFieldValue.type ? Number(inputFieldValue.type) : "";
        reqData.noOfProduct = inputFieldValue.noOfProduct
          ? Number(inputFieldValue.noOfProduct)
          : "";
        reqData.isSpin = Number(inputFieldValue.isSpin) || 1;
        reqData.selectProductid = inputFieldValue.selectProductid
          ? Number(inputFieldValue.selectProductid)
          : "";
        if (Number(inputFieldValue.type) === 1) {
          Object.assign(reqData, { giftId: Number(inputFieldValue.giftId) });
        }
        if (Number(inputFieldValue.type) === 2) {
          Object.assign(reqData, {
            giftProductId: Number(inputFieldValue.giftProductId),
          });
        }
        setButtonLoader(true);
        const response = await addSchemeController(reqData);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          navigate("/scheme-management");
          setButtonLoader(false);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setButtonLoader(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  // console.log("console.log", inputFieldValue);

  //   Config for Jodit Editor
  const config = useMemo(
    () => ({
      buttons: [
        "source",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "paragraph",
        "color",
        "table",
        "link",
        "undo",
        "redo",
        "cut",
        "hr",
        "eraser",
        "copyformat",
        "selectall",
        "formatting",
        "align",
        "removeformat",
        "fullsize",
        "about",
      ],
    }),
    []
  );
  const editor = useRef(null);

  useEffect(() => {
    fetchingProductList();
    fetchGiftManagementList();
  }, []);

  // console.log(inputFieldValue, "InputFieldValue");
  // console.log(errors, "--->errors");
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Add Scheme
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Scheme Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-8 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              placeholder="Enter Name"
                              name="schemeName"
                              value={inputFieldValue.schemeName}
                              //   onChange={(e) => {
                              //     setInputFieldValue({
                              //       ...inputFieldValue,
                              //       ["schemeName"]: e.target.value.replace(
                              //         /^\s+|\d+/g,
                              //         ""
                              //       ),
                              //     });
                              //   }}
                              onChange={(e) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  ["schemeName"]: e.target.value,
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.schemeName}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Select Product (To Be Applied On) <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-8 text-start">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  ["selectProductid"]: e.target.value,
                                });
                              }}
                            >
                              <option value="">Choose Product</option>
                              {productList.map(({ id, productName }, index) => {
                                return (
                                  <option key={id} value={id}>
                                    {productName}
                                  </option>
                                );
                              })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.selectProductid}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Select Product Type <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-8 text-start">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  ["type"]: selectedType,
                                  ...(selectedType === "1" && {
                                    giftProductId: "",
                                  }),
                                  ...(selectedType === "2" && { giftId: "" }),
                                });
                              }}
                            >
                              <option value={0 || ""}>
                                Select Product Type
                              </option>
                              <option value={1}>Gift</option>
                              <option value={2}>Product</option>
                            </select>
                            {!inputFieldValue.type && (
                              <span style={{ color: "red" }} className="error">
                                {errors.type}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {inputFieldValue.type == 1 ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              Select Gift <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-8 text-start">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setInputFieldValue({
                                    ...inputFieldValue,
                                    ["giftId"]: e.target.value,
                                  });
                                }}
                              >
                                <option value={"" || 0}>Select</option>
                                {giftList.map(({ id, giftName }, index) => {
                                  return (
                                    <option key={id} value={id}>
                                      {giftName}
                                    </option>
                                  );
                                })}
                              </select>
                              <span style={{ color: "red" }} className="error">
                                {errors.giftId}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              Select Product <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-8 text-start">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setInputFieldValue({
                                    ...inputFieldValue,
                                    ["giftProductId"]: e.target.value,
                                  });
                                }}
                              >
                                <option value={"" || 0}>Select</option>
                                {productList.map(
                                  ({ id, productName }, index) => {
                                    return (
                                      <option key={id} value={id}>
                                        {productName}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <span style={{ color: "red" }} className="error">
                                {errors.giftProductId}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            No. of product to be added <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-8 text-start">
                            <input
                              type="text"
                              maxlength="4"
                              className="form-control"
                              name="noOfProduct"
                              placeholder="Enter Number of Product"
                              value={inputFieldValue.noOfProduct}
                              onChange={(e) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  ["noOfProduct"]: e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfProduct}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="text-start">
                            <FormControlLabel
                              label={"Spin Type"}
                              className="cstm_label_form"
                              control={
                                <Checkbox
                                  name={"isSpin"}
                                  value={inputFieldValue.isSpin}
                                  onChange={(e) =>
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      ["isSpin"]: e.target.checked ? "2" : "1",
                                    })
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Terms And Condition <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-10 text-start">
                            <JoditEditor
                              ref={editor}
                              value={inputFieldValue?.termAndCondition}
                              tabIndex={1}
                              onBlur={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  termAndCondition: newContent,
                                });
                              }}
                              onChange={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  termAndCondition: newContent,
                                });
                              }}
                              config={config}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.termAndCondition}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <button
                    className="btn btn-gradient-primary mb-2 mr-2 mx-2"
                    onClick={AddSchemeHandler}
                    ref={buttonLoader}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddScheme;
