import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { productListController } from "../../services/recommendedServices/recommendedController";
import { toast } from "react-toastify";
import { giftListController } from "../../services/giftServices.jsx/giftController";
import {
  addSchemeController,
  viewSchemeDetailController,
} from "../../services/schemeServices/schemeController";
import { Checkbox, FormControlLabel } from "@mui/material";

const ViewScheme = () => {
  const [productList, setProductList] = useState([]);
  const [giftList, setGiftList] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchingProductList = async () => {
    try {
      const response = await productListController();
      if (response?.data?.code === 200) {
        setProductList(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Gift Listing
  const fetchGiftManagementList = async () => {
    try {
      const response = await giftListController();
      if (response?.data?.code === 200) {
        setGiftList(response?.data?.data?.gifts);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Add Scheme
  const viewSchemeDetails = async () => {
    try {
      const response = await viewSchemeDetailController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        // toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const editor = useRef(null);

  useEffect(() => {
    fetchingProductList();
    fetchGiftManagementList();
    viewSchemeDetails();
  }, []);

  console.log(inputFieldValue, "InputFieldValue");
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              View Scheme
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h4 className="card-title">Personal Details</h4> */}
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Scheme Name
                          </label>
                          <div className="col-sm-8 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Name"
                              name="schemeName"
                              disabled
                              value={inputFieldValue.schemeName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              Select Product (To Be Applied On)
                            </label>
                            <div className="col-sm-8 text-start">
                              <select
                                className="form-control"
                                value={inputFieldValue.productId}
                                disabled
                              >
                                <option value={0}>Choose Product</option>
                                {productList.map(
                                  ({ id, productName }, index) => {
                                    return (
                                      <option key={id} value={id}>
                                        {productName}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
             
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Select Product Type
                          </label>
                          <div className="col-sm-8 text-start">
                            <select
                              className="form-control"
                              value={inputFieldValue.type}
                              disabled
                            >
                              <option value={0 || ""}>
                                Select Product Type
                              </option>
                              <option value={1}>Gift</option>
                              <option value={2}>Product</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {inputFieldValue.type == 1 ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              Select Gift
                            </label>
                            <div className="col-sm-8 text-start">
                              <select
                                className="form-control"
                                value={inputFieldValue.giftId}
                                disabled
                              >
                                <option value={"" || 0}>Select</option>
                                {giftList.map(({ id, giftName }, index) => {
                                  return (
                                    <option key={id} value={id}>
                                      {giftName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      ):(
                        <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Select Product
                          </label>
                          <div className="col-sm-8 text-start">
                            <select
                              className="form-control"
                              value={inputFieldValue.giftProductId}
                              disabled
                            >
                              <option value={0}>Select</option>
                              {productList.map(({ id, productName }, index) => {
                                return (
                                  <option key={id} value={id}>
                                    {productName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            No. of product to be added
                          </label>
                          <div className="col-sm-8 text-start">
                            <input
                              type="text"
                              maxlength="4"
                              className="form-control"
                              name="noOfProduct"
                              placeholder="Enter Number of Product"
                              value={inputFieldValue.noOfProduct}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="text-start">
                      <FormControlLabel
                        label={"Spin Type"}
                        className="cstm_label_form"
                        control={
                          <Checkbox
                            name={"isSpin"}
                            checked={inputFieldValue.isSpin == "2"}
                           
                          />
                        }
                        />
                        </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Terms And Condition
                          </label>
                          <div className="col-sm-10 text-start">
                          <JoditEditor
                              ref={editor}
                              value={inputFieldValue?.termAndCondition}
                              tabIndex={1}
                              disabled
                              readonly={true}
                              config={{
                                readonly: true,
                                buttons: [
                                  "source",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strikethrough",
                                  "superscript",
                                  "subscript",
                                  "ul",
                                  "ol",
                                  "outdent",
                                  "indent",
                                  "font",
                                  "fontsize",
                                  "paragraph",
                                  "color",
                                  "table",
                                  "link",
                                  "undo",
                                  "redo",
                                  "cut",
                                  "hr",
                                  "eraser",
                                  "copyformat",
                                  "selectall",
                                  "formatting",
                                  "align",
                                  "removeformat",
                                  "fullsize",
                                  "about",
                                ],
                              }}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewScheme;
