import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { forgotPasswordController } from "../../services/authServices/authController";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useButtonLoader from "../../hooks/useButtonLoader";

function ForgotPassword() {
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "SEND",
    "Please wait.."
  );
  const {id} =useParams();
  const navigate = useNavigate();

  //Form validation
  const formValidation = () => {
    let error = "";
    let validationStatus = true;
    if (!inputValue) {
      validationStatus = false;
      error = "Please enter email";
    }
    setErrors(error);
    return validationStatus;
  };

  //Fetch forgot password API
  const fetchForgotPassword = async (e) => {
    e.preventDefault();
    const status = formValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const response = await forgotPasswordController(inputValue);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          setButtonLoader(false);
          navigate("/")
        } else {
          toast.error(response.data.message);
          setButtonLoader(false);
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  return (
    <React.Fragment>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="content-wrapper d-flex align-items-center auth">
            <div class="row flex-grow">
              <div class="col-lg-4 mx-auto">
                <div class="auth-form-light text-left p-5">
                  <div class="brand-logo">
                    {/* <img src="../../assets/images/logo.svg" /> */}
                  </div>
                  <h4>Forgot Password</h4>
                  <h6 class="font-weight-light">
                    Please check your inbox and click in the received link to
                    reset a passowrd
                  </h6>
                  <form class="pt-3">
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        value={inputValue}
                        class="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Enter Your Email Address"
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                      />
                    </div>
                    <span style={{ color: "red" }} className="error">
                      {errors}
                    </span>
                    {/* <div class="form-group">
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div> */}
                    <div class="mt-3">
                      <Link to="#">
                      <button
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        ref={buttonLoader}
                        onClick={(e) => {
                          fetchForgotPassword(e);
                        }}
                      >
                        Send
                      </button>
                        </Link>
                    </div>
                    {/* <div class="my-2 d-flex justify-content-between align-items-center"> */}
                    {/* <div class="form-check">
                        <label class="form-check-label text-muted">
                          <input type="checkbox" class="form-check-input" />{" "}
                          Keep me signed in{" "}
                        </label>
                      </div> */}
                    {/* <Link to="/forgot-password" class="auth-link text-black"> */}
                    <div className="my-2">
                      {/* <h6>
                        Didn't received the link ,{" "}
                        <Link to=""> resend again </Link>
                      </h6> */}
                    </div>
                    {/* </Link> */}
                    {/* </div> */}
                    <div class="mb-2">
                      {/* <button
                        type="button"
                        class="btn btn-block btn-facebook auth-form-btn"
                      >
                        <i class="mdi mdi-facebook me-2"></i>Connect using
                        facebook{" "}
                      </button>
                    </div>
                    <div class="text-center mt-4 font-weight-light">
                      {" "}
                      Don't have an account?{" "}
                      <a href="register.html" class="text-primary">
                        Create
                      </a> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
