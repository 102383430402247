import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  insertVendorController,
  vendorListController,
} from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { isValidEmail, isValidPhoneNumber } from "../../utility/formValidation";
import {
  addBundleController,
  editBundleController,
  viewBundleDetailController,
} from "../../services/bundleServices/bundleController";
import { productListController } from "../../services/recommendedServices/recommendedController";
import { MultiSelect } from "react-multi-select-component";
import { Checkbox, FormControlLabel } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";

const ViewBundle = () => {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [addProductList, setAddProductList] = useState([]);
  const [multipleproductImage, setMultipleProductImage] = useState([]);
  const [images, setImages] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const uploadProductImage = useRef();
  const [search, setSearch] = useState("");
  const [cloneProductList, setCloneProductList] = useState([]);

  const { id } = useParams();

  const viewBundleDetails = async () => {
    try {
      const response = await viewBundleDetailController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        setImages(response?.data?.data?.bundleImages);
        setAddProductList((prevList) => [
          ...prevList,
          ...response?.data?.data?.bundleProducts.map(
            ({ productDetails, qty }) => ({
              productId: productDetails?.id, // Corrected property name
              qty: qty,
              productName: productDetails?.productName,
            })
          ),
        ]);
        setCloneProductList((prevList) => [
          ...prevList,
          ...response?.data?.data?.bundleProducts.map(
            ({ productDetails, qty }) => ({
              productId: productDetails?.id, // Corrected property name
              qty: qty,
              productName: productDetails?.productName,
            })
          ),
        ]);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Vendor List
  const fetchVendorManagementList = async () => {
    try {
      const response = await vendorListController();
      // console.log(response);
      if (response?.code === 200) {
        setVendorsList(response?.data?.rows);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchVendorManagementList();
    viewBundleDetails();
  }, []);

  useEffect(() => {
    setCloneProductList(
      addProductList.filter(({ productName }) =>
        productName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);



  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              View Bundle
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card cstm_card_height">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Bundle Name
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              disabled
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Bundle Name"
                              name="bundleName"
                              value={inputFieldValue.bundleName}
                              //   onChange={(e) => inputFieldHandler(e)}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.bundleName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Bundle Price
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              disabled
                              maxLength={25}
                              placeholder="Enter Bundle Price"
                              className="form-control"
                              name="bundlePrice"
                              value={inputFieldValue.bundlePrice}
                              //   onChange={(e) => inputFieldHandler(e, "number")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.bundlePrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Discounted Price
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              maxLength={10}
                              name="discountPrice"
                              placeholder="Enter Discount Price"
                              value={inputFieldValue.discountPrice}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Select Vendor
                          </label>
                          <div className="col-sm-7 text-start">
                            <select
                              disabled
                              type="text"
                              className="form-control"
                              name="vendorId"
                              placeholder="select Vendor"
                              value={inputFieldValue.vendorId}
                            >
                              <option value={0 || ""}>Select Vendor</option>
                              {vendorsList &&
                                vendorsList.map(({ id, name }, index) => {
                                  return (
                                    <option key={index} value={id}>
                                      {name}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.vendorId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row cstm_drop_styling">
                          <label className="col-sm-2 col-form-label">
                            Select Products
                          </label>
                          <Dropdown
                            className="col-md-6 text-start"
                            autoClose="outside"
                          >
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                              Selected Products
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                               className="cstm_styling_drop_set"
                            >
                              <input
                                placeholder="search product"
                                className="form-control"
                                value={search}
                                name="search"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              {cloneProductList && cloneProductList.length > 0
                                ? cloneProductList.map(
                                    ({ productName, id, qty }, index) => {
                                      return (
                                        <div
                                          className="cstm_flex_style"
                                          key={++index}
                                        >
                                          <Dropdown.Item href="#">
                                            {productName}
                                          </Dropdown.Item>
                                          <td>
                                            <div className="action_button_wrap p-2">
                                              <div className="switch_button">
                                                <p className="cstm_p_count">
                                                  {qty}
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </Dropdown.Menu>
                            <span
                              style={{ color: "red", display: "block" }}
                              className="error"
                            >
                              {errors.productIds}
                            </span>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Product Image
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              ref={uploadProductImage}
                              disabled
                              type="file"
                              name="bundleImage"
                              accept="image/*"
                              className="form-control file-upload-info"
                              hidden
                              placeholder="Upload Image"
                            />

                            <span className="input-group-append cstm_append_group">
                              <button
                                className="file-upload-browse btn btn-gradient-primary"
                                type="button"
                                onClick={() =>
                                  uploadProductImage.current?.click()
                                }
                              >
                                Upload
                              </button>
                            </span>
                            <span
                              style={{ color: "red", display: "block" }}
                              className="error cstm_ere"
                            >
                              {errors.productImage}
                            </span>
                          </div>
                        </div>
                        <div className="mb-4 mt-4">
                          <div className="row">
                            {multipleproductImage?.length > 0
                              ? multipleproductImage?.map((image, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className="col-3 cstm_upload_image mt-4 ml-4">
                                        <img
                                          src={URL.createObjectURL(image)}
                                          className="mb-3  mw-100 w-100 rounded"
                                          alt="banner"
                                          height="150px"
                                          width="200px"
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                              : null}
                          </div>

                          <div className="row">
                            {images &&
                              images.map(({ bundleImage, id }, index) => {
                                return (
                                  <React.Fragment key={++index}>
                                    <div className="col-3 col-3 cstm_upload_image">
                                      <img
                                        src={bundleImage}
                                        className="mb-2 mw-100 w-100 rounded"
                                        alt={""}
                                        height="150px"
                                        width="200px"
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewBundle;
