import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import ModalBox from "../../common/ModalBox";
import {
  addDeliveryController,
  deleteDeliveryController,
  deliveryListingController,
  updateDeliveryController,
} from "../../services/deliveryServices/deliveryController";
import { toast } from "react-toastify";
import { usePermission } from "../../context/create_Context";
import { permissionChecker } from "../../config/roleAccess";
import useButtonLoader from "../../hooks/useButtonLoader";

function DeliveryManagement() {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [buttonLoader,setButtonLoader]=useButtonLoader();
  const [updateInputFieldData, setUpdateInputFieldData] = useState({
    minAmount: "",
    maxAmount: "",
    amount: "",
    id: null,
  });
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryId, setDeliveryId] = useState();
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errors, setErrors] = useState({});
  const {permission}=usePermission();

  const deliveryListing = async () => {
    try {
      const response = await deliveryListingController();
      if (response?.data?.code === 200) {
        setDeliveryList(response?.data?.data);
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Input Field Handler
  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    let updatedValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-dot characters
    updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1"); // Restrict to 2 decimal places
    setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
  };

  // Update Input Field Handler
  const updateInputFieldHandler = (e) => {
    const { name, value } = e.target;
    let updatedValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-dot characters
    updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1"); // Restrict to 2 decimal places
    setUpdateInputFieldData({ ...updateInputFieldData, [name]: updatedValue });
  };

  // Delete Delivery API Fetching
  const deleteChargesHandler = async () => {
    try {
      setButtonLoader(true);
      const response = await deleteDeliveryController(deliveryId);
      setButtonLoader(false)
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setDeleteModal(false);
        deliveryListing();
      } else {
        toast.error(response.response.data.message);
        setDeleteModal(false);
      }
    } catch (e) {
      setButtonLoader(false)
      console.log(e.message);
      setDeleteModal(false);
    }
  };

  // Input Field Validation
  const validation = () => {
    let validationStatus = true;
    let error = {};
    if (!inputFieldValue.minAmount) {
      error["minAmount"] = "Min-Price is mandatory*";
      validationStatus = false;
    }
    if (!inputFieldValue.maxAmount) {
      error["maxAmount"] = "Max-Price is mandatory*";
      validationStatus = false;
    }
    if (!inputFieldValue.amount) {
      error["amount"] = "Price is Mandatory*";
      validationStatus = false;
    }
    if (Number(inputFieldValue.minAmount) > Number(inputFieldValue.maxAmount)) {
      error["minAmount"] = "Min-Price should not be larger than Max-Price*";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Update Input Field Validation
  const updateValidation = () => {
    let validationStatus = true;
    let error = {};
    if (!updateInputFieldData.minAmount) {
      error["minAmount"] = "Min-Price is mandatory*";
      validationStatus = false;
    }
    if (!updateInputFieldData.maxAmount) {
      error["maxAmount"] = "Max-Price is mandatory*";
      validationStatus = false;
    }
    if (!updateInputFieldData.amount) {
      error["amount"] = "Price is Mandatory*";
      validationStatus = false;
    }
    if (
      Number(updateInputFieldData.minAmount) >
      Number(updateInputFieldData.maxAmount)
    ) {
      error["minAmount"] = "Min-Amount should not be larger than Max-Amount";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Add Delivery API fetching
  const addDeliveryHandler = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      setButtonLoader(true)
      try {
        const response = await addDeliveryController(inputFieldValue);
      setButtonLoader(false)
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setAddModal(false);
          deliveryListing();
          setInputFieldValue({});
        } else {
          toast.error(
            response.response.data.message || response?.data?.message
          );
          setAddModal(false);
          setInputFieldValue({});
        }
      } catch (e) {
        console.log(e.message);
      setButtonLoader(false)
        setAddModal(false);
        setInputFieldValue({});
      }
    }
  };

  // Update Delivery API Fetching
  const updateDeliveryHandler = async (e) => {
    e.preventDefault();
    const status = updateValidation();
    // console.log(status, "Status");
    if (status) {
      setButtonLoader(true)
      try {
        const response = await updateDeliveryController({
          ...updateInputFieldData,
          id: deliveryId,
        });
      setButtonLoader(false)
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setEditModal(false);
          deliveryListing();
        } else {
          toast.error(response.response.data.message);
          setEditModal(false);
        }
      } catch (e) {
        console.log(e.message);
      setButtonLoader(false)
        setEditModal(false);
      }
    }
  };

  useEffect(() => {
    deliveryListing();
  }, []);

  // console.log(inputFieldValue);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title"> Delivery Management </h2>
              {permissionChecker(permission, 7, 1) && (
              <div className="button_wrap">
                <Link
                  onClick={() => setAddModal(true)}
                  to="#"
                  className="btn btn-outline-info cstm_btn_wrap_theme"
                >
                  <i className="mdi mdi-plus menu-icon"></i> Add Charges
                </Link>
              </div>
                )}
            </div>
          </div>

          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  {deliveryList && deliveryList.length > 0
                    ? deliveryList.map(
                        ({ minAmount, maxAmount, amount, id }, index) => {
                          return (
                            <React.Fragment>
                              <div className="row mb-4 cstm_row_column">
                                <div className="col-3">
                                  <div className="form-group row">
                                    <label className="col-sm-5 col-form-label">
                                      Min-Price
                                    </label>
                                    <div className="col-sm-7 text-start">
                                      <input
                                        //   onFocus={handleInputClick}
                                        type="text"
                                        className="form-control"
                                        maxLength={5}
                                        name="minPrice"
                                        value={minAmount}
                                        placeholder="Enter Min-Price"
                                        // onChange={(e) => inputHandler(e, id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group row">
                                    <label className="col-sm-5 col-form-label">
                                      Max-Price
                                    </label>
                                    <div className="col-sm-7 text-start">
                                      <input
                                        //   onFocus={handleInputClick}
                                        type="text"
                                        className="form-control"
                                        maxLength={5}
                                        name="maxPrice"
                                        value={maxAmount}
                                        placeholder="Enter Max-Price"
                                        // onChange={(e) => inputHandler(e, id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group row">
                                    <label className="col-sm-5 col-form-label">
                                      Price
                                    </label>
                                    <div className="col-sm-7 text-start">
                                      <input
                                        //   onFocus={handleInputClick}
                                        type="text"
                                        className="form-control"
                                        maxLength={5}
                                        name="amount"
                                        value={amount}
                                        placeholder="Enter Amount"
                                        // onChange={(e) => inputHandler(e, id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="action_button_wrap">
                                  {permissionChecker(permission, 6, 2) && (
                                    <Link
                                      to="#"
                                      className="action_button"
                                      onClick={() => {
                                        setEditModal(true);
                                        setUpdateInputFieldData({
                                          ...updateInputFieldData,
                                          minAmount: minAmount,
                                          maxAmount: maxAmount,
                                          amount: amount,
                                        });
                                        setDeliveryId(id);
                                      }}
                                    >
                                      <i class="mdi mdi-table-edit"></i>
                                    </Link>
                                  )}
                                    {permissionChecker(permission, 6, 4) && (
                                    <Link className="action_button">
                                      <DeleteRoundedIcon
                                        className="cstm_delete_icon"
                                        sx={{ color: red[500] }}
                                        onClick={() => {
                                          setDeliveryId(id);
                                          setDeleteModal(true);
                                        }}
                                      />
                                    </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Delivery Charges */}
        <ModalBox
          status={addModal}
          onClose={() => {
            setAddModal(false);
            setInputFieldValue({});
            setErrors({});
          }}
          title={"Add Delivery Charges"}
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="form-group">
                    <label htmlFor="Banner ">Min-Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputName1"
                      maxLength={5}
                      value={inputFieldValue.minAmount}
                      name="minAmount"
                      placeholder="Enter Min-Price"
                      onChange={inputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.minAmount}
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail3">Max-Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail3"
                      placeholder="Enter Max-Price"
                      name="maxAmount"
                      maxLength={5}
                      value={inputFieldValue.maxAmount}
                      onChange={inputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.maxAmount}
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail3">Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail3"
                      placeholder="Enter Price"
                      name="amount"
                      maxLength={5}
                      value={inputFieldValue.amount}
                      onChange={inputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.amount}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      ref={buttonLoader}
                      className="btn btn-gradient-primary me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        addDeliveryHandler(e);
                      }}
                    >
                      Add
                    </button>
                    <button
                      class="btn btn-light"
                      onClick={(e) => {
                        e.preventDefault();
                        setInputFieldValue({});
                        setAddModal(false);
                        setErrors({});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>
        {/* Edit Delivery Charges */}
        <ModalBox
          status={editModal}
          onClose={() => {
            setEditModal(false);
            setErrors({});
          }}
          title={"Edit Delivery Charges"}
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="form-group">
                    <label htmlFor="Banner ">Min-Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputName1"
                      value={updateInputFieldData.minAmount}
                      name="minAmount"
                      placeholder="Enter Min-Price"
                      // onChange={(e) =>
                      //   setUpdateInputFieldData({
                      //     ...updateInputFieldData,
                      //     minAmount: e.target.value,
                      //   })
                      // }
                      onChange={updateInputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.minAmount}
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail3">Max-Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail3"
                      placeholder="Enter Max-Price"
                      name="maxAmount"
                      maxLength={5}
                      value={updateInputFieldData.maxAmount}
                      // onChange={(e) =>
                      //   setUpdateInputFieldData({
                      //     ...updateInputFieldData,
                      //     maxAmount: e.target.value,
                      //   })
                      // }
                      onChange={updateInputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.maxAmount}
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail3">Price <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail3"
                      placeholder="Enter Price"
                      name="amount"
                      value={updateInputFieldData.amount}
                      maxLength={5}
                      // onChange={(e) =>
                      //   setUpdateInputFieldData({
                      //     ...updateInputFieldData,
                      //     amount: e.target.value,
                      //   })
                      // }
                      onChange={updateInputFieldHandler}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors.amount}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      class="btn btn-gradient-primary me-2"
                      onClick={(e) => updateDeliveryHandler(e)}
                      ref={buttonLoader}
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-light"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateInputFieldData({});
                        setErrors({});
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>{" "}
        {/* Delete Charges  */}
        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete Charges"
          description={"Are you sure you want to delete this?"}
          action={deleteChargesHandler}
          uploadButtonElement={buttonLoader}
        />
      </div>
    </React.Fragment>
  );
}

export default DeliveryManagement;
