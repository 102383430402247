import React, { useEffect, useState } from "react";
import { winningListController } from "../../services/gameServices/gameController";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { showDateFormatYear, timeFormat } from "../../utility/utils";

const WinningList = () => {
  const [winnersList, setWinnersList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState();

  const fetchingWinnerList = async () => {
    try {
      const response = await winningListController(activePage, limit);
      // console.log(response?.data?.data?.data, "Respone winning List");
      if (response?.data?.code === 200) {
        setWinnersList(response?.data?.data?.data);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchingWinnerList();
  }, [activePage, limit]);
  return (
    <React.Fragment>
      {/* Adding Winning List Management */}
      <div className="page-header mt-5">
        <div className="page_title_wrap ">
          <h3 className="page-title">Winning List</h3>
          <div className="button_wrap"></div>
        </div>
      </div>

      {/* Search bar  */}
      <div className="row wrap_selectCstm my-4">
        <div className="col-1 cstm_padding_set">
          <select
            className="pageSelect input"
            onChange={(e) => setLimit(e.target.value)}
            value={limit}
          >
            <option value={"10"}>10</option>
            <option value={"25"}>25</option>
            <option value={"50"}>50</option>
            <option value={"100"}>100</option>
          </select>
        </div>
      </div>
      {/* {loader} */}
      <div className="row wrap_selectCstm">
        <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date & Time</th>
                      <th>Users Name</th>
                      <th>Winning Of Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {winnersList && winnersList.length > 0
                      ? winnersList.map(
                          (
                            {
                              id,
                              name,
                              email,
                              createdAt,
                              status,
                              updatedAt,
                              userId,
                              description,
                              phone,
                              retailerDetails,
                              sliceName,
                              offerDetails,
                              schemeDetails,
                              prize,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={id}>
                                <tr>
                                  <td>{(activePage - 1) * limit + ++index}</td>
                                  <td>
                                    {showDateFormatYear(createdAt)} ||{" "}
                                    {timeFormat(createdAt)}
                                  </td>
                                  <td>
                                    {retailerDetails?.retailerName || "---"}
                                  </td>

                                  <td>{`${sliceName} ${
                                    schemeDetails || offerDetails
                                      ? "(" +
                                        (schemeDetails
                                          ? schemeDetails.schemeName
                                          : offerDetails.couponCode) +
                                        ")"
                                      : prize !== 0
                                      ? "(" + prize + ")"
                                      : ""
                                  }`}</td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <h5>
                    Showing {startNumber} to{" "}
                    {startNumber + winnersList?.length - 1} of {totalList}{" "}
                    entries
                  </h5>
                </div>
                {totalList > 10 ? (
                  <div className="pagination_wrap">
                    <Pagination
                      activePage={activePage}
                      linkClass="page-link"
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalList}
                      pageRangeDisplayed={5}
                      itemClassLast="last_page next"
                      itemClassFirst="first_page prev"
                      activeLinkClass="active"
                      onChange={handlePageChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </React.Fragment>
  );
};

export default WinningList;
