import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorListController } from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import { addProductController } from "../../services/productServices/productListingController";
import useButtonLoader from "../../hooks/useButtonLoader";

function AddProduct() {
  const [multipleproductImage, setMultipleProductImage] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const [submitButton, setSubmitButton] = useButtonLoader(
    "Add",
    "Please wait..."
  );
  const navigate = useNavigate();
  const uploadProductImage = useRef();

  // Function for checking alphanumeric
  function containsAlphaNumeric(input) {
    // Check if the input contains at least one alphabetic character and one numeric character
    return /[a-zA-Z]/.test(input) && /[0-9]/.test(input);
  }

  // Input Field Validation
  const inputFieldValidation = () => {
    let error = {};
    const validImageFormat = ["image/jpg", "image/jpeg", "image/png"];
    let validationStatus = true;
    if (!inputFieldValue.vendorId) {
      error["vendorId"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.netPrice) {
      error["netPrice"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.productName) {
      error["productName"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.noOfStock) {
      error["noOfStock"] = "Required*";
      validationStatus = false;
    }
    if (multipleproductImage.length === 0) {
      error["productImage"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.sku) {
      error["sku"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.noOfUnitPerPack) {
      error["noOfUnitPerPack"] = "Required*";
      validationStatus = false;
    }
    if (inputFieldValue.sku && !containsAlphaNumeric(inputFieldValue.sku)) {
      error["sku"] = "SKU should contain both Alphabet & Numerical*";
      validationStatus = false;
    }
    if (multipleproductImage.length > 0) {
      multipleproductImage.forEach((image, index) => {
        if (!validImageFormat.includes(image.type)) {
          error["productImage"] = `Invalid image format at ${index + 1}`;
          validationStatus = false;
        }
      });
    }
    if (!inputFieldValue.margin) {
      error["margin"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.mrp) {
      error["mrp"] = "Required*";
      validationStatus = false;
    }

    if (!inputFieldValue.dimestion) {
      error["dimestion"] = "Required*";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  //Fetching Vendor List
  const fetchingVendorList = async () => {
    const response = await vendorListController();
    console.log(response);
    if (response?.code === 200) {
      setVendorList(response?.data?.rows);
    } else {
      toast.error(response?.message);
    }
  };

  // multiple image handler function
  const multipeImageHandler = (e) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;
    if (files.length > 0) {
      if (
        imageFormat.includes(files[0]?.type) &&
        files.length < 10
      ) {
        setMultipleProductImage([...multipleproductImage, ...files]);
      } else {
        toast.error(
          files.length >= 10
            ? "Sorry, you can't upload more than 10 images"
            : "Invalid image format"
        );
      }
    }
  };

  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    const valueType = typeof value;
    console.log("valueType", valueType);

    if (type === "number" && valueType === "string") {
      let updatedValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-dot characters
      updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1"); // Restrict to 2 decimal places
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text" && valueType === "string") {
      let updatedValue = value.replace(/[^a-zA-Z]/g, ""); // Allow only alphabets
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "dimension" && valueType === "string") {
      let updatedValue = value.replace(/[^0-9xX*]/g, ""); // Allow digits and 'x' or 'X'
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "noOfProduct" && valueType === "string") {
      let updatedValue = value.replace(/^[^1-9]|[^0-9*]/g, "");

      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "sku" && valueType === "string") {
      let updatedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Allow only alphanumeric characters
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  const removeProductImage = (id) => {
    const newProductImage = [...multipleproductImage];
    newProductImage.splice(id, 1);
    setMultipleProductImage(newProductImage);
  };

  // Add Product
  const addProductHandler = async (e) => {
    e.preventDefault();
    const status = inputFieldValidation();
    if (status) {
      try {
        setSubmitButton(true);
        const formData = new FormData();
        console.log(inputFieldValue, "inputFieldValue");
        formData.append("productName", inputFieldValue.productName || "");
        formData.append("vendorId", inputFieldValue.vendorId || "");
        formData.append(
          "compositionName",
          inputFieldValue.compositionName || ""
        );
        formData.append("units", inputFieldValue.units || "");
        formData.append("noOfStock", inputFieldValue.noOfStock || "");
        formData.append(
          "noOfUnitPerPack",
          inputFieldValue?.noOfUnitPerPack || ""
        );
        formData.append("margin", inputFieldValue.margin || "");
        formData.append(
          "marginPerUnit",
          inputFieldValue.margin && inputFieldValue.noOfUnitPerPack
            ? formatValue(
                inputFieldValue.margin / inputFieldValue.noOfUnitPerPack
              )
            : ""
        );
        formData.append(
          "productComposition",
          inputFieldValue.productComposition || ""
        );
        formData.append("mrp", inputFieldValue.mrp || "");
        formData.append("smallestUnit", inputFieldValue.smallestUnit || "");
        formData.append(
          "mrpPerUnit",
          inputFieldValue.mrp && inputFieldValue.noOfUnitPerPack
            ? formatValue(inputFieldValue.mrp / inputFieldValue.noOfUnitPerPack)
            : ""
        );
        formData.append("netPrice", inputFieldValue.netPrice || "");
        formData.append(
          "netPricePerUnit",
          inputFieldValue.netPrice && inputFieldValue.noOfUnitPerPack
            ? formatValue(
                inputFieldValue.netPrice / inputFieldValue.noOfUnitPerPack
              )
            : ""
        );
        formData.append("dimestion", inputFieldValue.dimestion || "");

        formData.append("sku", inputFieldValue.sku || "");
        multipleproductImage.forEach((item, index) => {
          formData.append("productImage", item);
        });

        const response = await addProductController(formData);
        if (response.code === 200) {
          toast.success(response?.message);
          setSubmitButton(false);
          navigate(-1);
        } else {
          toast.error(response?.message);
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  // For Format Decimal Value
  const formatValue = (value) => {
    let formattedValue = "";
    const floatValue = parseFloat(value);
    const roundedValue = floatValue.toFixed(2);
    formattedValue = roundedValue;
    return formattedValue;
  };

  useEffect(() => {
    fetchingVendorList();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Add Products
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Product Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={45}
                              name="productName"
                              placeholder="Enter Product Name"
                              value={inputFieldValue.name}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.productName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            Vendors <span className="text-danger">*</span>
                          </label>
                          <div class="col-sm-9 text-start">
                            <select
                              class="form-control"
                              onChange={inputFieldHandler}
                              value={inputFieldValue.vendorId}
                              name="vendorId"
                            >
                              <option value="">Select Vendor</option>
                              {vendorList.length > 0 &&
                                vendorList.map(({ id, name }) => {
                                  return (
                                    <React.Fragment key={id}>
                                      <option value={id}>{name}</option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.vendorId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Composition Name
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              name="compositionName"
                              placeholder="Enter Composition Name"
                              value={inputFieldValue.compositionName}
                              onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            SKU <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={50}
                              className="form-control"
                              name="sku"
                              placeholder="Enter SKU  (e.g:ABC123)"
                              value={inputFieldValue.sku}
                              onChange={(e) => {
                                inputFieldHandler(e, "sku");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.sku}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Units
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              name="units"
                              maxLength={15}
                              placeholder="Enter Units in Kilogram, litre"
                              value={inputFieldValue.units}
                              onChange={(e) => {
                                inputFieldHandler(e, "text");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Smallest Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="smallestUnit"
                              placeholder="Enter unit in kg , ltr"
                              value={inputFieldValue.smallestUnit}
                              onChange={(e) => {
                                inputFieldHandler(e, "text");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Packaging <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={11}
                              className="form-control"
                              name="dimestion"
                              placeholder="NNNxNNNxNNN"
                              value={inputFieldValue.dimestion}
                              onChange={(e) => {
                                inputFieldHandler(e, "dimension");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.dimestion}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of Stock <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfStock"
                              type="text"
                              maxLength={8}
                              className="form-control"
                              placeholder="Enter No. Of Stocks"
                              value={inputFieldValue.noOfStock}
                              onChange={(e) => {
                                inputFieldHandler(e, "noOfProduct");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfStock}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            MRP <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={8}
                              name="mrp"
                              placeholder="Enter MRP"
                              value={inputFieldValue.mrp}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.mrp}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            MRP Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              placeholder="Enter MRP/Unit"
                              name="mrpPerUnit"
                              value={
                                inputFieldValue.mrp &&
                                inputFieldValue.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue.mrp /
                                        inputFieldValue.noOfUnitPerPack
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={8}
                              className="form-control"
                              placeholder="Enter Net Price"
                              name="netPrice"
                              value={inputFieldValue.netPrice}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.netPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              name="netPricePerUnit"
                              placeholder="Enter Net Price/Unit"
                              value={
                                inputFieldValue.netPrice &&
                                inputFieldValue.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue.netPrice /
                                        inputFieldValue.noOfUnitPerPack
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={8}
                              className="form-control"
                              placeholder="Enter Margin"
                              name="margin"
                              value={inputFieldValue.margin}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.margin}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              name="marginPerUnit"
                              placeholder="Enter Margin/Unit"
                              value={
                                inputFieldValue.margin &&
                                inputFieldValue.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue.margin /
                                        inputFieldValue.noOfUnitPerPack
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of units per pack <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfUnitPerPack"
                              type="text"
                              maxLength={8}
                              className="form-control"
                              placeholder="Enter No. Of Units"
                              value={inputFieldValue.noOfUnitPerPack}
                              onChange={(e) => {
                                inputFieldHandler(e, "noOfProduct");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfUnitPerPack}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Product Description
                          </label>
                          <div className="col-sm-10 text-start cstm_width2">
                            <textarea
                              type="text"
                              className="form-control"
                              name="productComposition"
                              placeholder="Enter Product Description Here..."
                              rows="4"
                              onChange={inputFieldHandler}
                              value={inputFieldValue.productComposition}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row cstm_position_set">
                      <div className="col-sm-12">
                        <div className="form-group cstm_wrap_position">
                          <label>
                            Product Image <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-9 text-end">
                            <input
                              ref={uploadProductImage}
                              type="file"
                              multiple
                              name="productImage"
                              accept="image/*"
                              className="form-control file-upload-info"
                              hidden
                              placeholder="Upload Image"
                              onChange={multipeImageHandler}
                            />
                            <span
                              style={{ color: "red" }}
                              className="error cstm_ere"
                            >
                              {errors.productImage}
                            </span>
                            <span className="input-group-append cstm_append_group">
                              <button
                                className="file-upload-browse btn btn-gradient-primary"
                                type="button"
                                onClick={() =>
                                  uploadProductImage.current?.click()
                                }
                              >
                                Upload
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="mb-4 mt-4">
                          <div className="row">
                            {multipleproductImage.length > 0 &&
                              multipleproductImage.map((image, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div className="col-3 cstm_upload_image">
                                      <span className="cstm_close_icon">
                                        <i
                                          className="mdi mdi-close-circle-outline"
                                          onClick={() =>
                                            removeProductImage(index)
                                          }
                                        ></i>
                                      </span>
                                      <img
                                        src={URL.createObjectURL(image)}
                                        className="mb-2 mw-100 w-100 rounded"
                                        alt="banner"
                                        height="150px"
                                        width="200px"
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      ref={submitButton}
                      className="btn btn-gradient-primary mb-2"
                      onClick={(e) => addProductHandler(e)}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddProduct;
