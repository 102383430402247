import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  addNewOffersController,
  viewOfferController,
} from "../../services/offersServices/offersManagementController";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
} from "../../utility/utils";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";

function ViewOffer() {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  // Fetching View Offer API
  const fetchingViewOffer = async (e) => {
    try {
      const response = await viewOfferController(id);

      if (response.data.code === 200) {
        const { data } = response.data;

        setInputFieldValue({
          ...data,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
        });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchingViewOffer();
  }, []);

  console.log(inputFieldValue, "---inputFieldValue");
  const editor = useRef();
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              View Offer
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Code
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              name="couponCode"
                              placeholder="Enter Coupon Code"
                              value={inputFieldValue.couponCode}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Type
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              type="text"
                              className="form-control"
                              name="couponType"
                              placeholder="Enter Coupon Type"
                              value={
                                inputFieldValue.couponType
                                  ? inputFieldValue.couponType
                                  : ""
                              }
                              disabled
                            >
                              <option value={""}>Choose Coupon Type</option>
                              <option value={2}>Percentage</option>
                              <option value={1}>Flat</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {inputFieldValue.couponType &&
                      inputFieldValue.couponType === 2 ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon%
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="couponInPercent"
                                value={inputFieldValue.couponInPercent}
                                maxLength={10}
                                placeholder="Enter Coupon Percentage"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Amount
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                name="couponAmount"
                                placeholder="Enter Coupon Amount"
                                value={inputFieldValue.couponAmount}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Min Amount
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="minAmount"
                              placeholder="Enter min-amount"
                              value={inputFieldValue.minAmount}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="row">
                      {inputFieldValue.couponType === 2 && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">
                                Max Discount
                              </label>
                              <div className="col-sm-9 text-start">
                                <input
                                  name="maxAmount"
                                  type="text"
                                  maxLength={7}
                                  className="form-control"
                                  placeholder="Enter max-discount"
                                  value={inputFieldValue.maxAmount}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">
                                Coupon Title
                              </label>
                              <div className="col-sm-9 text-start">
                                <input
                                  name="couponTitle"
                                  type="text"
                                  maxLength={7}
                                  className="form-control"
                                  value={inputFieldValue.couponTitle || ""}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                  {inputFieldValue.type!==2 && (
                    <>
                    <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Coupon Start Date
                        </label>
                        <div className="col-sm-9 text-start">
                          <div className="col-sm-9 text-start">
                            <div className="react-datepicker-wrapper_retailer">
                              <DatePicker
                                placeholderText="Coupon Start Date"
                                className="form-control"
                                selected={inputFieldValue.startDate}
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dateFormat="dd/MM/yyyy"
                                minDate={Date.now()}
                                onChangeRaw={(e) => e.preventDefault()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Coupon End Date
                        </label>
                        <div className="col-sm-9 text-start">
                          <div className="react-datepicker-wrapper_retailer">
                            <DatePicker
                              placeholderText="Coupon End Date"
                              className="form-control"
                              selected={inputFieldValue.endDate}
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                              dateFormat="dd/MM/yyyy"
                              minDate={Date.now()}
                              onChangeRaw={(e) => e.preventDefault()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                  )}
                  <div className="row">
                    {inputFieldValue.couponType === 1 && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Title
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="couponTitle"
                              type="text"
                              maxLength={7}
                              className="form-control"
                              value={
                                inputFieldValue.couponTitle
                                  ? inputFieldValue.couponTitle
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                                {/* Adding Type  */}
                                <div className="col-md-6">
                      <div className="form-group">
                        <div className="text-start">
                          <FormControlLabel
                          className="cstm_label_form"
                            label={"Spin Type"}
                            control={
                              <Checkbox
                                name={"type"}
                                // value={inputFieldValue.type}
                                checked={inputFieldValue.type=="2"}
                                // onChange={(e)=>inputFieldHandler(e,"spinType")}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Terms and Conditions
                          </label>
                          <div className="col-sm-10 text-start cstm_width2">
                            <JoditEditor
                              ref={editor}
                              value={inputFieldValue?.couponDescription}
                              tabIndex={1}
                              disabled
                              readonly={true}
                              config={{
                                readonly: true,
                                buttons: [
                                  "source",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strikethrough",
                                  "superscript",
                                  "subscript",
                                  "ul",
                                  "ol",
                                  "outdent",
                                  "indent",
                                  "font",
                                  "fontsize",
                                  "paragraph",
                                  "color",
                                  "table",
                                  "link",
                                  "undo",
                                  "redo",
                                  "cut",
                                  "hr",
                                  "eraser",
                                  "copyformat",
                                  "selectall",
                                  "formatting",
                                  "align",
                                  "removeformat",
                                  "fullsize",
                                  "about",
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewOffer;
