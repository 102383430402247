import React, { useRef, useState, useEffect } from "react";
import {
  addRetailerController,
  editRetailerDetailsController,
  licenseFormsController,
  ownershipTypeController,
  retailerDetailController,
} from "../../services/retailerServices/retailerServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import {
  isValidPhoneNumber,
  isValidPANNumber,
  isValidAadharNumber,
  isValidEmail,
} from "../../utility/formValidation";
import DatePicker from "react-datepicker";
import { Checkbox, FormControlLabel } from "@mui/material";
import { dateFormatYear } from "../../utility/utils";

function EditRetailer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [panImage, setPanImage] = useState("");
  const [panImageName, setPanImageName] = useState();
  const [shopImageName, setShopImageName] = useState();
  const [licenceImageName, setLicenceImageName] = useState([]);
  const [ownershipList, setOwnershipList] = useState([]);
  const [licenseFormCheckBox, setLicenseFormCheckBox] = useState([]);
  const [licenceDetail, setLicenceDetail] = useState([]);
  const [shopImage, setShopImage] = useState("");
  const [errors, setErrors] = useState({});
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update",
    "Please Wait"
  );
  // const [bussinessDetail, setBusinessDetail] = useState({});
  // const [editLicenseDetail, setEditLicenseDetail] = useState([]);
  const [removeLicenceId, setRemoveLicenceId] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({
    retailerName: "",
    email: "",
    aadharNumber: "",
    mobileNumber: "",
    secondaryMobileNumber: "",
    shopLocation: "",
    shopName: "",
    type: "",
    panBussiness: "",
    panName: "",
    gstNumber: "",
  });
  const [licenseForm, setLicenseForm] = useState([]);
  const [storeImage, setStoreImage] = useState([]);
  const [licenseFormValidation, setLicenseFormValidation] = useState([]);
  // const [licenceDetail, setLicenceDetail] = useState([]);
  console.log("storeimage", storeImage);
  //Input Field Validation.
  const formValidation = () => {
    let validationStatus = true;
    const validImageFormat = ["image/jpeg", "image/png", "image/jpg"];
    const error = {};
    if (!inputFieldValue.retailerName) {
      error["retailerName"] = "Enter your name *";
      validationStatus = false;
    }
    if (inputFieldValue.email && !isValidEmail(inputFieldValue.email)) {
      error["email"] = "Enter valid email *";
      validationStatus = false;
    }
    if (
      inputFieldValue.aadharNumber &&
      !isValidAadharNumber(inputFieldValue.aadharNumber)
    ) {
      error["aadharNumber"] = "Enter valid aadhar number *";
      validationStatus = false;
    }
    if (!inputFieldValue.mobileNumber) {
      error["mobileNumber"] = "Enter your phone Number *";
      validationStatus = false;
    } else if (!isValidPhoneNumber(inputFieldValue.mobileNumber)) {
      error["mobileNumber"] = "Enter valid phone number *";
      validationStatus = false;
    }
    if (
      inputFieldValue.secondaryMobileNumber &&
      !isValidPhoneNumber(inputFieldValue.secondaryMobileNumber)
    ) {
      error["secondaryMobileNumber"] = "Enter valid phone number *";
      validationStatus = false;
    }
    if (!inputFieldValue.type || inputFieldValue.type == 0) {
      error["ownershipType"] = "Select ownership type *";
      validationStatus = false;
    }
    if (!inputFieldValue.shopName) {
      error["shopName"] = "Enter shop name*";
      validationStatus = false;
    }
    if (!inputFieldValue.shopLocation) {
      error["shopLocation"] = "Enter shop location *";
      validationStatus = false;
    }

    // if (panImage?.name) {
    //   if (!validImageFormat.includes(panImage.type)) {
    //     error["panImage"] = "Please upload a valid image format";
    //     validationStatus = false;
    //   }
    // }
    if (inputFieldValue.panName || inputFieldValue.panBussiness || panImage) {
      if (!inputFieldValue.panBussiness) {
        error["panBussiness"] = "Please enter PAN number *";
        validationStatus = false;
      } else if (!isValidPANNumber(inputFieldValue.panBussiness)) {
        error["panBussiness"] =
          "Please enter valid PAN number i.e AAAPL1234C *";
        validationStatus = false;
      }
      if (!inputFieldValue.panName) {
        error["panName"] = "Enter you PAN name *";
      }
      if (!panImage) {
        error["panImage"] = "Please upload your PAN image *";
        validationStatus = false;
      }
      if (panImage?.name && panImage?.type) {
        if (!validImageFormat.includes(panImage?.type)) {
          error["panImage"] = "Please upload a valid image format *";
          validationStatus = false;
        }
      }
    }
    if (!shopImage) {
      error["shopImage"] = "Please upload Image *";
      validationStatus = false;
    } else if (shopImage?.name && shopImage?.type) {
      if (!validImageFormat.includes(shopImage?.type)) {
        error["shopImage"] = "Please upload a valid image format *";
        validationStatus = false;
      }
    }

    const encounteredLicenseNumbers = new Set();
    for (let i = 0; i < licenceDetail.length; i++) {
      const License = licenceDetail[i];
      const currentStoreImage = storeImage[i];
      const { approvedLicenceName } = License;
      let errorField = {
        [approvedLicenceName]: {},
      };

      if (!License.licenceNumber) {
        validationStatus = false;
        errorField[approvedLicenceName]["licenceNumber"] =
          "Licence Number Required *";
      } else if (encounteredLicenseNumbers.has(License?.licenceNumber)) {
        validationStatus = false;
        errorField[approvedLicenceName]["licenceNumber"] =
          "Licence Number must be unique *";
      } else {
        encounteredLicenseNumbers.add(License?.licenceNumber);
      }

      if (!License.expiryDate) {
        validationStatus = false;
        errorField[approvedLicenceName]["expiryDate"] =
          "Expiry Date Required *";
      }
      if (!currentStoreImage.storeImg) {
        validationStatus = false;
        errorField[approvedLicenceName]["storeImg"] =
          "Licence Image  Required *";
      }

      setLicenseFormValidation((prevLicenseForm) => {
        const updatedLicenseValidationForm = {
          ...prevLicenseForm,
          ...errorField,
        };
        return updatedLicenseValidationForm;
      });
    }

    setErrors(error);
    return validationStatus;
  };

  const imageHandler = (e, imageName) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;

    if (files.length > 0) {
      if (imageFormat.includes(files[0]?.type)) {
        if (imageName === "panImage") {
          setPanImage(e.target.files[0]);
          setPanImageName(e.target.files[0]?.name);
        }
        if (imageName === "shopImage") {
          setShopImage(e.target.files[0]);
          setShopImageName(e.target.files[0]?.name);
        }
      } else {
        toast.error("Invalid Image Format");
        if (imageName === "panImage") {
          setPanImage({});
          setPanImageName("");
        }
        if (imageName === "shopImage") {
          setShopImage({});
          setShopImageName("");
        }
      }
    }
  };

  // Fetching Ownership Type
  const fetchingOwnershipType = async () => {
    try {
      const response = await ownershipTypeController();
      console.log(response, "ownerShipType");
      if (response?.code === 200) {
        setOwnershipList(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching license forms API
  const fetchingLicenseForms = async () => {
    try {
      const response = await licenseFormsController();
      // console.log(response);
      if (response?.code === 200) {
        setLicenseFormCheckBox(response?.data);
        // console.log(response?.data, "-------------->");
      } else {
        console.log(response.message, response.code);
        toast.error(response?.message);
      }
    } catch (e) {
      // console.log(e.message);
    }
  };

  // Fetching Retailer Detail
  const fetchRetailerDetail = async () => {
    try {
      const data = await retailerDetailController(id);
      if (data?.code === 200) {
        setInputFieldValue({
          ...data?.data,
          ...(data?.data?.BussinessDetail || {}),
        });
        setShopImage(data?.data?.BussinessDetail?.shopImage);
        setPanImage(data?.data?.BussinessDetail?.panImage);
        console.log(data.data.licenceDetails, "--->");
        setLicenceDetail(
          data.data?.LicenceDetails.map((licenceDetail) => {
            return {
              ...licenceDetail,
              expiryDate: new Date(licenceDetail.expiryDate),
            };
          }).sort((a, b) =>
            a.approvedLicenceName.localeCompare(b.approvedLicenceName, "en", {
              numeric: true,
            })
          )
        );
        setStoreImage(
          data?.data?.LicenceDetails.map(
            ({ storeImage, approvedLicenceName }) => ({
              licenceId: approvedLicenceName ? approvedLicenceName : "",
              storeImg: storeImage ? storeImage : "",
            })
          ).sort((a, b) =>
            a.licenceId.localeCompare(b.licenceId, "en", {
              numeric: true,
            })
          )
        );

        setLicenceImageName(
          data?.data?.LicenceDetails.map(
            ({ imageName, approvedLicenceName }) => ({
              licenceId: approvedLicenceName ? approvedLicenceName : "",
              imageName: imageName ? imageName : "",
            })
          ).sort((a, b) =>
            a.licenceId.localeCompare(b.licenceId, "en", {
              numeric: true,
            })
          )
        );
      } else {
        toast.error(data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  console.log("LicenceImageName", licenceImageName);

  const handleLicenseForm = (e) => {
    const { name } = e.target;
    console.log(name, "--name");
    // deleteLicenceId=[];
    const lastChar = name.charAt(name.length - 1);
    if (licenceDetail.some((item) => item.id)) {
      licenceDetail.forEach((removeId) => {
        if (removeId.id && !removeLicenceId.includes(removeId.id)) {
          setRemoveLicenceId((prevRemoveLicenceId) => [
            ...prevRemoveLicenceId,
            removeId.id,
          ]);
        }
      });
    }

    if (licenceDetail.some((item) => item.approvedLicenceName === name)) {
      const updatedLicenceDetail = licenceDetail.filter(
        (item) => !item.approvedLicenceName.endsWith(lastChar)
      );
      setLicenceDetail(updatedLicenceDetail);
      setLicenseForm((prevLicenseForm) =>
        prevLicenseForm.filter((item) => !item.endsWith(lastChar))
      );
      setStoreImage((prevImage) =>
        prevImage.filter((item) => !item.licenceId.endsWith(lastChar))
      );
      setLicenceImageName((prevImageName) =>
        prevImageName.filter((name) => !name.licenceId.endsWith(lastChar))
      );
    } else {
      let newArr = [];
      let mainArr = [];
      let storeImgArr = [];
      let licenceImgName = [];
      licenseFormCheckBox.forEach((item) => {
        const licenceFormLastChar = item.licence.charAt(
          item.licence.length - 1
        );
        if (licenceFormLastChar === lastChar) {
          if (item.licence.includes(lastChar)) {
            const obj = {
              id: "",
              licenceId: item.licence,
              licenceNumber: "",
              expiryDate: "",
              approvedLicenceName: item.licence,
              storeImage: "",
            };
            const storeImgObj = {
              licenceId: item.licence,
              storeImg: "",
            };
            const licence_Image_Name = {
              licenceId: item.licence,
              imageName: "",
            };
            newArr.push(item.licence);
            mainArr.push(obj);
            storeImgArr.push(storeImgObj);
            licenceImgName.push(licence_Image_Name);
          }
        }
      });
      setLicenceDetail([...licenceDetail, ...mainArr]);
      setLicenseForm([...licenseForm, ...newArr]);
      setStoreImage([...storeImage, ...storeImgArr]);
      setLicenceImageName([...licenceImageName, ...licenceImgName]);
    }
  };

  // Fetching Edit Retailer API
  const editRetailerHandler = async (e) => {
    e.preventDefault();
    const status = formValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const formData = new FormData();
        formData.append("name", inputFieldValue.retailerName || "");
        formData.append("email", inputFieldValue.email || "");
        formData.append("aadharNumber", inputFieldValue.aadharNumber || "");
        formData.append("phoneNumber", inputFieldValue.mobileNumber || "");
        formData.append(
          "secondaryMobileNumber",
          inputFieldValue.secondaryMobileNumber || ""
        );
        formData.append("shopLocation", inputFieldValue.shopLocation || "");
        formData.append("shopName", inputFieldValue.shopName || "");
        formData.append("ownershipType", inputFieldValue.type || "");
        formData.append("panBussiness", inputFieldValue.panBussiness || "");
        formData.append("panName", inputFieldValue.panName || "");
        formData.append("gstNumber", inputFieldValue.gstNumber || "");
        formData.append("shopImage", shopImage);
        formData.append("panImage", panImage);
        formData.append("id", id);
        formData.append(
          "licenceDetail",
          JSON.stringify(
            licenceDetail.map((items) => {
              return {
                licenceId: items.id ? items.id : "",
                licenceNumber: items.licenceNumber,
                expiryDate: dateFormatYear(items.expiryDate),
                approvedLicenceName: items.approvedLicenceName,
                storeImage: items.storeImage ? items.storeImage : "",
              };
            })
          )
        );
        storeImage.forEach((image, index) => {
          if (image.storeImg?.name) {
            formData.append("storeImage", image.storeImg);
            formData.append("indexNo", index);
          }
        });
        if (removeLicenceId.length > 0) {
          removeLicenceId.forEach((id, index) => {
            formData.append("licenceIdsToRemove", id);
          });
        }

        const response = await editRetailerDetailsController(formData);
        if (response.code === 200) {
          toast.success(response.message);
          setButtonLoader(false);
          navigate(-1);
        } else {
          // toast.error(response.message);
          setButtonLoader(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  // console.log(removeLicenceId, "---------->storeImage");

  // License Input Field Handler
  const licenseInputFieldHandler = (e) => {
    const { name, value } = e.target;
    const [updateName, licenceId] = name.split("_");

    setLicenceDetail((prevLicenceDetail) => {
      return prevLicenceDetail.map((item) => {
        if (item.approvedLicenceName === licenceId) {
          return { ...item, [updateName]: value };
        }
        return item;
      });
    });
  };

  // Licence Expiry Date Handler
  const licenceExpiryDateHandler = (date, name, objectId) => {
    console.log("date", objectId);
    setLicenceDetail((prevLicenceDetail) => {
      return prevLicenceDetail.map((item) => {
        // console.log(item.approvedLicenceName, "------>Item.id");
        if (item.approvedLicenceName === objectId) {
          return { ...item, [name]: date };
        }
        return item;
      });
    });
  };
  // console.log(licenceDetail, "---------->Licence detail");
  const licenseInputImageHandler = (e, licenceName) => {
    // console.log(id, "Id");
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;
    if (files.length > 0) {
      if (imageFormat.includes(files[0]?.type)) {
        setStoreImage((prevImage) => {
          const imageIndex = prevImage.findIndex(
            (image) => image.licenceId === licenceName
          );
          if (imageIndex !== -1) {
            const updatedImage = [...prevImage];
            // console.log(updatedImage, "updatedImage");
            updatedImage[imageIndex] = {
              ...prevImage[imageIndex],
              storeImg: files[0],
            };

            return updatedImage;
          }

          return prevImage;
        });
        setLicenceDetail((prevImage) => {
          const imageIndex = prevImage.findIndex(
            (image) => image.approvedLicenceName === licenceName
          );
          if (imageIndex !== -1) {
            const updatedImage = [...prevImage];
            // console.log(updatedImage, "updatedImage");
            updatedImage[imageIndex] = {
              ...prevImage[imageIndex],
              storeImage: files[0],
            };

            return updatedImage;
          }

          return prevImage;
        });
        setLicenceImageName((prevImageNames) => {
          const imageIndex = prevImageNames.findIndex(
            (imageName) => imageName.licenceId === licenceName
          );

          if (imageIndex !== -1) {
            const updatedImageNames = [...prevImageNames];
            updatedImageNames[imageIndex] = {
              ...prevImageNames[imageIndex],
              imageName: files[0].name,
            };
            return updatedImageNames;
          }

          return prevImageNames;
        });
      } else {
        toast.error("Invalid Image Format");
      }
    }
  };

  // console.log(storeImage);

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    const valueType = typeof value;
    let sanitizedText;
    if (
      (name === "mobileNumber" ||
        name === "aadharNumber" ||
        name === "secondaryMobileNumber") &&
      valueType === "string"
    ) {
      sanitizedText = value.replace(/\D/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: sanitizedText });
    } else if (name === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  const deleteImageHandler = (name) => {
    if (name === "panImage") {
      setPanImage("");
      setPanImageName("");
    }
    if (name === "shopImage") {
      setShopImage("");
      setShopImageName("");
    }
  };

  // Delete Licence Image Handler
  // const deleteLicenceImageHandler = (id) => {
  //   console.log(id, "idLicenceImage");
  //   const licenceImageToDelete = licenceImageName.find(
  //     (imageName) => imageName.licenceId === id
  //   );

  //   console.log(licenceImageToDelete, "qeqqeq");
  //   console.log(licenceImageName, "LicenceImageName");

  //   // return false;

  //   if (licenceImageToDelete && licenceImageToDelete.imageName) {
  //     setLicenceImageName((prevLicenceImage) =>
  //       prevLicenceImage.map((item) =>
  //         item.licenceId === id ? { ...item, imageName: "" } : item
  //       )
  //     );
  //   }
  // };

  const deleteLicenceImageHandler = (id) => {
    console.log(id, "idLicenceImage");
    const licenceImageToDelete = storeImage.find(
      (imageName) => imageName.licenceId === id
    );
    const licenceNameImageToDelete = licenceImageName.find(
      (licenceImageName) => licenceImageName.licenceId === id
    );

    // console.log(licenceImageToDelete, "qeqqeq");
    // console.log(licenceImageName, "LicenceImageName");

    // return false;

    if (licenceImageToDelete && licenceImageToDelete.storeImg) {
      setStoreImage((prevStoreImage) =>
        prevStoreImage.map((item) =>
          item.licenceId === id ? { ...item, storeImg: "" } : item
        )
      );
    }
    if (licenceNameImageToDelete && licenceNameImageToDelete.imageName) {
      setLicenceImageName((prevLicenceImageName) =>
        prevLicenceImageName.map((item) =>
          item.licenceId === id ? { ...item, imageName: "" } : item
        )
      );
    }
  };

  // console.log(inputFieldValue, "------->inputFieldValue");

  useEffect(() => {
    fetchRetailerDetail();
    fetchingOwnershipType();
    fetchingLicenseForms();
  }, []);

  const licenceImageRefs = licenceDetail.map(() => React.createRef());
  const panRef = useRef();
  const shopRef = useRef();

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Edit Retailer Details
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Personal Details</h4>
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Retailer Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              name="retailerName"
                              placeholder="Enter Name"
                              value={
                                inputFieldValue.retailerName
                                  ? inputFieldValue.retailerName
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.retailerName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Aadhar Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              name="aadharNumber"
                              maxLength={12}
                              placeholder="Enter Aadhar Number"
                              value={
                                inputFieldValue.aadharNumber
                                  ? inputFieldValue.aadharNumber
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.aadharNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Email Address
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              maxlength="35"
                              placeholder="Enter Email Address"
                              value={
                                inputFieldValue.email
                                  ? inputFieldValue.email
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxlength="10"
                              disabled
                              className="form-control"
                              name="mobileNumber"
                              placeholder="Enter Phone Number"
                              value={inputFieldValue.mobileNumber || ""}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.mobileNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Secondary Phone Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxlength="10"
                              className="form-control"
                              name="secondaryMobileNumber"
                              placeholder="Enter Secondary Phone Number"
                              value={
                                inputFieldValue?.secondaryMobileNumber
                                  ? inputFieldValue?.secondaryMobileNumber
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.secondaryMobileNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title">Business Details</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Store Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={45}
                              className="form-control"
                              placeholder="Enter Store Name"
                              name="shopName"
                              value={inputFieldValue?.shopName || ""}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.shopName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Shop Location<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="60"
                              placeholder="Enter Shop Location"
                              name="shopLocation"
                              value={
                                inputFieldValue.shopLocation
                                  ? inputFieldValue.shopLocation
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.shopLocation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Ownership Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              className="form-control"
                              onChange={inputFieldHandler}
                              name="type"
                              value={inputFieldValue?.type}
                            >
                              <option value="0">Select Ownership Type</option>
                              {ownershipList.map(({ id, types }) => {
                                return <option value={id}>{types}</option>;
                              })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.ownershipType}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            GST Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              placeholder="Enter GST Number"
                              name="gstNumber"
                              value={
                                inputFieldValue?.gstNumber
                                  ? inputFieldValue?.gstNumber
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row text-start">
                          <label className="col-sm-3 col-form-label">
                            PAN Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={20}
                              className="form-control"
                              name="panName"
                              placeholder="Enter Pan Name"
                              value={
                                inputFieldValue?.panName
                                  ? inputFieldValue?.panName
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.panName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={12}
                              className="form-control"
                              name="panBussiness"
                              placeholder="Enter Pan Number"
                              value={
                                inputFieldValue?.panBussiness
                                  ? inputFieldValue?.panBussiness
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.panBussiness}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Image
                          </label>
                          <div className="col-md-9">
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <div className="col-sm-12 text-end">
                                {/* <div> */}
                                <div className="file-wrap">
                                  <div
                                    className={`file-input ${
                                      !panImageName &&
                                      errors.panImage &&
                                      "is-invalid"
                                    }`}
                                    onClick={() => panRef.current?.click()}
                                  >
                                    <div className="choose">
                                      <span>Choose file</span>
                                    </div>
                                    <div className="file-name">
                                      <span>
                                        {(panImageName &&
                                          panImageName.slice(0, 20)) ||
                                          "No file chosen"}
                                      </span>
                                    </div>
                                  </div>
                                  {!panImageName && errors.panImage && (
                                    <div className="invalid-feed text-start error">
                                      {errors.panImage}
                                    </div>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) => {
                                    imageHandler(e, "panImage");
                                  }}
                                  placeholder="Select Image"
                                  className={`form-control `}
                                  accept="image/*"
                                  hidden
                                  ref={panRef}
                                />
                                {/* </div> */}
                              </div>
                            </div>
                            <div className="mb-4">
                              {panImageName ? (
                                <div className="cstm_img_set_retails cstm_image_retails_set">
                                  <i
                                    className="mdi mdi-close-circle-outline"
                                    onClick={() =>
                                      deleteImageHandler("panImage")
                                    }
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(panImage)}
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="banner"
                                  />
                                </div>
                              ) : (
                                panImage && (
                                  <div className="cstm_img_set_retails cstm_image_retails_set">
                                    <i
                                      className="mdi mdi-close-circle-outline"
                                      onClick={() =>
                                        deleteImageHandler("panImage")
                                      }
                                    ></i>
                                    <img
                                      src={panImage}
                                      className="mb-2 mw-100 w-100 rounded"
                                      alt="banner"
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Store Image <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <div className="col-sm-12 text-end">
                                <div className="file-wrap">
                                  <div
                                    className={`file-input ${
                                      !shopImageName &&
                                      errors.shopImage &&
                                      "is-invalid"
                                    }`}
                                    onClick={() => shopRef.current?.click()}
                                  >
                                    <div className="choose">
                                      <span>Choose file</span>
                                    </div>
                                    <div className="file-name">
                                      <span>
                                        {(shopImageName &&
                                          shopImageName.slice(0, 20)) ||
                                          "No file chosen"}
                                        {/* {shopImageName || shopImage} */}
                                      </span>
                                    </div>
                                  </div>
                                  {!shopImageName && errors.shopImage && (
                                    <div className="invalid-feed text-start error">
                                      {errors.shopImage}
                                    </div>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) => {
                                    imageHandler(e, "shopImage");
                                  }}
                                  placeholder="Select Image"
                                  className={`form-control `}
                                  accept="image/*"
                                  hidden
                                  ref={shopRef}
                                />
                              </div>
                            </div>
                            <div className="mb-4">
                              {shopImageName ? (
                                <div className="cstm_img_set_retails cstm_image_retails_set">
                                  <i
                                    className="mdi mdi-close-circle-outline"
                                    onClick={() =>
                                      deleteImageHandler("shopImage")
                                    }
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(shopImage)}
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="banner"
                                  />
                                </div>
                              ) : (
                                shopImage && (
                                  <div className="cstm_img_set_retails cstm_image_retails_set">
                                    <i
                                      className="mdi mdi-close-circle-outline"
                                      onClick={() =>
                                        deleteImageHandler("shopImage")
                                      }
                                    ></i>
                                    <img
                                      src={shopImage}
                                      className="mb-2 mw-100 w-100 rounded"
                                      alt="banner"
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title">Licence Details</h4>
                    <div className="row">
                      {licenseFormCheckBox.map((item) => {
                        return (
                          <React.Fragment>
                            <div className="col-4 text-start">
                              <FormControlLabel
                                label={item.licence}
                                control={
                                  <Checkbox
                                    name={item.licence}
                                    value={item.licence}
                                    checked={
                                      licenseForm.includes(item.licence) ||
                                      licenceDetail.some(
                                        ({ approvedLicenceName }) =>
                                          approvedLicenceName === item.licence
                                      )
                                    }
                                    onChange={(e) => handleLicenseForm(e)}
                                  />
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="row">
                      {licenceDetail &&
                        licenceDetail.length > 0 &&
                        licenceDetail.map((item, index) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <h4 className="my-4">
                                  {item.approvedLicenceName}
                                </h4>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Licence Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9 text-start">
                                      <input
                                        type="text"
                                        name={`licenceNumber_${item.approvedLicenceName}`}
                                        value={item.licenceNumber}
                                        className="form-control"
                                        onChange={(e) =>
                                          licenseInputFieldHandler(e)
                                        }
                                      />
                                      <span
                                        style={{ color: "red" }}
                                        className="error text-start"
                                      >
                                        {licenseFormValidation[
                                          item.approvedLicenceName
                                        ] &&
                                          licenseFormValidation[
                                            item.approvedLicenceName
                                          ].licenceNumber}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Expiry Date{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9 text-start">
                                      <div className="react-datepicker-wrapper_retailer">
                                        <DatePicker
                                          placeholderText="Expiry Date"
                                          className="form-control"
                                          selected={item.expiryDate}
                                          value={item.expiryDate}
                                          showYearDropdown
                                          yearDropdownItemNumber={100}
                                          scrollableYearDropdown
                                          showMonthDropdown
                                          useShortMonthInDropdown
                                          onChange={(date) => {
                                            licenceExpiryDateHandler(
                                              date,
                                              "expiryDate",
                                              item.approvedLicenceName
                                            );
                                          }}
                                          dateFormat="dd/MM/yyyy"
                                          minDate={Date.now()}
                                          onChangeRaw={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                        <span
                                          style={{ color: "red" }}
                                          className="error text-center"
                                        >
                                          {licenseFormValidation[
                                            item.approvedLicenceName
                                          ] &&
                                            licenseFormValidation[
                                              item.approvedLicenceName
                                            ].expiryDate}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Licence Image
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9 text-start">
                                      <div className="form-group d-flex cstm_margin_remove justify-content-between align-items-center">
                                        <div className="col-sm-12">
                                          <div>
                                            <div className="file-wrap">
                                              <div
                                                className={`file-input ${
                                                  licenseFormValidation[
                                                    item.approvedLicenceName
                                                  ] &&
                                                  licenseFormValidation[
                                                    item.approvedLicenceName
                                                  ].storeImg
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  licenceImageRefs[
                                                    index
                                                  ]?.current?.click()
                                                }
                                              >
                                                <div className="choose">
                                                  <span>Choose file</span>
                                                </div>
                                                <div className="file-name">
                                                  <span>
                                                    {(licenceImageName[index] &&
                                                      licenceImageName[index]
                                                        .imageName) ||
                                                      "No file chosen"}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) => {
                                                licenseInputImageHandler(
                                                  e,
                                                  item.approvedLicenceName
                                                );
                                              }}
                                              placeholder="Select Image"
                                              className={`form-control `}
                                              accept="image/*"
                                              hidden
                                              ref={licenceImageRefs[index]}
                                            />
                                          </div>

                                          <span
                                            style={{ color: "red" }}
                                            className="error"
                                          >
                                            {licenseFormValidation[
                                              item.approvedLicenceName
                                            ] &&
                                              licenseFormValidation[
                                                item.approvedLicenceName
                                              ].storeImg}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="mb-4 cstm_margin_remove">
                                        {storeImage.find(
                                          (image) =>
                                            image.licenceId ===
                                            item.approvedLicenceName
                                        )?.storeImg ? (
                                          <div className="cstm_img_set_retails cstm_image_retails_set">
                                            <i
                                              className="mdi mdi-close-circle-outline"
                                              onClick={() =>
                                                deleteLicenceImageHandler(
                                                  item.approvedLicenceName
                                                )
                                              }
                                            ></i>
                                            <img
                                              src={
                                                storeImage.find(
                                                  (image) =>
                                                    image.licenceId ===
                                                    item.approvedLicenceName
                                                )?.storeImg?.name
                                                  ? URL.createObjectURL(
                                                      storeImage.find(
                                                        (image) =>
                                                          image.licenceId ===
                                                          item.approvedLicenceName
                                                      ).storeImg
                                                    )
                                                  : storeImage.find(
                                                      (image) =>
                                                        image.licenceId ===
                                                        item.approvedLicenceName
                                                    )?.storeImg
                                              }
                                              alt="retailer"
                                            />
                                          </div>
                                        ) : (
                                          <div className="mt-5 text-start">
                                            <h3 className="h3">{null}</h3>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <button
                      onClick={(e) => {
                        editRetailerHandler(e);
                      }}
                      ref={buttonLoader}
                      type="submit"
                      className="btn btn-gradient-primary mb-2"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditRetailer;
