import React, { useEffect, useState } from "react";
import {
  cancelOrderController,
  changeOrderStatusController,
  refundPaymentController,
  viewOrderController,
} from "../../services/orderServices/orderListController";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  dateFormatYear,
  dateTimeFormat,
  showDateFormatYear,
  subtractAndFormatDate,
  timeFormat,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";

function ViewOrder() {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderSummary, setOrderSummary] = useState({});
  const [schemeDetail, setSchemeDetail] = useState([]);
  const [orderStatus, setOrderStatus] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const [CancelledModal, setCancelledModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelDescription, setCancelDescription] = useState("");
  const [cancelDescriptionModal, setCancelDescriptionModal] = useState(false);
  const navigate = useNavigate();
  const [submitButton, setSubmitButton] = useButtonLoader("", "Please wait...");
  const [trackingId, setTrackingId] = useState("");
  const [orderStatusModal, setOrderStatusModal] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [errors, setErrors] = useState("");
  const [refundModal, setRefundModal] = useState(false);

  const fetchViewOrder = async () => {
    try {
      const response = await viewOrderController(id);
      // console.log(response, "OrderResponse");
      if (response?.code === 200) {
        setOrderSummary(response?.data?.orderSummary);
        setSchemeDetail(response?.data?.orderSummary?.usedScheme);
        // console.log(response?.data?.orderSummary?.usedScheme, "-----");

        setOrderDetail(response?.data?.orderSummary?.orderDetail);
        let orderStatusArray = [];

        if (response?.data?.orderSummary?.createdAt) {
          orderStatusArray.push({
            orderStatus: "Approved",
            date: showDateFormatYear(response?.data?.orderSummary?.createdAt),
            time: timeFormat(response?.data?.orderSummary?.createdAt),
          });
        }

        if (response?.data?.orderSummary?.shippedDate) {
          orderStatusArray.push({
            orderStatus: "Shipped",
            date: showDateFormatYear(response?.data?.orderSummary?.shippedDate),
            time: timeFormat(response?.data?.orderSummary?.shippedDate),
          });
        }

        if (response?.data?.orderSummary?.deliveredDate) {
          orderStatusArray.push({
            orderStatus: "Delivered",
            date: showDateFormatYear(
              response?.data?.orderSummary?.deliveredDate
            ),
            time: timeFormat(response?.data?.orderSummary?.deliveredDate),
          });
        }

        if (response?.data?.orderSummary?.rejectedDate) {
          orderStatusArray.push({
            orderStatus: "Cancelled",
            date: showDateFormatYear(
              response?.data?.orderSummary?.rejectedDate
            ),
            time: timeFormat(response?.data?.orderSummary?.rejectedDate),
          });
        }
        setOrderHistory(orderStatusArray);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Cancel Reason Field Validation
  const cancelReasonValidation = () => {
    let validationStatus = true;
    let error = "";
    if (!cancelReason) {
      error = "Reason for cancellation is mandatory*";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // order status handler
  const orderStatusHandler = (e) => {
    const { value } = e.target;
    setOrderStatus(value);
    if (Number(value) === 2) {
      setStatusModal(true);
    } else if (Number(value) === 4) {
      setCancelledModal(true);
    } else {
      setOrderStatusModal(true);
    }
  };

  //   Change Order Status Handler
  const changeOrderStatusHandler = async () => {
    try {
      setSubmitButton(true);
      const reqData = {
        orderId: id,
        trackingId: trackingId || "",
        status: orderStatus,
      };
      const response = await changeOrderStatusController(reqData);
      console.log(response);
      if (response?.code === 200) {
        setStatusModal(false);
        setOrderStatusModal(false);
        setSubmitButton(false);
        toast.success(response?.message);
        fetchViewOrder();
      } else {
        toast.error(response?.message);
        setStatusModal(false);
        setOrderStatusModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
      setStatusModal(false);
      setOrderStatusModal(false);
    }
  };

  //   Change Order Status Handler
  const cancelOrderHandler = async (e) => {
    e.preventDefault();
    const status = cancelReasonValidation();
    if (status) {
      try {
        setSubmitButton(true);
        const reqData = {
          orderId: id,
          cancelReason: cancelReason || "",
          status: orderStatus,
        };

        const response = await cancelOrderController(reqData);
        console.log(response);
        if (response?.code === 200) {
          setCancelledModal(false);
          setSubmitButton(false);
          setCancelReason("");
          setErrors("");
          toast.success(response?.message);
          fetchViewOrder();
        } else {
          toast.error(response?.message);
          setCancelReason("");
          setErrors("");
          setCancelledModal(false);
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
        setCancelledModal(false);
      }
    }
  };

  const refundPaymentHandler = async () => {
    try {
      const data = {
        orderId: Number(id),
      };
      const response = await refundPaymentController(data);
      // console.log(response,"----------message");
      if (response?.data?.code === 200) {
        setRefundModal(false);
        toast.success(
          response?.data?.message || response?.response?.data?.message
        );
        fetchViewOrder();
        setSubmitButton(false);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setRefundModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  useEffect(() => {
    fetchViewOrder();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                  // Retailer
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                View Order / Product Info
              </h3>
              <div className="button_wrap"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-start mt-4">
              <h4>Order Id : {orderSummary.orderNo}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 grid-margin stretch-card mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <h4>Product Image</h4>
                          </th>
                          <th>
                            <h4>Item Summary</h4>
                          </th>
                          <th>
                            <h4>Quantity</h4>
                          </th>
                          <th>
                            <h4>Price</h4>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetail && orderDetail.length > 0 ? (
                          orderDetail.map(
                            (
                              {
                                productDetails,
                                quantity,
                                orderId,
                                orderNo,
                                totalAmount,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          productDetails?.productImage[0]
                                            ?.productImage
                                            ? productDetails?.productImage[0]
                                                ?.productImage
                                            : "assets/images/user.png"
                                        }
                                        // alt={orderId}
                                      />
                                    </td>
                                    <td>
                                      {productDetails?.productName
                                        ? productDetails.productName
                                        : "---"}
                                    </td>
                                    <td>{quantity ? quantity : "---"}</td>
                                    <td>{totalAmount ? totalAmount : "---"}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={4}>Orders Not Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 grid-margin stretch-card mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-6">
                      <h4 className="h4">Order Summary</h4>
                    </div>
                    {orderSummary?.status == 5 ? (
                      <div className="col-6">
                        <p>Refunded</p>
                      </div>
                    ) : (
                      <div className="col-6">
                        <select
                          value={orderSummary?.status}
                          className="form-control"
                          onChange={(e) => orderStatusHandler(e)}
                        >
                          <option value={1}>Order Processing</option>
                          <option value={2}>Shipped</option>
                          <option value={3}>Delivered</option>
                          <option value={4}>Cancelled</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Last Updated</p>
                    </div>
                    <div className="col-6">
                      <p>{dateTimeFormat(orderSummary?.shippedDate) || ""}</p>
                    </div>
                  </div>
                  {orderSummary?.trackingId && (
                    <div className="row">
                      <div className="col-6">
                        <p>Tracking Id</p>
                      </div>
                      <div className="col-6">
                        <p>{orderSummary?.trackingId || ""}</p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-6">
                      <p>Order Created</p>
                    </div>
                    <div className="col-6">
                      <p>{dateFormatYear(orderSummary?.createdAt, "month")}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Order Time</p>
                    </div>
                    <div className="col-6">
                      <p>{timeFormat(orderSummary?.createdAt || "---")}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Item Price</p>
                    </div>
                    <div className="col-6">
                      <p>{orderSummary?.itemAmount}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>GST</p>
                    </div>
                    <div className="col-6">
                      <p>{orderSummary.gst ? orderSummary.gst : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Wallet Amount</p>
                    </div>
                    <div className="col-6">
                      <p>
                        {orderSummary.walletAmount
                          ? orderSummary.walletAmount
                          : ""}
                      </p>
                    </div>
                  </div>
                  {orderSummary?.couponCode ? (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <p>Discount</p>
                        </div>
                        <div className="col-6">
                          <p>{orderSummary?.couponAmount}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="row">
                    <div className="col-6">
                      <p>Delivery Charges</p>
                    </div>
                    <div className="col-6">
                      <p>{orderSummary?.deliveryAmount}</p>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <p>Total</p>
                    </div>
                    <div className="col-6">
                      <p>{orderSummary?.totalAmount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 grid-margin stretch-card mt-4">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <h4>Customer Details</h4>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Customer Name:</td>
                        <td>
                          {orderSummary?.retailerDetails?.retailerName
                            ? orderSummary?.retailerDetails?.retailerName
                            : "---"}
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile Number:</td>
                        <td>
                          {orderSummary?.retailerDetails?.mobileNumber
                            ? orderSummary?.retailerDetails?.mobileNumber
                            : "---"}
                        </td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>
                          {orderSummary?.retailerDetails?.email
                            ? orderSummary?.retailerDetails?.email
                            : "---"}
                        </td>
                      </tr>
                      {orderSummary?.transactionType === "PhonePe" ? (
                        <>
                          <tr>
                            <td>Payment Mode</td>
                            <td>{orderSummary?.transactionType}</td>
                          </tr>
                          <tr>
                            <td>Transaction ID</td>
                            <td>{orderSummary?.transactionId}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>Payment Mode</td>
                          <td>{orderSummary?.transactionType}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Address:</td>
                        <td>
                          {orderSummary?.address
                            ? orderSummary?.address
                            : "---"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-6 grid-margin stretch-card mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>
                            <h4>Status</h4>
                          </th>
                          <th>
                            <h4>Date</h4>
                          </th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderHistory.map(
                          ({ orderStatus, date, time }, index) => {
                            return (
                              <tr key={++index}>
                                <td>{++index}</td>
                                <td> {orderStatus}</td>
                                <td>{date}</td>
                                <td>{time}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {schemeDetail && schemeDetail.length > 0 && (
              <>
                <h2 className="page-title text-start mt-4">Scheme</h2>
                <div className="row">
                  <div className="col-lg-6 grid-margin stretch-card mt-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>
                                  <h4>Product Name</h4>
                                </th>
                                <th>
                                  <h4>Price</h4>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {schemeDetail && schemeDetail.length > 0
                                ? schemeDetail.map(
                                    ({ productDetail }, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr>
                                            {/* <td>
                                      <img
                                        src={
                                          productDetails?.productImage[0]
                                            ?.productImage
                                            ? productDetails?.productImage[0]
                                                ?.productImage
                                            : "assets/images/user.png"
                                        }
                                        // alt={orderId}
                                      />
                                    </td> */}
                                            <td>
                                              {productDetail?.productName
                                                ? productDetail.productName
                                                : "---"}
                                            </td>
                                            <td>
                                              {productDetail?.netPrice
                                                ? productDetail.netPrice
                                                : "---"}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    }
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {Number(orderSummary.status) === 4 && (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <h4>Image</h4>
                          </th>
                          <th>
                            <h4>Reason</h4>
                          </th>
                          <th>Total Price</th>
                          {orderSummary?.walletAmount !== 0 &&
                            orderSummary?.status !== 5 && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={
                                orderSummary.cancelImage
                                  ? orderSummary.cancelImage
                                  : "./assets/images/user.png"
                              }
                              alt=""
                            />
                          </td>
                          <td>
                            {orderSummary?.cancelReason.length > 30 ? (
                              <>
                                {orderSummary?.cancelReason.slice(0, 30)}{" "}
                                <Link
                                  onClick={() => {
                                    setCancelDescription(
                                      orderSummary?.cancelReason
                                    );
                                    setCancelDescriptionModal(true);
                                  }}
                                  to="#"
                                >
                                  see more
                                </Link>
                              </>
                            ) : (
                              orderSummary?.cancelReason
                            )}
                          </td>

                          <td>{orderSummary.totalAmount}</td>
                          {orderSummary?.walletAmount !== 0 &&
                            orderSummary?.status !== 5 && (
                              <td>
                                <Link
                                  to="#"
                                  className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                  onClick={() => setRefundModal(true)}
                                >
                                  Refund Payment
                                </Link>
                              </td>
                            )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ModalBox
          status={statusModal}
          onClose={() => {
            setStatusModal(false);
            setTrackingId("");
          }}
          title="Tracking Id"
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="form-group">
                    <label htmlFor="Banner ">Enter Tracking Id</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputName1"
                      value={trackingId}
                      name="trackingId"
                      placeholder="Enter Tracking Id"
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <button
                        type="submit"
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          changeOrderStatusHandler(e);
                        }}
                        ref={submitButton}
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          setStatusModal(false);
                          setTrackingId("");
                          e.preventDefault();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>

        {/* Cancel Reason Modal*/}
        <ModalBox
          status={CancelledModal}
          onClose={() => {
            setCancelledModal(false);
            setCancelReason("");
            setErrors("");
          }}
          title="Cancel Order"
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="form-group">
                    <label htmlFor="Banner ">
                      Enter Cancel Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="exampleInputName1"
                      cols={4}
                      value={cancelReason}
                      name="cancelReason"
                      placeholder="Enter cancel reason"
                      onChange={(e) => setCancelReason(e.target.value)}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors}
                    </span>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <button
                        type="submit"
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          cancelOrderHandler(e);
                        }}
                        ref={submitButton}
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          setCancelledModal(false);
                          e.preventDefault();
                          setCancelReason("");
                          setErrors("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>

        {/* Order Status Modal */}
        <ModalBox
          status={orderStatusModal}
          onClose={() => setOrderStatusModal(false)}
          title="Change Status"
          description="Are you sure you want to change the status of order"
          action={changeOrderStatusHandler}
          uploadButtonElement={submitButton}
        />

        <ModalBox
          status={cancelDescriptionModal}
          onClose={() => setCancelDescriptionModal(false)}
          title="Cancel Reason"
          customizedFooter={<></>}
        >
          <div>
            <p className="text-center">{cancelDescription}</p>
          </div>
        </ModalBox>

        <ModalBox
          status={refundModal}
          onClose={() => setRefundModal(false)}
          title="Refund Payment"
          description="Are you sure you want to refund payment of this order?"
          action={refundPaymentHandler}
          uploadButtonElement={submitButton}
        />
      </div>
    </React.Fragment>
  );
}

export default ViewOrder;
