import { instance, setJwtToken } from "../../config/axiosInstance";

// Fetching Product List Api
export const deliveryListingController = async () => {
  const data = await instance.get(`admin/delivery-list`,setJwtToken());
  return data;
};

// Add Delivery 
export const addDeliveryController = async (reqData) => {
  const data = await instance.post(`admin/create-deliery-charges`,reqData,setJwtToken());
  return data;
};

// Delete Delivery 
export const deleteDeliveryController = async (id) => {
  const data = await instance.delete(`admin/delete-delivery-charges/${id}` ,setJwtToken());
  return data;
};

// Update Delivery Charges
export const updateDeliveryController = async (reqData) => {
  const data = await instance.post(`admin/update-delivery-charges`,reqData,setJwtToken());
  return data;
};
