import { instance, setJwtToken } from "../../config/axiosInstance";

// Fetching Offer Listing
export const offersListController = async () => {
  const data = await instance.get(
    `admin/spin-offer-list`,

    setJwtToken()
  );
  return data;
};

// Fetching Training Module List Api
export const slicesListController = async () => {
  const data = await instance.get(
    `admin/slices-list`,

    setJwtToken()
  );
  return data;
};

// update Game Management Slices
export const updateGameSliceController = async (reqData) => {
  const data = await instance.post(
    `admin/slices-update`,
    { data: reqData },
    setJwtToken()
  );
  return data;
};

// Fetching Scheme List Api
export const schemeListController = async () => {
  const data = await instance.get(
    `admin/spin-scheme`,

    setJwtToken()
  );
  return data;
};

// Fetching Winning List Api
export const winningListController = async (page,limit) => {
  let params={
    page:page,
    limit:limit
  }
  const data = await instance.get(
    `admin/winning-list`,

    {params , ...setJwtToken()}
  );
  return data;
};
