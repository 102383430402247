import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  addNewOffersController,
  updateOfferController,
  viewOfferController,
} from "../../services/offersServices/offersManagementController";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
} from "../../utility/utils";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { Checkbox, FormControlLabel } from "@mui/material";

function EditOffer() {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [errors, setErrors] = useState({});
  const [showDateField, setShowDateField] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchingViewOffer = async (e) => {
    try {
      const response = await viewOfferController(id);
      if (response.data.code === 200) {
        const resData = response?.data?.data;
        setInputFieldValue({
          ...(resData || {}),
          startDate: new Date(response?.data?.data?.startDate),
          endDate: new Date(response?.data?.data?.endDate),
          type: (response?.data?.data?.type).toString(),
        });
        if ((response?.data?.data?.type).toString() === "2") {
          setShowDateField(true);
        }
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };


  // console.log(showDateField,"show------------>")

  //Input Field Validation
  const validation = () => {
    let error = {};
    let validationStatus = true;
    if (!inputFieldValue.couponCode) {
      error["couponCode"] = "Coupon Code is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === 1 && !inputFieldValue.couponAmount) {
      error["couponAmount"] = "Amount is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponDescription === "<p><br></p>") {
      error["couponDescription"] = "Coupon Description is Mandatory";
      validationStatus = false;
    }
    if (!inputFieldValue.couponTitle) {
      error["couponTitle"] = "Coupon Title is Mandatory";
      validationStatus = false;
    }
    if (!inputFieldValue.minAmount) {
      error["minAmount"] = "Min-Amount is Mandatory";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === "" || !inputFieldValue.couponType) {
      error["couponType"] = "Select Coupon Type";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === 2 && !inputFieldValue.couponInPercent) {
      error["couponInPercent"] = "Coupon Percentage is Mandatory";
      validationStatus = false;
    }
    if (Number(inputFieldValue.couponInPercent) > 100) {
      error["couponInPercent"] =
        "Coupon Percentage should not greater than 100";
      validationStatus = false;
    }
    if (inputFieldValue.couponType === 2 && !inputFieldValue.maxAmount) {
      error["maxAmount"] = "Max-Discount is Mandatory";
      validationStatus = false;
    }
    if (!showDateField && !inputFieldValue.startDate) {
      error["startDate"] = "Start Date is Mandatory";
      validationStatus = false;
    }
    if (!showDateField && !inputFieldValue.endDate) {
      error["endDate"] = "End Date is Mandatory";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Handling Input Fields
  const inputFieldHandler = (e, type = "") => {
    const { name, value, checked } = e.target;
    const valueType = typeof value;
    if (type === "number" && valueType === "string") {
      let updatedValue = value.replace(/[^0-9.\s]/g, ""); // Remove non-numeric and non-dot characters
      updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1"); // Restrict to 2 decimal places
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "couponCode" && valueType === "string") {
      setInputFieldValue({
        ...inputFieldValue,
        [name]: value.replace(/\s/g, ""),
      });
    } else if (type === "ct" && valueType === "string") {
      setInputFieldValue({
        ...inputFieldValue,
        [name]: value.replace(/^\s+/, ""),
      });
    } else if (type === "spinType" && valueType === "string") {
      setInputFieldValue({ ...inputFieldValue, [name]: checked ? "2" : "1" });
      setShowDateField(checked);
      dateFieldHandler();
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  // console.log(inputFieldValue, "InputFieldValue");

  //   Update Offers Handler
  const updateOfferHandler = async (e) => {
    e.preventDefault();
    try {
      const status = validation();
      if (status) {
        setSubmitButton(true)
        const reData = {
          type: showDateField ? 2 : 1,
          couponCode: inputFieldValue.couponCode,
          couponType: inputFieldValue.couponType,
          minAmount: Number(inputFieldValue.minAmount),
          couponTitle: inputFieldValue.couponTitle,
          couponDescription: inputFieldValue.couponDescription,
          id: id,
        };
        if (!showDateField) {
          Object.assign(reData, {
            startDate: dateFormatYear(inputFieldValue.startDate),
            endDate: dateFormatYear(inputFieldValue.endDate),
          });
        }
        if (Number(inputFieldValue.couponType) === 1) {
          Object.assign(reData, {
            couponAmount: Number(inputFieldValue.couponAmount) || "",
            couponInPercent: null,
            maxAmount: null,
          });
        } else {
          Object.assign(reData, {
            maxAmount: Number(inputFieldValue.maxAmount),
            couponInPercent: Number(inputFieldValue.couponInPercent) || "",
            couponAmount: null,
          });
        }

        // console.log(reData,"Data------------>")

        const response = await updateOfferController(reData);
        setSubmitButton(false);
        if (response.data.code === 200) {
          toast.success(response?.data?.message);
          navigate("/offers-management");
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
        }
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  //   Config for Jodit Editor
  const config = useMemo(
    () => ({
      buttons: [
        "source",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "paragraph",
        "color",
        "table",
        "link",
        "undo",
        "redo",
        "cut",
        "hr",
        "eraser",
        "copyformat",
        "selectall",
        "formatting",
        "align",
        "removeformat",
        "fullsize",
        "about",
      ],
    }),
    []
  );

  const dateFieldHandler = () => {
    setInputFieldValue({
      ...inputFieldValue,
      startDate: "",
      endDate: "",
    });
  };

  useEffect(() => {
    fetchingViewOffer();
  }, []);

  //   console.log(inputFieldValue, "--->InputFieldValue");

  const editor = useRef();
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Edit Offer
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Code <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              name="couponCode"
                              placeholder="Enter Coupon Code"
                              value={inputFieldValue.couponCode}
                              onChange={(e) => {
                                inputFieldHandler(e, "couponCode");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.couponCode}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Coupon Type <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              type="text"
                              className="form-control"
                              name="couponType"
                              placeholder="Enter Coupon Type"
                              value={
                                inputFieldValue.couponType
                                  ? inputFieldValue.couponType
                                  : ""
                              }
                              onChange={(e) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  ["couponType"]: Number(e.target.value),
                                });
                              }}
                            >
                              <option value={""}>Choose Coupon Type</option>
                              <option value={2}>Percentage</option>
                              <option value={1}>Flat</option>
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.couponType}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {inputFieldValue.couponType &&
                      inputFieldValue.couponType === 2 ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon% <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="couponInPercent"
                                value={inputFieldValue.couponInPercent || ""}
                                maxLength={10}
                                placeholder="Enter Coupon Percentage"
                                onChange={(e) => {
                                  inputFieldHandler(e, "number");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponInPercent}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Amount <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                name="couponAmount"
                                placeholder="Enter Coupon Amount"
                                value={inputFieldValue.couponAmount || ""}
                                onChange={(e) => {
                                  inputFieldHandler(e, "number");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Min Amount <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="minAmount"
                              placeholder="Enter min-amount"
                              value={inputFieldValue.minAmount}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.minAmount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="row">
                      {inputFieldValue.couponType === 2 && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">
                                Max Discount <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-9 text-start">
                                <input
                                  name="maxAmount"
                                  type="text"
                                  maxLength={7}
                                  className="form-control"
                                  placeholder="Enter max-discount"
                                  value={inputFieldValue.maxAmount}
                                  onChange={(e) => {
                                    inputFieldHandler(e, "number");
                                  }}
                                />
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {errors.maxAmount}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">
                                Coupon Title <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-9 text-start">
                                <input
                                  name="couponTitle"
                                  type="text"
                                  maxLength={25}
                                  className="form-control"
                                  value={inputFieldValue.couponTitle || ""}
                                  onChange={(e) => {
                                    inputFieldHandler(e, "ct");
                                  }}
                                />
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {errors.couponTitle}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {/*  */}
                    {!showDateField && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Start Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="col-sm-9 text-start">
                                <div className="react-datepicker-wrapper_retailer">
                                  <DatePicker
                                    placeholderText="Coupon Start Date"
                                    className="form-control"
                                    selected={inputFieldValue.startDate}
                                    value={inputFieldValue.startDate}
                                    showYearDropdown
                                    isClearable
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat="dd/MM/yyyy"
                                    minDate={Date.now()}
                                    onChangeRaw={(e) => e.preventDefault()}
                                    onChange={(date) => {
                                      setInputFieldValue({
                                        ...inputFieldValue,
                                        startDate: date,
                                      });
                                    }}
                                    // onChange={dateHandler(date)}
                                  />
                                  <p style={{ color: "red" }} className="error">
                                    {errors.startDate}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon End Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <div className="react-datepicker-wrapper_retailer">
                                <DatePicker
                                  placeholderText="Coupon End Date"
                                  className="form-control"
                                  selected={inputFieldValue.endDate}
                                  value={inputFieldValue.endDate}
                                  showYearDropdown
                                  isClearable
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat="dd/MM/yyyy"
                                  minDate={Date.now()}
                                  onChangeRaw={(e) => e.preventDefault()}
                                  onChange={(date) => {
                                    setInputFieldValue({
                                      ...inputFieldValue,
                                      endDate: date,
                                    });
                                  }}
                                />
                                <p style={{ color: "red" }} className="error">
                                  {errors.endDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {inputFieldValue.couponType === 1 && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Coupon Title <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                name="couponTitle"
                                type="text"
                                maxLength={7}
                                className="form-control"
                                value={
                                  inputFieldValue.couponTitle
                                    ? inputFieldValue.couponTitle
                                    : ""
                                }
                                onChange={(e) => {
                                  inputFieldHandler(e, "ct");
                                }}
                              />
                              <span style={{ color: "red" }} className="error">
                                {errors.couponTitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Adding Type  */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="text-start">
                            <FormControlLabel
                            className="cstm_label_form"
                              label={"Spin Type"}
                              control={
                                <Checkbox
                                  name={"type"}
                                  checked={showDateField}
                                  onChange={(e) =>
                                    inputFieldHandler(e, "spinType")
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Terms and Conditions <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-10 text-start cstm_width2">
                            <JoditEditor
                              ref={editor}
                              value={inputFieldValue?.couponDescription}
                              tabIndex={1}
                              config={config}
                              onBlur={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  couponDescription: newContent,
                                });
                              }}
                              onChange={(newContent) => {
                                setInputFieldValue({
                                  ...inputFieldValue,
                                  couponDescription: newContent,
                                });
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.couponDescription}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      ref={submitButton}
                      className="btn btn-gradient-primary mb-2"
                      onClick={(e) => updateOfferHandler(e)}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditOffer;
