import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editVendorController,
  insertVendorController,
  viewVendorController,
} from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import { isValidEmail, isValidPhoneNumber } from "../../utility/formValidation";

const EditVendor = () => {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [certificateImageName, setCertificateImageName] = useState("");
  const [certificateImage, setCertificateImage] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  // View Vendor Details
  const viewVendorDetails = async () => {
    try {
      const response = await viewVendorController(id);
      if (response?.data?.code === 200) {
        setInputFieldValue(response?.data?.data);
        setCertificateImage(response?.data?.data?.certificate);
        // console.log(response?.data?.data?.certificate,"Certificate---------->");
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Validation Status
  const validation = () => {
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.name) {
      error["name"] = "Name is Manadatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.email) {
      error["email"] = "Email is Manadatory *";
      validationStatus = false;
    } else if (!isValidEmail(inputFieldValue.email)) {
      error["email"] = "Please enter valid email format *";
      validationStatus = false;
    }
    if (!inputFieldValue.address) {
      error["address"] = "Address is Mandatory *";
      validationStatus = false;
    }
    if (!inputFieldValue.mobileNumber) {
      error["mobileNumber"] = "Mobile Number is Manadatory *";
      validationStatus = false;
    } else if (!isValidPhoneNumber(inputFieldValue.mobileNumber)) {
      error["mobileNumber"] = "Enter Valid Mobile Number *";
      validationStatus = false;
    }
    if (!inputFieldValue.facilityDetails) {
      error["facilityDetails"] = "Facility Details is Manadatory *";
      validationStatus = false;
    }
    if (!certificateImage) {
      error["certificate"] = "Certificate is Manadatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Input Field Handler
  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    if (type === "number") {
      let updatedValue = value.replace(/[^0-9]/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text") {
      let updatedValue = value.replace(/^\s+|\d+/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  const imageHandler = (e) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;

    if (files.length > 0) {
      if (imageFormat.includes(files[0]?.type)) {
        setCertificateImage(files[0]);
        setCertificateImageName(files[0]?.name);
      } else {
        toast.error("Invalid Image Format");
        setCertificateImage("");
        setCertificateImageName("");
      }
    }
  };
  const deleteImageHandler = () => {
    setCertificateImage("");
    setCertificateImageName("");
  };

  // Handle Add Vendor
  const editVendorHandler = async () => {
    const status = validation();
    if (status) {
      try {
        setSubmitButton(true);
        const formData = new FormData();
        formData.append("name", inputFieldValue.name);
        formData.append("email", inputFieldValue.email);
        formData.append("address", inputFieldValue.address);
        formData.append("mobileNumber", inputFieldValue.mobileNumber);
        formData.append("facilityDetails", inputFieldValue.facilityDetails);
        formData.append("certificate", certificateImage);
        formData.append("id", id);
        const response = await editVendorController(formData);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setSubmitButton(false);
          navigate(-1);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };
  useEffect(() => {
    viewVendorDetails();
  }, []);

  const certificateRef = useRef();

  console.log(certificateImage,"CertificateName")
  console.log(certificateImageName,"ceetificateImageName");

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Edit Supplier
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Name"
                              name="name"
                              value={inputFieldValue.name}
                              onChange={(e) => inputFieldHandler(e, "text")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Email <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              placeholder="Enter Email"
                              className="form-control"
                              name="email"
                              value={inputFieldValue.email}
                              onChange={(e) => inputFieldHandler(e, "email")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={10}
                              name="mobileNumber"
                              placeholder="Enter Mobile Number"
                              value={inputFieldValue.mobileNumber}
                              onChange={(e) => inputFieldHandler(e, "number")}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.mobileNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Address <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <textarea
                              type="text"
                              className="form-control"
                              maxLength={100}
                              name="address"
                              placeholder="Enter Address"
                              value={inputFieldValue.address}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.address}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Facility Details <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7 text-start">
                            <textarea
                              type="text"
                              className="form-control"
                              name="facilityDetails"
                              placeholder="Enter Facility Details"
                              value={inputFieldValue.facilityDetails}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.facilityDetails}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Certificate <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-7">
                          <div className="col-sm-12">
                            <div className="form-group d-flex justify-content-between align-items-center">
                              {/* <div> */}
                                <div className="file-wrap w-100">
                                  <div
                                    className={`file-input ${
                                      !certificateImageName &&
                                      errors.certificate &&
                                      "is-invalid"
                                    }`}
                                    onClick={() =>
                                      certificateRef.current?.click()
                                    }
                                  >
                                    <div className="choose">
                                      <span>Choose file</span>
                                    </div>
                                    <div className="file-name">
                                      <span className="text-center">
                                        {certificateImageName ||
                                          "No file chosen"}
                                      </span>
                                    </div>
                                  </div>
                                  {!certificateImageName &&
                                    errors.certificate &&
                                     (
                                      <div className="invalid-feed text-start error">
                                        {errors.certificate}
                                      </div>
                                    )}
                                </div>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={imageHandler}
                                  placeholder="Select Image"
                                  className={`form-control `}
                                  accept="image/*"
                                  hidden
                                  ref={certificateRef}
                                />
                              {/* </div> */}
                            </div>
                            <div className="mb-4">
                              {certificateImageName ? (
                                <div className="cstm_img_set_retails position-relative text-start">
                                  <i
                                    className="mdi mdi-close-circle-outline position-absolute"
                                    onClick={deleteImageHandler}
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(certificateImage)}
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="certificate"
                                  />
                                </div>
                              ) : (
                               certificateImage && (
                                  <div className="cstm_img_set_retails">
                                    <i
                                      className="mdi mdi-close-circle-outline position-absolute"
                                      onClick={deleteImageHandler}
                                    ></i>
                                    <img
                                      src={certificateImage}
                                      className="mb-2 mw-100 w-100 rounded"
                                      alt="certificate"
                                    />
                                  </div>
                               )
                              )}
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-gradient-primary mb-2 mr-2 mx-2"
                      onClick={editVendorHandler}
                      ref={submitButton}
                    >
                      Save
                    </button>
                    <button
                      type="submit"
                      className="btn btn-gradient-danger mb-2"
                      onClick={()=>{
                        navigate("/vendor-management")
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditVendor;
