import {
    instance,
    setJwtToken,
  } from "../../config/axiosInstance";
  
  // Fetching Scheme List Api
  export const dashboardListController = async ()=> {
    const params = {};
    // params.page = page || "";
    // params.limit = limit || "";
    // params.search = search || "";
    const data = await instance.get(`admin/dashboard`,setJwtToken());
    return data;
  };