import React, { useState, useEffect } from "react";
import ModalBox from "../../common/ModalBox";
import { toast } from "react-toastify";
import {
  notificationListController,
  sendNotificationController,
  userListingController,
} from "../../services/notificationServices/notificationController";
import Pagination from "react-js-pagination";
import {
  showDateFormatYear,
  showFullTimeFormat,
} from "../../utility/utils";
import { Link } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { usePermission } from "../../context/create_Context";
import { permissionChecker } from "../../config/roleAccess";

function NotificationManagement() {
  const [notificationList, setNotificationList] = useState([]);
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [loader, show, hide] = useFullPageLoader();
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const {permission}=usePermission();

  //   Fetching Notification List
  const fetchingNotificationList = async () => {
    try {
      show();
      const response = await notificationListController(activePage, limit);
      // console.log(response, "notificationResponze");
      if (response?.data?.code === 200) {
        setNotificationList(response?.data?.data?.notificationList);
        setTotalList(response?.data?.data?.count);
        hide();
        // console.log(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingNotificationList();
  }, [activePage, limit]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="page_title_wrap">
            <h3 className="page-title">Notification Management</h3>
            {permissionChecker(permission, 15, 1) && (
            <div className="button_wrap">
              <Link
                to="/notification-management/add-notification"
                className="btn btn-btn btn-gradient-primary button"
              >
                <i className="mdi mdi-plus menu-icon menu-icon"></i> Add
                Notification
              </Link>
            </div>
            )}
          </div>
        </div>
        <div className="row wrap_selectCstm">
          <div className="col-1 cstm_padding_set">
            <select
              className="pageSelect input"
              onChange={(e) => setLimit(e.target.value)}
              value={limit}
            >
              <option value={"10"}>10</option>
              <option value={"25"}>25</option>
              <option value={"50"}>50</option>
              <option value={"100"}>100</option>
            </select>
          </div>
        </div>
        <div className="row wrap_selectCstm">
          <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive mt-4">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Created At</th>
                        <th>Notification Title</th>
                        <th>Notification Message</th>
                        <th>Schedule Date</th>
                        <th>Schedule Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader}
                      {notificationList && notificationList.length > 0
                        ? notificationList.map(
                            (
                              {
                                id,
                                title,
                                scheduleDate,
                                description,
                                type,
                                createdAt,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{showDateFormatYear(createdAt)}</td>
                                    <td>{title}</td>
                                    <td className="Cstm_see_description">
                                      {description &&
                                      description.length > 30 ? (
                                        <>
                                          {description.slice(0, 30)}{" "}
                                          <Link
                                            onClick={() => {
                                              setShowDescription(description);
                                              setDescriptionModal(true);
                                            }}
                                            to="#"
                                          >
                                            see more
                                          </Link>
                                        </>
                                      ) : (
                                        description
                                      )}
                                    </td>
                                    <td>
                                      {scheduleDate
                                        ? showDateFormatYear(scheduleDate)
                                        : "---"}
                                    </td>
                                    <td>
                                      {scheduleDate
                                        ? showFullTimeFormat(scheduleDate)
                                        : "---"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>
                      Showing {startNumber} to{" "}
                      {startNumber + notificationList?.length - 1} of{" "}
                      {totalList} entries
                    </h5>
                  </div>
                  {totalList >= 10 ? (
                    <div className="pagination_wrap">
                      <Pagination
                        activePage={activePage}
                        linkClass="page-link"
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalList}
                        pageRangeDisplayed={5}
                        itemClassLast="last_page next"
                        itemClassFirst="first_page prev"
                        activeLinkClass="active"
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Show Category Name Description Modal */}
      <ModalBox
        status={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        title=""
        customizedFooter={<></>}
      >
        <div>
          <h5 className="text-center">{showDescription}</h5>
        </div>
      </ModalBox>
    </div>
  );
}

export default NotificationManagement;
