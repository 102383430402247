import React, { useRef, useState } from "react";
import ModalBox from "../../common/ModalBox";
import { editTrainingController } from "../../services/trainingServices/trainingController";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";

const EditTraningModule = ({
  errors,
  editModal,
  setEditModal,
  setErrors,
  trainingModuleList,
  trainingDetail,
  setTrainingDetail,
}) => {
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [thumbnail, setThumbnail] = useState("");

  const validation = () => {
    let error = {};
    let validationStatus = true;
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!trainingDetail.moduleName) {
      error["moduleName"] = "Enter Module Name";
      validationStatus = false;
    }
    if (!trainingDetail.description) {
      error["description"] = "Enter Description";
      validationStatus = false;
    }
    if (!trainingDetail.link) {
      error["link"] = "Enter Link";
      validationStatus = false;
    }
    if(!trainingDetail?.thumbnail){
      if (!thumbnail?.name) {
        if (!validImageTypes.includes(thumbnail.type)) {
          error["thumbnailImage"] =
            "Please upload a valid format (JPEG,PNG,JPG).";
          validationStatus = false;
        }
      }
    }

    setErrors(error);
    return validationStatus;
  };
  const editTrainingModuleHandler = async (e) => {
    e.preventDefault();
    // console.log(trainingDetail, "TrainingDetail");
    const status = validation();
    if (status) {
      try {
        const formData = new FormData();
        formData.append("moduleName", trainingDetail?.moduleName || "");
        formData.append("description", trainingDetail.description || "");
        formData.append("link", trainingDetail.link || "");
        formData.append("thumbnail", thumbnail);
        formData.append("trainingId", trainingDetail?.trainingId || "");
        setSubmitButton(true);
        const response = await editTrainingController(formData);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          trainingModuleList();
          setEditModal(false);
          setSubmitButton(false);
          setErrors({});
        } else {
          toast.error(response?.response?.data?.message || response?.message);
          setEditModal(false);
          setSubmitButton(false);
          setErrors({});
        }
      } catch (e) {
        console.log(e.message);
        setEditModal(false);
        setErrors({});
        setSubmitButton(false);
      }
    }
  };
  const inputRef = useRef();

  return (
    <ModalBox
      status={editModal}
      onClose={() => {
        setEditModal(false);
        setErrors({});
        setTrainingDetail({});
        setThumbnail("");
      }}
      title="Edit Training Module"
      customizedFooter={<></>}
    >
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <form className="forms-sample">
              <div className="form-group">
                <label htmlFor="moduleName">Module Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={30}
                  id="exampleInputName1"
                  value={trainingDetail.moduleName}
                  name="moduleName"
                  placeholder="Enter Name"
                  onChange={(e) =>
                    setTrainingDetail({
                      ...trainingDetail,
                      moduleName: e.target.value.replace(/^\s+/, ""),
                    })
                  }
                />
                {!trainingDetail.moduleName && errors.moduleName && (
                  <span style={{ color: "red" }} className="error">
                    {errors.moduleName}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="mt-2">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  maxLength={200}
                  id="exampleInputName1"
                  value={trainingDetail.description}
                  name="description"
                  placeholder="Write Here..."
                  onChange={(e) =>
                    setTrainingDetail({
                      ...trainingDetail,
                      description: e.target.value.replace(/^\s+/, ""),
                    })
                  }
                />
                {!trainingDetail.description && errors.description && (
                  <span style={{ color: "red" }} className="error">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="link" className="mt-2">
                  Link <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  value={trainingDetail.link}
                  name="link"
                  placeholder="link"
                  onChange={(e) =>
                    setTrainingDetail({
                      ...trainingDetail,
                      link: e.target.value.replace(/^\s+/, ""),
                    })
                  }
                />
                {!trainingDetail.link && errors.link && (
                  <span style={{ color: "red" }} className="error">
                    {errors.link}
                  </span>
                )}
              </div>
              <div class="form-group d-flex justify-content-between align-items-center">
                <label>Thumbnail <span className="text-danger">*</span></label>

                <div class="">
                  <input
                    ref={inputRef}
                    type="file"
                    maxLength={25}
                    class="form-control file-upload-info"
                    hidden
                    placeholder="Upload Image"
                    accept="image/*"
                    onChange={(e) => setThumbnail(e.target.files[0])}
                  />
                  <span class="input-group-append">
                    <button
                      class="file-upload-browse btn btn-gradient-primary"
                      type="button"
                      onClick={() => inputRef.current?.click()}
                    >
                      Upload
                    </button>
                  </span>
                </div>
              </div>
              <span style={{ color: "red" }} className="error">
                {errors.thumbnailImage}
              </span>
              <div className="mb-4">
                {thumbnail?.name ? (
                  <div className="cstm_modal_img_wrap">
                    <img
                      src={URL.createObjectURL(thumbnail)}
                      alt="not found"
                      class="mb-2 mw-100 w-100 rounded"
                    />
                  </div>
                ) : (
                  trainingDetail?.thumbnail !== null && (
                    <div className="cstm_modal_img_wrap">
                      <img
                        src={trainingDetail?.thumbnail}
                        alt="not found"
                        class="mb-2 mw-100 w-100 rounded"
                      />
                    </div>
                  )
                )}
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button
                    type="submit"
                    ref={submitButton}
                    className="btn btn-gradient-primary me-2"
                    onClick={(e) => {
                      editTrainingModuleHandler(e);
                    }}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={(e) => {
                      setEditModal(false);
                      e.preventDefault();
                      setErrors({});
                      setTrainingDetail({});
                      setThumbnail("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    </ModalBox>
  );
};

export default EditTraningModule;
