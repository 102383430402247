import React, { useEffect, useState } from "react";
import { helpAndSupportListController } from "../../services/helpAndSupportServices/helpAndSupportController";
import { Link } from "react-router-dom";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import {
  deleteOfferController,
  offerStatusController,
  offersListingController,
} from "../../services/offersServices/offersManagementController";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";

function OffersManagement() {
  const [offersList, setOffersList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [offerId, setOfferId] = useState(null);
  const [updateFilterCategoryName, setUpdateFilterCategoryName] = useState("");
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const { permission } = usePermission();

  const fetchOffersManagementList = async () => {
    try {
      show();
      const response = await offersListingController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      // console.log(response);
      if (response?.data?.code === 200) {
        hide();
        setOffersList(response?.data?.data?.offers);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  //fetching Filter Category Status API
  const offerStatusHandler = async () => {
    try {
      setSubmitButton(true);
      const response = await offerStatusController(offerId);
      setSubmitButton(false);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setStatusModal(false);
        fetchOffersManagementList();
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  // Delete Offer Handler
  const deleteOfferHandler = async () => {
    try {
      setSubmitButton(true);
      const response = await deleteOfferController(offerId);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setDeleteModal(false);
        setSubmitButton(false);
        fetchOffersManagementList();
      } else {
        toast.error(response?.response?.data?.message);
        setDeleteModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchOffersManagementList();
  }, [debounceValue, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">Offers Management</h3>
              {permissionChecker(permission, 6, 1) && (
                <div className="button_wrap">
                  <Link
                    to="/offers-management/add-new-offers"
                    className="btn btn-btn btn-gradient-primary button"
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i> Add New
                    Offer
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Coupon Code"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Coupon Code</th>
                          <th>Coupon Title</th>
                          <th>Coupon Type</th>
                          <th>Coupon Discount </th>
                          <th>Coupon Amount</th>
                          <th>Coupon Start Date</th>
                          <th>Coupon End Date</th>
                          <th>Offer Type</th>
                          {actionsChecker(permission, 6) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {loader}
                        {offersList && offersList.length > 0
                          ? offersList.map(
                              (
                                {
                                  id,
                                  couponCode,
                                  couponType,
                                  couponTitle,
                                  minAmount,
                                  maxAmount,
                                  startDate,
                                  endDate,
                                  couponInPercent,
                                  couponAmount,
                                  couponDescription,
                                  createdAt,
                                  status,
                                  type,
                                },
                                index
                              ) => {
                                return (
                                  <React.Fragment key={id}>
                                    <tr>
                                      <td>
                                        {(activePage - 1) * limit + ++index}
                                      </td>
                                      <td>{couponCode}</td>
                                      <td>{couponTitle || "---"}</td>
                                      <td>
                                        {couponType === 1
                                          ? "Amount"
                                          : "Percentage"}
                                      </td>
                                      <td>
                                        {couponInPercent
                                          ? `${couponInPercent}%`
                                          : "---"}
                                      </td>
                                      <td>{couponAmount || "---"}</td>
                                      <td>{startDate?showDateFormatYear(
                                          new Date(startDate)
                                        ):"---"}
                                        {/* {showDateFormatYear(
                                          new Date(startDate)
                                        )} */}
                                      </td>
                                      <td>
                                        {endDate?showDateFormatYear(new Date(endDate)):"---"}
                                      </td>
                                      <td>
                                        {type && type===2? "Spin the wheel":"Offer"}
                                      </td>
                                      {actionsChecker(permission, 6) && (
                                        <td>
                                          <div className="action_button_wrap">
                                            {permissionChecker(
                                              permission,
                                              6,
                                              3
                                            ) && (
                                              <Link
                                                to={`/offers-management/view-offer/${id}`}
                                                className="action_button"
                                              >
                                                <VisibilityIcon
                                                  className="cstm_eye"
                                                  color="secondary"
                                                />
                                              </Link>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              6,
                                              5
                                            ) && (
                                              <div className="switch_button">
                                                <FormControlLabel
                                                  control={
                                                    <ToggleStatus
                                                      sx={{ m: 1 }}
                                                      checked={status === 1}
                                                      onClick={() => {
                                                        setStatusModal(true);
                                                        setOfferId(id);
                                                      }}
                                                    />
                                                  }
                                                />
                                              </div>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              6,
                                              2
                                            ) && (
                                              <Link
                                                className="action_button"
                                                to={`/offers-management/edit-offer/${id}`}
                                              >
                                                <i class="mdi mdi-table-edit"></i>
                                              </Link>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              6,
                                              4
                                            ) && (
                                              <Link className="action_button">
                                                <DeleteRoundedIcon
                                                  sx={{ color: red[500] }}
                                                  onClick={() => {
                                                    setOfferId(id);
                                                    setDeleteModal(true);
                                                  }}
                                                />
                                              </Link>
                                            )}
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + offersList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete Offer"
          description={"Are you sure you want to delete this offer?"}
          action={deleteOfferHandler}
          uploadButtonElement={submitButton}
        />

        {/* Modal for Status Change */}
        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title={"Change Status"}
          description="Are you sure you want to change status?"
          action={offerStatusHandler}
          uploadButtonElement={submitButton}
        />
      </div>
    </React.Fragment>
  );
}

export default OffersManagement;
