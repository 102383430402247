import { instance, setJwtToken } from "../../config/axiosInstance";

// Fetching Product List Api
export const offersListingController = async (activePage,limit,search) => {
  const params={};
  params.search=search?search:"";
  const data = await instance.get(`admin/list-offers`,{params ,...setJwtToken()});
  return data;
};

// Add Offer
export const addNewOffersController = async (reqData) => {
  const data = await instance.post(
    `admin/create-offer`,
   reqData,
    setJwtToken()
  );
  return data;
};

// Update Offer 
export const updateOfferController = async (reqData) => {
  const data = await instance.post(
    `admin/edit-offer`,
   reqData,
    setJwtToken()
  );
  return data;
};

// Fetching View Offers API
export const viewOfferController = async (id) => {
  const params={};
  params.id=id?id:"";
  const data = await instance.get(`admin/view-offer`,{params ,...setJwtToken()});
  return data;
};

// Delete Offer API 
export const deleteOfferController = async (id) => {
  // console.log(id);
  const data = await instance.delete(`admin/delete-offer/${id}`, setJwtToken() );
  return data;
};


// {{baseUrl}}admin/status-offer
export const offerStatusController = async (id) => {
  // console.log(id);
  const data = await instance.post(`admin/status-offer/`,{id:id}, setJwtToken() );
  return data;
};


