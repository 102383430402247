import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import Header from "../common/Header";
import LeftSideBar from "../common/LeftSideBar";
import { routes } from "./sidenav";
import Login from "../pages/auth/Login"
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import { usePermission } from "../context/create_Context";
import CMSContentReader from "../pages/CmsContentReader/CMSContentReader";


const Routing = () => {
  const {permission}=usePermission();
  const SidebarLayout = () => (
    <>
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">

          <LeftSideBar />
          <Outlet />
        </div>
      </div>
    </>
  );

  //protected routes
  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem(process.env.REACT_APP_AUTH)) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  };

  return (
    <>
      <Routes>
        <Route element={<SidebarLayout />}>
          {routes &&
            routes(permission).map(({ path, element }) => {
              return (
                  <Route
                    key={path}
                    path={path}
                    // element={<ProtectedRoute>{element}</ProtectedRoute>}
                    element={element}
                  />
              );
            })}
        </Route>

        <Route path="/" element={<Login />} />

        {/* <Route path="/logout" element={<Logout />}></Route> */}
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/content-reader" element={<CMSContentReader />} />
      </Routes>

      {/* <Footer /> */}
    </>
  );
};

export default Routing;