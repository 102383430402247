import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Help and support List Api
export const helpAndSupportListController = async (
  activePage,
  limit,
  search
) => {
  try {
    const params = {};
    params.page = activePage;
    params.limit = limit;
    params.search = search;
    const data  = await instance.get(`admin/help-support`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching Help and Support list.");
    return null;
  }
};

// Delete Help & Support
export const deleteHelpSupportController = async (id) => {
  const data = await instance.delete(`admin/delete-help-support/${id}`,
  setJwtToken(),
  );
  return data;
};
