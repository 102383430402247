import {
    instance,
    multipartJwtToken,
    setJwtToken,
  } from "../../config/axiosInstance";
  
  // Fetching Scheme List Api
  export const orderRequestListController = async (page, limit, search) => {
    const params = {};
    params.page = page || "";
    params.limit = limit || "";
    params.search = search || "";
    const data = await instance.get(`admin/order-help-support`, {params , ...setJwtToken()});
    return data;
  };