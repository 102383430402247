import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ModalBox from "../../common/ModalBox";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  offersListController,
  slicesListController,
  updateGameSliceController,
} from "../../services/gameServices/gameController";
import WinningList from "./WinningList";
import { schemeListController } from "../../services/schemeServices/schemeController";
import useButtonLoader from "../../hooks/useButtonLoader";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const GameRule = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [totalList, setTotalList] = useState(null);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [startNumber, setStartNumber] = useState(1);
  const [slicesData, setSlicesData] = useState([]);
  const [errors, setErrors] = useState("");
  const [amountError, setAmountError] = useState("");
  const navigate = useNavigate();
  const [buttonLoader,setButtonLoader]=useButtonLoader();

  //   Scheme Game Management
  const [schemeModal, setSchemeModal] = useState(false);
  const [schemeId, setSchemeId] = useState(null);
  const [selectSchemeId, setSelectSchemeId] = useState(null);
  const [schemeList, setSchemeList] = useState([]);

  //   Wallet Cash Game Management
  const [walletModal, setWalletModal] = useState(false);
  const [walletAmount, setWalletAmount] = useState(null);
  const [selectWalletId, setSelectWalletId] = useState(null);

  //   Offer Game Management
  const [offerModal, setOfferModal] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const [offersList, setOffersList] = useState([]);

  // Manage All Probability
  const [manageProbabilityModal, setManageProbabilityModal] = useState(false);
  const [manageProbability, setManageProbability] = useState([]);
  const [probabilityErrors, setProbabilityErrors] = useState([]);
  const [showingProbabilityError, setShowingProbabilityError] = useState([]);

  // Fetching Slices List
  const fetchingSlicesList = async () => {
    try {
      const response = await slicesListController();
      if (response?.data?.code === 200) {
        setSlicesData(response?.data?.data);
        setManageProbability(
          response?.data?.data.map(
            ({ winningProbability, id, name }, index) => ({
              id: id,
              winningProbability: winningProbability,
              name: name,
            })
          )
        );
        setShowingProbabilityError(
          response?.data?.data?.map(({ id }) => ({
            id: id,
            error: "",
          }))
        );
        setProbabilityErrors(
          response?.data?.data?.map(({ id }) => ({
            id: id,
            error: "",
          }))
        );
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.error);
    }
  };

  const handleChange = (id) => (event, newExpanded) => {
    setExpanded(newExpanded ? id : false);
  };

  const amountValidation = () => {
    let validationStatus = true;
    if (!walletAmount) {
      setAmountError("Wallet Cash is Mandatory *");
      validationStatus = false;
    }
    return validationStatus;
  };

  // Clear Handler
  const clearHandler = () => {
    setErrors("");
    setOfferId("");
    setSchemeId("");
    setErrors("");
    setErrors("");
    setSelectWalletId("");
    setErrors("");
    setErrors("");
    fetchingSlicesList();
    setErrors("");
  };

  // fetching update slice handler
  const updateSliceHandler = async (type = "") => {
    try {
      if (type === "probability") {
        setButtonLoader(true)
        if (probabilityValidation()) {
          const reqData = manageProbability.map((item) => {
            return {
              winningProbability: Number(item.winningProbability),
              id: item?.id,
            };
          });
          const response = await updateGameSliceController(reqData);
          setButtonLoader(false)
          if (response?.data?.code === 200) {
            toast.success(response?.data?.message);
            setManageProbabilityModal(false);
            fetchingSlicesList();
            return false;
          } else {
            toast.error(response?.data?.message);
            setErrors("");
          }
        }
      }

      // Handling Prizes
      if (type === "offer") {
        setButtonLoader(true)
        const data = [{ couponCode: offerId, id: selectSchemeId }];

        const response = await updateGameSliceController(data);
        setButtonLoader(false);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          fetchingSlicesList();
          setOfferId("");
          setOfferModal(false);
          setSelectSchemeId("");
        } else {
          toast.error(response?.data?.message);
          setOfferId("");
          setOfferModal(false);
          setSelectSchemeId("");
        }
      }
      if (type === "scheme") {
        setButtonLoader(true)
        const data = [{ schemeId: schemeId, id: selectSchemeId }];

        const response = await updateGameSliceController(data);
        setButtonLoader(false)
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setSchemeId("");
          setSelectSchemeId("");
          setSchemeModal(false);
          fetchingSlicesList();
        } else {
          toast.error(response?.data?.message);
          setSelectSchemeId("");
          setSchemeId("");
          setSchemeModal(false);
        }
      }

      //  wallet cash
      if (type === "wallet") {
        const data = [{ prize: walletAmount, id: selectWalletId }];
        if (amountValidation()) {
          setButtonLoader(true)
          const response = await updateGameSliceController(data);
          if (response?.data?.code === 200) {
            setButtonLoader(false)
            toast.success(response?.data?.message);
            setWalletAmount("");
            setWalletModal(false);
            setSelectWalletId("");
            setAmountError("")
            fetchingSlicesList();
            
          } else {
            toast.error(response?.data?.message);
            setWalletAmount("");
            setWalletModal(false);
            setAmountError("")
            setSelectWalletId("");
          }
        }
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
      clearHandler();
      setAmountError("")
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  const schemeHandler = async (id) => {
    setSchemeModal(true);
    setSelectSchemeId(id);
    try {
      const response = await schemeListController();
      // console.log(response, "Response");
      if (response?.data?.code === 200) {
        setSchemeList(
          response?.data?.data?.scheme.filter((item) => item.isSpin === 2)
        );
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Offer Handler
  const offerHandler = async (id) => {
    setOfferModal(true);
    setSelectSchemeId(id);
    try {
      const response = await offersListController();
      // console.log(response, "Response Offers");
      if (response?.data?.code === 200) {
        setOffersList(response?.data?.data?.offers);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Handling Winning Probability Validation
  const probabilityValidation = () => {
    const validateData = [...manageProbability];
    let validationStatus = true;
    const updatedErrorData = [...probabilityErrors];

    validateData.forEach((item, index) => {
      if (!item?.winningProbability) {
        updatedErrorData[index] = {
          ...updatedErrorData[index],
          error: "Required *",
        };
        validationStatus = false;
      }
    });

    setProbabilityErrors(updatedErrorData);
    return validationStatus;
  };

  // Total Probability Winning
  const totalWinningProbability = () => {
    return 100;
  };

  // Winning Probability Handler
  const winningProbabilityHandler = (e, id) => {
    let totalProbab = totalWinningProbability();
    const updatedProbabData = [...manageProbability];
    const { name, value } = e.target;
    const particularData = updatedProbabData.find((item) => item.id === id);

    if (particularData) {
      const updatedItem = {
        ...particularData,
        winningProbability: value,
      };
      const index = updatedProbabData.findIndex((item) => item.id === id);
      updatedProbabData[index] = updatedItem;
      const checkProbabSum = updatedProbabData.reduce((acc, item) => {
        return (acc += Number(item?.winningProbability));
      }, 0);
      if (checkProbabSum > totalProbab) {
        setShowingProbabilityError((prevErrors) => {
          const newErrors = [...prevErrors];
          if (newErrors[index]) {
            newErrors[index] = {
              ...newErrors[index],
              error: "Winning Probability Should Not Exceed 100 %",
            };
          }
          return newErrors;
        });
      } else {
        setManageProbability(updatedProbabData);
        setShowingProbabilityError((prevErrors) => {
          const newErrors = [...prevErrors];
          if (newErrors[index]) {
            newErrors[index] = { ...newErrors[index], error: "" };
          }
          return newErrors;
        });
      }
    }
  };

  useEffect(() => {
    fetchingSlicesList();
  }, []);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <h2 className="page-title">
            <span
              className="page-title-icon bg-gradient-primary text-white me-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="mdi mdi-chevron-double-left"></i>
            </span>
            Manage Prizes & Probability
          </h2>
          <nav aria-label="breadcrumb"></nav>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="button_wrap text-end">
              <Link
                to="#"
                className="btn btn-outline-info cstm_btn_wrap_theme mb-4"
                onClick={() => setManageProbabilityModal(true)}
              >
                <i className="mdi mdi-plus menu-icon menu-icon"></i>Manage
                Winning Probability
              </Link>
            </div>
            <div>
              {/* Accoridan For Offer Code */}
              <Accordion
                expanded={expanded === slicesData[0]?.id}
                onChange={handleChange(slicesData[0]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[0]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <button
                        className="btn btn-gradient-primary"
                        onClick={() => {
                          offerHandler(slicesData[0]?.id);
                        }}
                      >
                        Set Offer
                      </button>
                      <div></div>
                    </div>
                    <div className="text-start mt-3">
                      <span>Offer Code : </span>
                      <span className="px-2">
                        {slicesData[0]?.offerDetails?.couponCode}
                      </span>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Accordian For Scheme  */}
              <Accordion
                expanded={expanded === slicesData[2]?.id}
                onChange={handleChange(slicesData[2]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[2]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <button
                        className="btn btn-gradient-primary"
                        onClick={() => {
                          schemeHandler(slicesData[2]?.id);
                        }}
                      >
                        Set Scheme
                      </button>
                      <div></div>
                    </div>
                    <div className="text-start mt-3">
                      <span>Scheme Name : </span>
                      <span className="px-2">
                        {slicesData[2]?.schemeDetails?.schemeName}
                      </span>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Better Luck Next Time */}
              <Accordion
                expanded={expanded === slicesData[1]?.id}
                onChange={handleChange(slicesData[1]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[1]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography></Typography>
                </AccordionDetails>
              </Accordion>

              {/* 1 Extra Spin */}
              <Accordion
                expanded={expanded === slicesData[3]?.id}
                onChange={handleChange(slicesData[3]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[3]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography></Typography>
                </AccordionDetails>
              </Accordion>

              {/* Wallet Cash */}
              <Accordion
                expanded={expanded === slicesData[4]?.id}
                onChange={handleChange(slicesData[4]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[4]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex">
                      <button
                        className="btn btn-gradient-primary"
                        onClick={() => {
                          setWalletModal(true);
                          setSelectWalletId(slicesData[4]?.id);
                        }}
                      >
                        Set Wallet Cash
                      </button>
                      <div></div>
                    </div>
                    <div className="text-start mt-3">
                      <span>Wallet Amount : </span>
                      <span className="px-2">{slicesData[4]?.prize}</span>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Accordian 6 */}
              <Accordion
                expanded={expanded === slicesData[5]?.id}
                onChange={handleChange(slicesData[5]?.id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{slicesData[5]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography></Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <WinningList />
      </div>

      {/* Showing Modal For Adding Offers */}
      <ModalBox
        status={offerModal}
        onClose={() => {
          setOfferModal(false);
          setOfferId("");
        }}
        title=""
        centered={true}
        size={"lg"}
        customizedFooter={<></>}
      >
        <div className="row wrap_selectCstm">
          <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Coupon Code</th>
                        <th>Coupon Title</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {offersList
                        ? offersList.map(
                            (
                              {
                                id,
                                couponCode,
                                couponType,
                                couponTitle,
                                minAmount,
                                maxAmount,
                                startDate,
                                endDate,
                                couponInPercent,
                                couponAmount,
                                couponDescription,
                                createdAt,
                                status,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>{couponCode}</td>
                                    <td>{couponTitle}</td>
                                    <td>
                                      <div className="action_button_wrap">
                                        <Link
                                          className="action_button"
                                          to="#"
                                          onClick={() => {
                                            setOfferId(id);
                                          }}
                                        >
                                          {offerId === id ? (
                                            "Added"
                                          ) : (
                                            <i class="mdi mdi-plus"></i>
                                          )}
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
                    <h5>
                      Showing {startNumber} to{" "}
                      {startNumber + offersList?.length - 1} of {totalList}{" "}
                      entries
                    </h5>
                  </div>
                  {totalList > 10 ? (
                    <div className="pagination_wrap">
                      <Pagination
                        activePage={activePage}
                        linkClass="page-link"
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalList}
                        pageRangeDisplayed={5}
                        itemClassLast="last_page next"
                        itemClassFirst="first_page prev"
                        activeLinkClass="active"
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : null}
                  <div className="align-center">
                    {" "}
                    <button
                      className=" btn btn-gradient-primary"
                      onClick={() => updateSliceHandler("offer")}
                      ref={buttonLoader}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>

      {/* Showing Modal for Adding Scheme */}
      <ModalBox
        status={schemeModal}
        onClose={() => {
          setSchemeModal(false);
          setSchemeId("");
        }}
        title=""
        centered={true}
        size={"lg"}
        customizedFooter={<></>}
      >
        <div className="row wrap_selectCstm">
          <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Scheme Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schemeList
                        ? schemeList.map(
                            (
                              {
                                id,
                                schemeName,
                                couponType,
                                couponTitle,
                                minAmount,
                                maxAmount,
                                startDate,
                                endDate,
                                couponInPercent,
                                couponAmount,
                                couponDescription,
                                createdAt,
                                status,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>{schemeName}</td>
                                    <td>
                                      <div className="action_button_wrap">
                                        <Link
                                          className="action_button"
                                          to="#"
                                          onClick={() => {
                                            setSchemeId(id);
                                          }}
                                        >
                                          {schemeId === id ? (
                                            "Added"
                                          ) : (
                                            <i class="mdi mdi-plus"></i>
                                          )}
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
                    <h5>
                      Showing {startNumber} to{" "}
                      {startNumber + schemeList?.length - 1} of {totalList}{" "}
                      entries
                    </h5>
                  </div>
                  {totalList > 10 ? (
                    <div className="pagination_wrap">
                      <Pagination
                        activePage={activePage}
                        linkClass="page-link"
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalList}
                        pageRangeDisplayed={5}
                        itemClassLast="last_page next"
                        itemClassFirst="first_page prev"
                        activeLinkClass="active"
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : null}
                  <div className="align-center">
                    {" "}
                    <button
                      className="btn btn-gradient-primary"
                      onClick={() => updateSliceHandler("scheme")}
                      ref={buttonLoader}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>

      {/* Showing Modal for Adding Wallet Amount  */}
      <ModalBox
        status={walletModal}
        onClose={() => {setWalletModal(false);
        setAmountError("");
        }}
        title=""
        centered={true}
        size={"md"}
        customizedFooter={<></>}
      >
        <label className="mb-2">Enter Wallet Cash:</label>
        <input
          type="text"
          className="form-control"
          value={walletAmount}
          onChange={(e) =>
            setWalletAmount(e.target.value.replace(/[^0-9.]/g, ""))
          }
        />
        {!walletAmount && amountError && (
          <span style={{ color: "red" }} className="error">
            {amountError}
          </span>
        )}

        <div className="d-flex justify-content-around mt-3">
          <button
            className="btn btn-gradient-primary"
            onClick={() => updateSliceHandler("wallet")}
            ref={buttonLoader}
          >
            Save
          </button>
        </div>
      </ModalBox>

      {/* Manage All Probability MOdal */}
      <ModalBox
        status={manageProbabilityModal}
        onClose={() => {
          setManageProbabilityModal(false);
          setProbabilityErrors([]);
        }}
        title="Manage Winning Probability"
        centered={true}
        size={"md"}
        customizedFooter={<></>}
      >
        <React.Fragment>
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                {manageProbability && manageProbability.length > 0
                  ? manageProbability.map(
                      ({ id, name, winningProbability }, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="form-group">
                              <label htmlFor="moduleName">{`${name} winning Probability`}</label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength={3}
                                id="exampleInputName1"
                                value={winningProbability}
                                name={id}
                                placeholder="Enter Probability"
                                onChange={(e) =>
                                  winningProbabilityHandler(e, id)
                                }
                              />
                              {showingProbabilityError[index]?.error !== "" && (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {showingProbabilityError[index]?.error}
                                </span>
                              )}
                              {
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {probabilityErrors[index]?.error}
                                </span>
                              }
                            </div>
                          </React.Fragment>
                        );
                      }
                    )
                  : null}

                {manageProbability.reduce((acc, item) => {
                  return acc + Number(item?.winningProbability || 0);
                }, 0) === 100 && (
                  <div className="form-group">
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <button
                        type="submit"
                        ref={buttonLoader}
                        className="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          updateSliceHandler("probability");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </React.Fragment>
      </ModalBox>
    </div>
  );
};

export default GameRule;
