import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Home Configuration List Api
export const homeConfigurationListController = async (
  activePage,
  limit,
  search,
  filterByDate
) => {
  const data = await instance.get(`admin/home-config-list`, setJwtToken());
  return data;
};

// Fetching Quick Menus Api
export const QuickMenusListController = async (
  activePage,
  limit,
  search,
  filterByDate
) => {
  const data = await instance.get(`admin/quik-menu-list`, setJwtToken());
  return data;
};

//   Admin Home Drag and Drop API
export const homeDragDropController = async (originalShiftId, newShiftId) => {
  const reqData = {
    originalShiftId: originalShiftId,
    newShiftId: newShiftId,
  };

  const data = await instance.post("admin/home", reqData, setJwtToken());
  return data;
};

//   Admin Quick Menus Drag and Drop API
export const quickMenusDragDropController = async (
  originalShiftId,
  newShiftId
) => {
  const reqData = {
    originalShiftId: originalShiftId,
    newShiftId: newShiftId,
  };

  const data = await instance.post("admin/quik-menus", reqData, setJwtToken());
  return data;
};

// Change Home Status API
export const homeStatusController = async (id) => {
  const data = await instance.post(
    `admin/home/update-status`,
    { id: id },
    setJwtToken()
  );
  return data;
};

// Change Quick Menus Status API
export const quickMenusStatusController = async (id) => {
  const data = await instance.post(
    `admin/quik-menus/update-quik-menus`,
    { id: id },
    setJwtToken()
  );
  return data;
};

// Category Listing
// {{baseUrl}}api/view-all-filtered-products?categoryId=2&search=Bita&page=1
export const cat_1_ListController = async (
  categoryId,
  activePage,
  limit,
  search
) => {
  const params = {
    categoryId: categoryId,
    page: activePage ? activePage : "",
    limit: limit || 10,
    search: search || "",
  };
  const data = await instance.get(`http://13.235.137.221:3005/api/view-all-filtered-products`, {
    params,
    ...setJwtToken(),
  });
  return data;
};


