import logo from "./logo.svg";
import "./App.scss";
import Dashboard from "./pages/dashboard/Dashboard";
import Charts from "./pages/charts/Charts";
import Header from "./common/Header";
import LeftSideBar from "./common/LeftSideBar";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes";
import { HashRouter } from "react-router-dom";
import { AxiosInterceptor } from "./config/axiosInstance";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <AxiosInterceptor>
          <Routing />
        <ToastContainer />
      </AxiosInterceptor>
    </BrowserRouter>
    </div>
  );
}

export default App;
