import React, { useEffect, useLayoutEffect, useState } from "react";
import { CmsDetailsController } from "../../services/cmsServices/CMSController";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import SuperTag from "../../common/SuperTag";

const CMSContentReader = () => {
  const [type, setType] = useState(1);
  const [cmsData, setCmsData] = useState("");
  const [errors, setErrors] = useState("");

  const [searchParam, setSearchParam] = useSearchParams();
  const contentType = searchParam.get("type"); //basic type about | terms | privacy

  const fetchingCmsDetail = async (url) => {
    if (contentType) {
      try {
        // let url=`http://13.235.137.221:3005/api/`
        let url = `${process.env.REACT_APP_API_URL}/api/`;
        // console.log(url);

        if (contentType === "about") {
          url += "about-us";
        }
        if (contentType === "terms") {
          url += "term-condition";
        }
        if (contentType === "privacy") {
          url += "privacy-policy";
        }

        const response = await axios.get(url);
        if (response?.data?.code === 200) {
          setCmsData(response?.data?.data?.description || "");
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  useLayoutEffect(() => {
    fetchingCmsDetail();
  }, []);

  if (!contentType) {
    return <p>Content Type is required</p>;
  }

  return <SuperTag>{cmsData}</SuperTag>;
};

export default CMSContentReader;
