import React, { useEffect, useState } from "react";
import {
  deleteHelpSupportController,
  helpAndSupportListController,
} from "../../services/helpAndSupportServices/helpAndSupportController";
import { Link } from "react-router-dom";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import {
  addFilterCategoryNameController,
  deleteFilterCategoryNameController,
  editFilterCategoryNameController,
  filterCategoryListController,
} from "../../services/filterCategoryServices/filterCategoryController";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";

function HelpAndSupport() {
  const [helpSupportList, setHelpSupportList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [filterCategoryId, setFilterCategoryId] = useState("");
  const [updateFilterCategoryName, setUpdateFilterCategoryName] = useState("");
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const { permission } = usePermission();
  const [helpSupportId, setHelpSupportId] = useState(null);
  const fetchHelpSupportList = async () => {
    try {
      show();
      const response = await helpAndSupportListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      // console.log(response);
      hide();
      if (response?.code === 200) {
        setHelpSupportList(response?.data?.data);
        setTotalList(response?.data?.count);
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  const deleteHelpSupportHandler = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      const response = await deleteHelpSupportController(helpSupportId);
      if (response?.data?.code === 200) {
        setDeleteModal(false);
        toast.success(response.data?.message);
        fetchHelpSupportList();
        setButtonLoader(false);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setDeleteModal(false);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchHelpSupportList();
  }, [debounceValue, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Help & Support Management </h3>
              <div className="button_wrap">
                {/* <Link
                  onClick={() => setAddModal(true)}
                  className="btn btn-btn btn-gradient-primary button"
                >
                  <i className="mdi mdi-plus menu-icon menu-icon"></i> Add
                  Filter Category
                </Link> */}
              </div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by name or email address"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Business Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Image</th>
                          <th>Reported On</th>
                          <th>Message</th>
                          {actionsChecker(permission, 23) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {helpSupportList && helpSupportList.length > 0
                          ? helpSupportList.map(
                              (
                                {
                                  id,
                                  name,
                                  email,
                                  createdAt,
                                  bussinessName,
                                  status,
                                  updatedAt,
                                  userId,
                                  description,
                                  phone,
                                  image,
                                },
                                index
                              ) => {
                                return (
                                  <React.Fragment key={id}>
                                    <tr>
                                      <td>
                                        {(activePage - 1) * limit + ++index}
                                      </td>
                                      <td>{name ? name : "---"}</td>
                                      <td>
                                        {bussinessName ? bussinessName : "---"}
                                      </td>
                                      <td>{email ? email : "---"}</td>
                                      <td>{phone ? phone : "---"}</td>
                                      <td>
                                        <img
                                          src={
                                            image
                                              ? image
                                              : "./assets/images/user.png"
                                          }
                                        />
                                      </td>
                                      <td>{showDateFormatYear(createdAt)}</td>
                                      {/* <td>{description}</td> */}
                                      <td className="Cstm_see_description">
                                        {description ? (
                                          description.length > 30 ? (
                                            <>
                                              {description.slice(0, 30)}{" "}
                                              <Link
                                                onClick={() => {
                                                  setShowDescription(
                                                    description
                                                  );
                                                  setDescriptionModal(true);
                                                }}
                                                to="#"
                                              >
                                                see more
                                              </Link>
                                            </>
                                          ) : (
                                            description
                                          )
                                        ) : (
                                          "---"
                                        )}
                                      </td>
                                      {/* <td>
                                      <div className="action_button_wrap">
                                        <Link
                                          to={`/help-support/view/${id}`}
                                          className="action_button"
                                        >
                                          <VisibilityIcon color="secondary" disabled />
                                        </Link>
                                      </div>
                                    </td> */}
                                      <td>
                                        {permissionChecker(
                                          permission,
                                          23,
                                          4
                                        ) && (
                                          <Link className="action_button">
                                            <DeleteRoundedIcon
                                              sx={{ color: red[500] }}
                                              onClick={() => {
                                                setHelpSupportId(id);
                                                setDeleteModal(true);
                                              }}
                                            />
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + helpSupportList?.length - 1} of{" "}
                        {totalList} entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Category Name Description Modal */}
        <ModalBox
          status={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          title=""
          customizedFooter={<></>}
        >
          <div>
            <h5 className="text-center">{showDescription}</h5>
          </div>
        </ModalBox>

        {/* Delete Modal */}
        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete"
          description={"Are you sure you want to delete?"}
          action={deleteHelpSupportHandler}
          uploadButtonElement={buttonLoader}
        />
      </div>
    </React.Fragment>
  );
}

export default HelpAndSupport;
