import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Vendor List  Api
export const vendorListController = async (page, limit, search) => {
  const params = {
    page: page,
    limit: limit,
    search: search,
  };
  const data = await instance.get(`admin/list-vendor`, {
    params,
    ...setJwtToken(),
  });
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// admin/edit-vendo

// Add Vendor
export const insertVendorController = async (reqData) => {
  const data = await instance.post(
    `admin/insert-vendor`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// Edit Vendor
export const editVendorController = async (reqData) => {
  const data = await instance.post(
    `admin/edit-vendor`,
    reqData,
    multipartJwtToken()
  );
  return data;
};

// View Vendor Details
export const viewVendorController = async (id) => {
  const data = await instance.get(
    `admin/detail-vendor?id=${id}`,
    setJwtToken()
  );
  return data;
};
