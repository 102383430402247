import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Product List Api
export const productListController = async (
  activePage,
  limit,
  search,
  date
) => {
  const params = {};
  params.search = search;
  params.page = activePage ? activePage : "";
  params.limit = limit ? limit : "";
  params.date = date ? date : "";
  const data = await instance.get(`admin/product-list`, {
    params,
    ...setJwtToken(),
  });
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching  Add Product  Api
export const addProductController = async (reqData) => {
  const data = await instance.post(
    `admin/create-product`,
    reqData,
    multipartJwtToken()
  );
  if (data?.data?.code) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Change Product Status API
export const changeProductStatusController = async (id) => {
  const data = await instance.post(
    `admin/update-product-status`,
    { id: id },
    setJwtToken()
  );
  if (data?.data?.code) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching  Update Product  Api
export const updateProductController = async (reqData) => {
  const data = await instance.post(
    `admin/update-product`,
    reqData,
    multipartJwtToken()
  );
  if (data.data.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching Delete Images That Comes In Edit Product API

export const deleteProductImageController = async (id, imageId) => {
  const data = await instance.post(
    `admin/delete-product-image`,
    { productId: id, productImgId: imageId },
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching Product Details  Api
export const productDetailsController = async (id) => {
  const data = await instance.get(
    `admin/product-detail?productZohoId=${id}`,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};
