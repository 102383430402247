import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import ModalBox from "../../common/ModalBox";
import { toast } from "react-toastify";
import {
  notificationListController,
  sendNotificationController,
  userListingController,
} from "../../services/notificationServices/notificationController";
import Pagination from "react-js-pagination";
import { MultiSelect } from "react-multi-select-component";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {
  dateFormatYear,
  fullTimeFormat,
  showDateFormatYear,
  showFullTimeFormat,
} from "../../utility/utils";
import { Link, useNavigate } from "react-router-dom";
import useButtonLoader from "../../hooks/useButtonLoader";

function AddNotification() {
  const [selectRetailer, setSelectRetailer] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [notificationList, setNotificationList] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectTime, setSelectTime] = useState({});
  const [userList, setUserList] = useState([]);
  const [textValue, setTextValue] = useState({
    title: "",
    message: "",
    type: "1",
  });
  const navigate = useNavigate();

  // For setting minimum time in Timepicker
  const currentTime = dayjs().startOf("minute");

  //   Select Retailer for Notification
  const notifySelectionHandler = (e) => {
    const { value } = e.target;
    setTextValue({ ...textValue, type: value });
  };

  // console.log(textValue.type, "-------Type");

  //   Schedule Date Handler
  const scheduleDateHandler = (date) => {
    // console.log(date, "date-->");
    setTextValue({ ...textValue, scheduleDate: date });
  };

  //   Scheduled Time Handler
  const scheduleTimeHandler = (date) => {
    setSelectTime(date);
  };

  //Fetching Retailer List
  const fetchingUserList = async () => {
    try {
      const response = await userListingController();
      if (response.data.code === 200) {
        setUserList(response?.data?.data);
      } else {
        toast.error(response?.response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  //   Options for Multi Select
  const options = userList.map(({ retailerName, id }) => ({
    label: retailerName,
    value: id,
  }));

  // Validation Function
  const nonSchedulerValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!textValue.title) {
      error["title"] = "Title Field is mandatory";
      validationStatus = false;
    }
    if (selectRetailer.length <= 0) {
      error["selectRetailer"] = "Please select retailer*";
      validationStatus = false;
    }
    if (!textValue.message) {
      error["message"] = "Description is mandatory";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Validation Function
  const schedulerValidation = () => {
    let error = {};
    let validationStatus = true;
    if (!textValue.title) {
      error["title"] = "Title Field is mandatory*";
      validationStatus = false;
    }
    if (selectRetailer.length <= 0) {
      error["selectRetailer"] = "Please select retailer*";
      validationStatus = false;
    }
    if (!textValue.message) {
      error["message"] = "Description is mandatory*";
      validationStatus = false;
    }
    if (!textValue.scheduleDate) {
      error["scheduleDate"] = "Date is mandatory*";
      validationStatus = false;
    }
    if (!selectTime.$d) {
      error["scheduleTime"] = "Time is mandatory*";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  //   Send Notification Handler
  const sendNotificationHandler = async (key = "") => {
    try {
      let data = {};
      let selectedUserdata = [];
      if (Number(textValue.type) === 1) {
        if (key === "notScheduler") {
          data = {
            title: textValue.title,
            message: textValue.message,
            type: Number(textValue.type),
          };
        } else {
          data = {
            title: textValue.title,
            message: textValue.message,
            type: Number(textValue.type),
            scheduleDate: dateFormatYear(textValue.scheduleDate),
            scheduleTime: fullTimeFormat(selectTime.$d),
          };
        }
      } else {
        if (key === "notScheduler") {
          selectedUserdata = {
            title: textValue.title,
            message: textValue.message,
            type: Number(textValue.type),
            usersId: selectRetailer.map(({ value }) => value),
          };
        } else {
          selectedUserdata = {
            title: textValue.title,
            message: textValue.message,
            type: Number(textValue.type),
            scheduleDate: dateFormatYear(textValue.scheduleDate),
            scheduleTime: fullTimeFormat(selectTime.$d),
            usersId: selectRetailer.map(({ value }) => value),
          };
        }
      }

      if (key === "notScheduler") {
        let status = nonSchedulerValidation();
        if (status) {
          setSubmitButton(true);
          const response = await sendNotificationController(
            Number(textValue.type) === 1 ? data : selectedUserdata
          );
          if (response?.data?.code === 200) {
            // console.log(response);
            toast.success(response?.data?.message);
            navigate(-1);
            setSubmitButton(false);
            setSelectTime({});
            setSelectRetailer([]);
            setTextValue({
              type: "1",
              title: "",
              message: "",
            });
          } else {
            toast.error(response?.response?.data?.message);
            setSubmitButton(false);
          }
        }
      }
      if (key !== "notScheduler") {
        let status = schedulerValidation();
        if (status) {
          setSubmitButton(true);
          const response = await sendNotificationController(
            Number(textValue.type) === 1 ? data : selectedUserdata
          );
          if (response?.data?.code === 200) {
            // console.log(response);
            toast.success(response?.data?.message);
            navigate(-1);
            setSubmitButton(false);
            setSelectTime({});
            setSelectRetailer([]);
            setTextValue({
              type: "1",
              title: "",
              message: "",
            });
          } else {
            toast.error(response?.response?.data?.message);
            setSubmitButton(false);
          }
        }
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  useEffect(() => {
    fetchingUserList();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="page_title_wrap">
            <h3 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Add Notification
            </h3>
            <div className="button_wrap"></div>
          </div>
        </div>

        <div className="row wrap_selectCstm my-4">
          <div className="col-1 cstm_padding_set"></div>
          <div className="col-lg-4 cstm_padding_set">
            <div className="search_box"></div>
          </div>
        </div>
        <div className="row wrap_selectCstm">
          <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <RadioGroup
                      name="type"
                      sx={{ textAlign: "left" }}
                      value={textValue.type}
                      onChange={notifySelectionHandler}
                    >
                      <p>
                        <b>Type</b>
                      </p>
                      <div className="wraplabel">
                        <FormControlLabel
                          value="1"
                          label="For All"
                          checked={textValue.type === "1"}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="2"
                          label="Select Retailer"
                          checked={textValue.type === "2"}
                          control={<Radio />}
                          // onClick={() => {
                          //   setRetailerModal(true);
                          // }}
                        />
                      </div>
                    </RadioGroup>
                  </div>
                </div>
                {textValue.type === "2" && (
                  <div className="row">
                    <div className="col-lg-10 col-md-10 text-start">
                      <React.Fragment>
                        <MultiSelect
                          options={options}
                          value={selectRetailer}
                          onChange={setSelectRetailer}
                          labelledBy="Select"
                          // className="mb-4"
                        />
                        {errors.selectRetailer && (
                          <span style={{ color: "red" }} className="error">
                            {errors.selectRetailer}
                          </span>
                        )}
                      </React.Fragment>
                    </div>
                  </div>
                )}
                <div
                  className={`notification_btn ${
                    errors.scheduleDate ? "cstm_dateAlign" : ""
                  }`}
                >
                  <div className="row mb-3 mt-3 align-center">
                    <div className="col-5 d-flex align-center">
                      <div className="col-4 text-start">
                        <h5>
                          Schedule : <span>Send On</span>
                        </h5>
                      </div>
                      <div className="col-8 text-start">
                        <div className="react-datepicker-wrapper_retailer">
                          <DatePicker
                            placeholderText="Enter Date"
                            className="form-control"
                            showYearDropdown
                            selected={textValue.scheduleDate}
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            minDate={Date.now()}
                            dateFormat="dd/MM/yyyy"
                            onChange={scheduleDateHandler}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                          {errors.scheduleDate && (
                            <p style={{ color: "red" }} className="error">
                              {errors.scheduleDate}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3 text-start">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          className={"form-control"}
                          ampm={false}
                          minTime={
                            textValue.scheduleDate &&
                            dateFormatYear(textValue.scheduleDate) >
                              dateFormatYear(Date.now())
                              ? null
                              : currentTime
                          }
                          views={["hours", "minutes", "seconds"]}
                          value={selectTime}
                          onChange={(time) => {
                            scheduleTimeHandler(time);
                          }}
                        />
                      </LocalizationProvider>
                      {errors.scheduleTime && (
                        <p style={{ color: "red" }} className="error">
                          {errors.scheduleTime}
                        </p>
                      )}
                    </div>
                    <div className="col-2 text-end">
                      <button
                        className="btn btn-btn btn-gradient-dark button"
                        onClick={sendNotificationHandler}
                        ref={submitButton}
                      >
                        Add Schedule
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group row">
                        <div className="col-sm-12 text-start">
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Enter Title"
                            value={textValue.title}
                            onChange={(e) => {
                              setTextValue({
                                ...textValue,
                                title: e.target.value,
                              });
                            }}
                          />
                          {errors.title && (
                            <span style={{ color: "red" }} className="error">
                              {errors.title}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group row">
                        <div className="col-sm-12 text-start">
                          <textarea
                            type="text"
                            className="form-control"
                            name="message"
                            placeholder="Write Here..."
                            rows="10"
                            value={textValue.message}
                            onChange={(e) => {
                              setTextValue({
                                ...textValue,
                                message: e.target.value,
                              });
                            }}
                          />
                          {errors.message && (
                            <span style={{ color: "red" }} className="error">
                              {errors.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 text-end">
                      <button
                        className="btn btn-gradient-dark"
                        onClick={() => {
                          sendNotificationHandler("notScheduler");
                        }}
                        ref={submitButton}
                      >
                        Send Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotification;
