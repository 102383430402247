import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { viewVendorController } from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import { Input } from "@mui/material";

const ViewVendor = () => {
  const [inputFieldValue, setInputFieldValue] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  // View Vendor Details
  const viewVendorDetails = async () => {
    try {
      const response = await viewVendorController(id);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setInputFieldValue(response?.data?.data);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    viewVendorDetails();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              View Supplier
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Name 
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              disabled
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Name"
                              name="name"
                              value={inputFieldValue.name}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Email
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              disabled
                              maxLength={25}
                              placeholder="Enter Email"
                              className="form-control"
                              name="email"
                              value={inputFieldValue.email}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Enter Mobile Number
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={10}
                              name="mobileNumber"
                              disabled
                              placeholder="Enter Mobile Number"
                              value={inputFieldValue.mobileNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-7 text-start">
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              placeholder="Enter Address"
                              value={inputFieldValue.address}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Facility Details
                          </label>
                          <div className="col-sm-7 text-start">
                            <textarea
                              type="text"
                              disabled
                              className="form-control"
                              name="facilityDetails"
                              placeholder="Enter Facility Details"
                              value={inputFieldValue.facilityDetails}
                            />
                          </div>
                        </div>
                      </div>
                      </div>

                      {inputFieldValue?.certificate && (
                        <div className="row cstm_image_vendor">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Certificate
                            </label>
                            <div className="col-sm-10">
                              <div className="cstm_modal_img_wrap">
                                <img
                                  src={inputFieldValue?.certificate}
                                  alt=""
                                  class="mb-2 mw-100 w-100 rounded"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewVendor;
