import React from "react";

const containerStyle={
  padding: "15px",
  overflow: "auto",
  width: "100%",
  maxWidth: "100%",
}

const SuperTag = ({ children, ...rest }) => {
  // set description in to inner html
  const support = (() => {
    if (!window.DOMParser) return false;
    let parser = new DOMParser();
    try {
      parser.parseFromString("x", "text/html");
    } catch (err) {
      return false;
    }
    return true;
  })();

  const textToHTML = (str) => {
    // check for DOMParser support
    if (support) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(str, "text/html");
      return doc.body.innerHTML;
    }

    // Otherwise, create div and append HTML
    let dom = document.createElement("p");
    dom.innerHTML = str;
    return dom;
  };
  return (
    <div
      className="superTag" 
      style={containerStyle}
      dangerouslySetInnerHTML={{ __html: textToHTML(children) }}
      {...rest}
    />
  );
};

export default SuperTag;
