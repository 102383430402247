import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";


// Fetching Scheme List Api
export const productCategoryListController = async (id) => {
    const data = await instance.get(
      `admin/products-list?categoryId=${id}`,
      setJwtToken()
    );
    return data;
  };


// Fetching Manage Category Products  Api
export const manageCategoryProductsController = async (reqData) => {
  const data = await instance.post(
    `admin/category-with-products`,
    reqData,
    setJwtToken()
  );
  return data;
};
