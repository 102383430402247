import React, { useEffect, useState } from "react";
import { Link, useSubmit } from "react-router-dom";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel, IconButton } from "@mui/material";
import {
  addFilterCategoryNameController,
  deleteFilterCategoryNameController,
  editFilterCategoryNameController,
  filterCategoryListController,
  filterCategoryStatusController,
  manageHomeConfigController,
} from "../../services/filterCategoryServices/filterCategoryController";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import { homeConfigurationListController } from "../../services/homeConfigurationServices/homeConfigurationController";

function FilterCategoryManagement() {
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [homeConfigList, setHomeConfigList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [filterId, setFilterId] = useState();
  const [categoryId, setCategoryId] = useState(null);
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [filterCategoryId, setFilterCategoryId] = useState("");
  const [updateFilterCategoryName, setUpdateFilterCategoryName] = useState("");
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [manageHomeStatus, setManageHomeStatus] = useState(false);
  const { permission } = usePermission();
  const [activeOnHome,setActiveOnHome]=useState(null);

  // Fetching Filter Category List API
  const filterCategoryListing = async () => {
    try {
      show();
      const response = await filterCategoryListController(
        search,
        activePage,
        limit
      );
      if (response?.code === 200) {
        setFilterList(response?.data?.rows);
        setTotalList(response?.data?.count);
        hide();
      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Fetching Home Configuration List
  const fetchingHomeConfig = async () => {
    try {
      const response = await homeConfigurationListController();
      if (response.data.code === 200) {
        setHomeConfigList(
          response?.data?.data.filter((item) => item.categoryId !== 0)
        );
      } else {
        toast.error(response.response.data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // For Adding Category Name
  const inputFieldValidation = () => {
    let validationStatus = true;
    const error = {};
    if (!filterCategoryName) {
      error["categoryName"] = "Please enter category name";
      validationStatus = false;
    } else if (filterCategoryName.length < 3) {
      error["categoryName"] = "Name should be minimum 3 characters";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Fetching Add Filter Category Name
  const handleAddFilterCategoryModal = async (e) => {
    e.preventDefault();
    const status = inputFieldValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const response = await addFilterCategoryNameController(
          filterCategoryName
        );
        if (response?.code === 200) {
          toast.success(response?.message);
          filterCategoryListing();
          setButtonLoader(false);
          setAddModal(false);
        } else {
          toast.error(response?.message);
          setButtonLoader(false);
          setAddModal(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
        setAddModal(false);
      }
    }
  };

  // For updating Category Name
  const updateInputFieldValidation = () => {
    let validationStatus = true;
    const error = {};
    if (!updateFilterCategoryName) {
      error["categoryName"] = "Please enter category name";
      validationStatus = false;
    } else if (updateFilterCategoryName.length < 3) {
      error["categoryName"] = "Name should be minimum 3 characters";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  const manageHomeInputValidation = () => {
    let validationStatus = true;
    if (!categoryId) {
      validationStatus = false;
      setErrors("Select Category *");
    }
    return validationStatus;
  };

  //Fetching Edit Filter Category Name
  const handleEditFilterCategoryModal = async (e) => {
    e.preventDefault();
    const status = updateInputFieldValidation();
    if (status) {
      try {
        setButtonLoader(true);
        const response = await editFilterCategoryNameController(
          updateFilterCategoryName,
          filterCategoryId
        );
        // console.log(response, "Data");
        if (response?.code === 200) {
          toast.success(response?.message);
          filterCategoryListing();
          setButtonLoader(false);
          setEditModal(false);
        } else {
          toast.error(response?.message);
          setButtonLoader(false);
          setEditModal(false);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
        setEditModal(false);
      }
    }
  };

  //Fetching Delete Filter Category Name
  const deleteCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setSubmitButton(true);
      const response = await deleteFilterCategoryNameController(
        filterCategoryId
      );
      // console.log(response, "Data");
      if (response?.code === 200) {
        filterCategoryListing();
        setSubmitButton(false);
        toast.success(response?.message);
        setDeleteModal(false);
      } else {
        toast.error(response?.message);
        setSubmitButton(false);
        setDeleteModal(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
      setDeleteModal(false);
    }
  };

  //fetching Filter Category Status API
  const filterCategoryStatusHandler = async () => {
    try {
      setButtonLoader(true);
      const response = await filterCategoryStatusController(filterId);
      if (response?.code === 200) {
        toast.success(response?.message);
        setButtonLoader(false);
        setStatusModal(false);
        filterCategoryListing();
      } else {
        toast.error(response?.message);
        setButtonLoader(false);
      }
    } catch (e) {
      console.log(e.message);
      setButtonLoader(false);
    }
  };

  // Manage Home Configuration
  const manageHomeCategoryHandler = async () => {
    if (manageHomeInputValidation()) {
      // console.log(categoryId,"CategoryId");
      try {
        const data = {
          id: filterId,
          catId: Number(categoryId),
        };
        setButtonLoader(true);
        const response = await manageHomeConfigController(data);
        setButtonLoader(false);
        if (response?.data?.code === 200) {
          toast.message(response?.data?.message);
          setErrors("");
          setManageHomeStatus(false);
          setCategoryId("");
          filterCategoryListing();
          fetchingHomeConfig();
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setCategoryId("");
          setButtonLoader(false);
          setManageHomeStatus(false);
          filterCategoryListing();
          fetchingHomeConfig();
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
        setManageHomeStatus(false);
        setCategoryId("");
        filterCategoryListing();
        fetchingHomeConfig();
      }
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  const handleRowClick = (index) => {
    setShowDescription((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    filterCategoryListing();
  }, [debounceValue, limit,activePage]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchingHomeConfig();
  }, []);

  // console.log(homeConfigList, "Home Configuartion List");

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Filter Category Management </h3>
              {permissionChecker(permission, 13, 1) && (
                <div className="button_wrap">
                  <Link
                    onClick={() => setAddModal(true)}
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i> Add
                    Filter Category
                  </Link>
                </div>
              )}
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Created At</th>
                          <th>Manage Home Category</th>
                          {actionsChecker(permission, 13) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {filterList && filterList.length > 0
                          ? filterList.map(
                              (
                                {
                                  id,
                                  categoryName,
                                  createdAt,
                                  status,
                                  updatedAt,
                                  activeOnHome,
                                },
                                index
                              ) => {
                                return (
                                  <React.Fragment key={id}>
                                    <tr>
                                      <td>
                                        {(activePage - 1) * limit + ++index}
                                      </td>
                                      <td className="Cstm_see_description">
                                        {categoryName.length > 40 ? (
                                          <>
                                            {categoryName.slice(0, 40)}{" "}
                                            <Link
                                              onClick={() => {
                                                setShowDescription(
                                                  categoryName
                                                );
                                                setDescriptionModal(true);
                                              }}
                                              to="#"
                                            >
                                              see more
                                            </Link>
                                          </>
                                        ) : (
                                          categoryName
                                        )}
                                      </td>

                                      <td>{showDateFormatYear(createdAt)}</td>
                                      <td>
                                        <div className="switch_button">
                                          <FormControlLabel
                                            control={
                                              <ToggleStatus
                                                sx={{ m: 1 }}
                                                checked={activeOnHome === 1}
                                                onClick={() => {
                                                  setManageHomeStatus(true);
                                                  setFilterId(id);
                                                }}
                                              />
                                            }
                                          />
                                        </div>
                                      </td>
                                      {actionsChecker(permission, 13) && (
                                        <td>
                                          <div className="action_button_wrap">
                                            {permissionChecker(
                                              permission,
                                              13,
                                              5
                                            ) && (
                                              <div className="switch_button">
                                                <FormControlLabel
                                                  control={
                                                    <ToggleStatus
                                                      sx={{ m: 1 }}
                                                      checked={status === 1}
                                                      onClick={() => {
                                                        setStatusModal(true);
                                                        setFilterId(id);
                                                      }}
                                                    />
                                                  }
                                                />
                                              </div>
                                            )}

                                            {permissionChecker(
                                              permission,
                                              13,
                                              3
                                            ) && (
                                              <Link
                                                to={`/filter-category-management/view-filter-category-management/${id}`}
                                                className="action_button"
                                              >
                                                <VisibilityIcon
                                                  className="cstm_eye"
                                                  color="secondary"
                                                />
                                              </Link>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              13,
                                              2
                                            ) && (
                                              <Link className="action_button">
                                                <i
                                                  class="mdi mdi-table-edit"
                                                  onClick={() => {
                                                    setUpdateFilterCategoryName(
                                                      categoryName
                                                    );
                                                    setEditModal(true);
                                                    setFilterCategoryId(id);
                                                  }}
                                                ></i>
                                              </Link>
                                            )}
                                            {permissionChecker(
                                              permission,
                                              13,
                                              4
                                            ) && (
                                              <Link className="action_button">
                                                <DeleteRoundedIcon
                                                  sx={{ color: red[500] }}
                                                  onClick={() => {
                                                    setFilterCategoryId(id);
                                                    setDeleteModal(true);
                                                  }}
                                                />
                                              </Link>
                                            )}
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + filterList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add Filter Category Modal */}
          <ModalBox
            status={addModal}
            onClose={() => {
              setAddModal(false);
              setFilterCategoryName("");
              setErrors("");
            }}
            title={"Add Filter"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    <div class="form-group">
                      <label htmlFor="Banner ">
                        Enter Filter Category Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={40}
                        minLength={3}
                        class="form-control mb-2"
                        name="categoryName"
                        id="exampleInputName1"
                        placeholder="Enter Filter Category Name"
                        onChange={(e) => setFilterCategoryName(e.target.value)}
                      />
                      <span
                        style={{ color: "red" }}
                        className="error"
                        id="error_cstm"
                      >
                        {errors.categoryName}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          e.preventDefault();
                          setAddModal(false);
                          setFilterCategoryName("");
                          setErrors("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        ref={buttonLoader}
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          handleAddFilterCategoryModal(e);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/* Edit Filter Category Modal */}
          <ModalBox
            status={editModal}
            onClose={() => {
              setEditModal(false);
              setUpdateFilterCategoryName("");
              setErrors("");
            }}
            title={"Update Filter Category Name"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    <div class="form-group">
                      <label htmlFor="Banner ">
                        Enter Filter Category Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Filter Category Name"
                        maxLength={40}
                        minLength={3}
                        value={updateFilterCategoryName}
                        onChange={(e) =>
                          setUpdateFilterCategoryName(e.target.value)
                        }
                      />
                      <span style={{ color: "red" }} className="error">
                        {errors.categoryName}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        class="btn btn-light"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditModal(false);
                          setUpdateFilterCategoryName("");
                          setErrors("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        ref={buttonLoader}
                        class="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          handleEditFilterCategoryModal(e);
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/* Delete Category Name Modal */}
          <ModalBox
            status={deleteModal}
            onClose={() => setDeleteModal(false)}
            title="Delete Filter"
            description={"Are you sure you want to delete this filter?"}
            action={deleteCategoryHandler}
            uploadButtonElement={submitButton}
          />

          {/* Show Category Name Description Modal */}
          <ModalBox
            status={descriptionModal}
            onClose={() => setDescriptionModal(false)}
            title=""
            uploadButtonElement={submitButton}
            customizedFooter={<></>}
          >
            <div>
              <h5 className="text-center">{showDescription}</h5>
            </div>
          </ModalBox>

          {/* Modal for Status Change */}
          <ModalBox
            status={statusModal}
            onClose={() => setStatusModal(false)}
            title={"Change Status"}
            description="Are you sure you want to change status?"
            action={filterCategoryStatusHandler}
            uploadButtonElement={buttonLoader}
          />

          {/* Manage Home Configuration Status */}
          <ModalBox
            status={manageHomeStatus}
            onClose={() => {setManageHomeStatus(false);
            setErrors("");
            }}
            title={"Which Category You Want To Update?"}
            customizedFooter={<></>}
          >
            <div className="form-group row">
              {/* <p className="text-center">Which Category You Want To Update?</p> */}
              <label className="col-3 mt-2 mx-3">
                <strong>Category <span className="text-danger">*</span></strong>
              </label>
              <div className="col-5">
                <select
                  class="form-control"
                  onChange={(e) => setCategoryId(e.target.value)}
                  name="categoryId"
                  value={categoryId}
                >
                  <option value={0}>Select Category</option>
                  {homeConfigList.map(({ id, categoryId, name }) => {
                    return (
                      <option key={id} value={categoryId}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <span
                        style={{ color: "red" }}
                        className="error"
                        id="error_cstm"
                      >
                        {errors}
                      </span>
              </div>
            </div>
            <div className="text-center">
              <button
                onClick={manageHomeCategoryHandler}
                className="btn btn-gradient-primary me-2"
                ref={buttonLoader}
              >
                Submit
              </button>
            </div>
          </ModalBox>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FilterCategoryManagement;
