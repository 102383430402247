import React, { useEffect, useState } from "react";
import {
  deleteRequestListController,
  deleteRetailerController,
  requestListController,
} from "../../services/retailerServices/retailerServices";
import { toast } from "react-toastify";
import { dateFormat, dateFormatYear } from "../../utility/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import useDebounce from "../../hooks/useDebounce";
import { permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";

function DeleteRetailerList() {
  const [totalList, setTotalList] = useState();
  const [deleteRequestListData, setDeleteRequestListData] = useState([]);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const [retailerId, setRetailerId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [errors, setErrors] = useState("");
  const [submitButton, setSubmitButton] = useButtonLoader();
  const { permission } = usePermission();

  const fetchingDeleteRequestList = async () => {
    try {
      showLoader();
      const response = await deleteRequestListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : ""
      );
      if (response.data?.code === 200) {
        hideLoader();
        setDeleteRequestListData(response?.data?.data?.data);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hideLoader();
      }
    } catch (e) {
      console.log(e.message);
      hideLoader();
    }
  };

  // Delete Input Field Validation
  const validation = () => {
    let validationStatus = true;
    let error = "";
    if (!deleteReason) {
      error = "Reason is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Fetching Delete Retailer
  const deleteRetailerHandler = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      setSubmitButton(true);
      try {
        const data = {
          userId: retailerId,
          deleteReason: deleteReason,
        };

        const response = await deleteRetailerController(data);
        if (response?.data?.code === 200) {
          setDeleteModal(false);
          toast.success(response.data?.message);
          fetchingDeleteRequestList();
          setSubmitButton(false);
          setDeleteReason("");
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setDeleteModal(false);
          setErrors("");
          setDeleteReason("");
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
        setDeleteReason("");
      }
    }
  };

  // Clear Filter
  const clearFilter = () => {
    setFilterByDate("");
    setActivePage(1);
    setLimit("10");
    setSearch("");
    fetchingDeleteRequestList();
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingDeleteRequestList();
  }, [limit, activePage, debounceValue]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                Delete Retailer List{" "}
              </h3>
              <div className="button_wrap">
                <Link
                  to="#"
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={clearFilter}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Reset
                </Link>
              </div>
            </div>
          </div>

          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search cstm_btn_wrap_theme"
                  placeholder="Search by name,email address or phone number"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* <div className="col-lg-2 cstm_padding_set">
              <div className="datePicker_wrap cstm_date_picker">
                <DatePicker
                  showIcon
                  placeholderText="Start Date"
                  icon={true}
                  className="input date_picker cstm_btn_wrap_theme2 "
                  selected={filterByDate}
                  value={filterByDate}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onChange={(date) => setFilterByDate(date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
            </div> */}
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Retailer Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Registered On</th>
                        <th>Status</th>
                        {permissionChecker(permission, 2, 3) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {deleteRequestListData &&
                      deleteRequestListData.length > 0 ? (
                        deleteRequestListData.map(
                          (
                            {
                              id,
                              retailerName,
                              email,
                              mobileNumber,
                              createdAt,
                              status,
                              isApproved,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={id}>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{retailerName ? retailerName : "---"}</td>
                                  <td>{email ? email : "---"}</td>
                                  <td>{mobileNumber}</td>
                                  <td>{dateFormat(createdAt)}</td>
                                  <td>
                                    {/* {status == 1 ? "Verifed" : "Unverified"} */}
                                    {isApproved === 0 ? (
                                      <button className="btn-gradient-warning">
                                        Unverified
                                      </button>
                                    ) : isApproved === 1 ? (
                                      <button className="btn-gradient-success">
                                        Verified
                                      </button>
                                    ) : isApproved === 2 ? (
                                      <button className="btn-gradient-danger">
                                        Rejected
                                      </button>
                                    ) : (
                                      "---"
                                    )}
                                  </td>
                                  {permissionChecker(permission, 2, 3) && (
                                    <td>
                                      <div className="action_button_wrap">
                                        <div className="switch_button">
                                          <Link className="action_button">
                                            <DeleteRoundedIcon
                                              sx={{ color: red[500] }}
                                              onClick={() => {
                                                setRetailerId(id);
                                                setDeleteModal(true);
                                              }}
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + deleteRequestListData?.length - 1} of{" "}
                        {totalList} entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cancel Reason Modal*/}
        <ModalBox
          status={deleteModal}
          onClose={() => {
            setDeleteModal(false);
            setDeleteReason("");
            setErrors("");
          }}
          title="Delete Retailer"
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="form-group">
                    <label htmlFor="Banner ">Enter Delete Reason</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      cols={4}
                      value={deleteReason}
                      name="cancelReason"
                      placeholder="Enter delete reason"
                      onChange={(e) => setDeleteReason(e.target.value)}
                    />
                    <span style={{ color: "red" }} className="error">
                      {errors}
                    </span>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <button
                        type="submit"
                        className="btn btn-gradient-primary me-2"
                        onClick={(e) => {
                          deleteRetailerHandler(e);
                        }}
                        ref={submitButton}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-light"
                        onClick={(e) => {
                          setDeleteModal(false);
                          e.preventDefault();
                          setDeleteReason("");
                          setErrors("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>
      </div>
    </React.Fragment>
  );
}

export default DeleteRetailerList;
