import React, { useEffect, useState } from "react";
import { helpAndSupportListController } from "../../services/helpAndSupportServices/helpAndSupportController";
import { Link } from "react-router-dom";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import {
  deleteOfferController,
  offersListingController,
} from "../../services/offersServices/offersManagementController";
import { vendorListController } from "../../services/vendorServices/vendorServices";
import AddGiftProduct from "./AddGiftProduct";
import {
  deleteGiftController,
  giftListController,
} from "../../services/giftServices.jsx/giftController";
import EditGiftProduct from "./EditGiftProduct";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";

function GiftManagement() {
  const [vendorsList, setVendorsList] = useState([]);
  const [giftList, setGiftList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [giftId, setGiftId] = useState("");
  const [updateFilterCategoryName, setUpdateFilterCategoryName] = useState("");
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [imageFile, setImageFile] = useState("");
  const { permission } = usePermission();

  const fetchGiftManagementList = async () => {
    try {
      show();
      const response = await giftListController(
      activePage ? activePage : "",
      limit ? limit : "",
      search ? search : ""
      );
      // console.log(response?.data?.data?.gifts, "-->");
      if (response?.data?.code === 200) {
        hide();
        setGiftList(response?.data?.data?.gifts);
        setTotalList(response?.data?.data?.count);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Delete Gift Handler
  const deleteGiftHandler = async () => {
    try {
      setSubmitButton(true);
      const response = await deleteGiftController(giftId);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setDeleteModal(false);
        setSubmitButton(false);
        fetchGiftManagementList();
      } else {
        toast.error(response?.response?.data?.message);
        setDeleteModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchGiftManagementList();
  }, [debounceValue, limit, activePage,search]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">Gift Management</h3>
              {permissionChecker(permission, 17, 1) && (
                <div className="button_wrap">
                  <Link
                    to="#"
                    className="btn btn-btn btn-gradient-primary button"
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i> Add
                    Gift
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search By Product Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Gift Name</th>
                          {/* <th>Inventory</th> */}
                          <th>Price</th>
                          {actionsChecker(permission, 17) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {loader}
                        {giftList && giftList.length > 0 ? (
                          giftList.map(({ id, giftName,giftUnit,giftPrice }, index) => {
                            return (
                              <React.Fragment key={id}>
                                <tr>
                                  <td>{(activePage - 1) * limit + ++index}</td>
                                  <td>{giftName || "---"}</td>
                                  {/* <td>{giftUnit || "---"}</td> */}
                                  <td>{giftPrice || "---"}</td>
                                  {actionsChecker(permission, 17) && (
                                    <td>
                                      <div className="action_button_wrap">
                                        {permissionChecker(
                                          permission,
                                          17,
                                          2
                                        ) && (
                                          <Link
                                            className="action_button"
                                            to="#"
                                            onClick={() => {
                                              setEditModal(true);
                                              setGiftId(id);
                                            }}
                                          >
                                            <i class="mdi mdi-table-edit"></i>
                                          </Link>
                                        )}
                                        {permissionChecker(
                                          permission,
                                          17,
                                          5
                                        ) && (
                                          <Link
                                            onClick={() => {
                                              setDeleteModal(true);
                                              setGiftId(id);
                                            }}
                                            className="action_button"
                                          >
                                            <DeleteRoundedIcon
                                              sx={{ color: red[500] }}
                                            />
                                          </Link>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + giftList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete Gift"
          description={"Are you sure you want to delete this gift?"}
          action={deleteGiftHandler}
          uploadButtonElement={submitButton}
        />

        {/* Add Modal */}
        <AddGiftProduct
          errors={errors}
          setErrors={setErrors}
          addModal={addModal}
          setAddModal={setAddModal}
          inputFieldValue={inputFieldValue}
          setInputFieldValue={setInputFieldValue}
          imageFile={imageFile}
          setImageFile={setImageFile}
          fetchGiftManagementList={fetchGiftManagementList}
        />

        {/* Edit Modal */}
        {editModal && (
          <EditGiftProduct
            errors={errors}
            setErrors={setErrors}
            editModal={editModal}
            setEditModal={setEditModal}
            inputFieldValue={inputFieldValue}
            setInputFieldValue={setInputFieldValue}
            imageFile={imageFile}
            setImageFile={setImageFile}
            fetchGiftManagementList={fetchGiftManagementList}
            giftId={giftId}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default GiftManagement;
